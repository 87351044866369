import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '48px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      columnGap: 'unset',
    },
  },
  toolbarRoot: {
    backgroundColor: theme.palette.custom.blackFill,
    padding: 0,
    width: '100%',
    border: 'none',
    '&.MuiToolbar-regular': {
      padding: 0,
      height: '48px',
      minHeight: '48px',
    },
  },
  icon: {
    color: theme.palette.custom.white,
    display: 'flex',
    margin: '0 15px',
    [theme.breakpoints.down('lg')]: {
      marginRight: 'unset',
    },
  },
}));

export default useStyles;
