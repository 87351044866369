export const hasDuplicates = (array) => {
  return (new Set(array)).size !== array.length;
}

export const compareForm = (currentForm, newForm, keysToExtract) => {
  function extractFields(obj, keysToExtract) {
    const result = Array.isArray(obj) ? [] : {};

    if (Array.isArray(obj)) {
        obj.forEach((item, index) => {
            result[index] = extractFields(item, keysToExtract);
        });
    } else {
        Object.keys(keysToExtract).forEach(key => {
          if (obj.hasOwnProperty(key)) {
            if (typeof keysToExtract[key] === 'object' && keysToExtract[key] !== null && typeof obj[key] === 'object') {
              result[key] = extractFields(obj[key], keysToExtract[key]);
            } else {
              if (obj[key] !== undefined && obj[key] !== null && Object.keys(obj[key]).length !== 0) {
                result[key] = obj[key];
              }
            }
          }
        });
    }
    return result;
  }

  function deepSortObject(obj, sortKey = 'module') {
    if (Array.isArray(obj)) {
      if (obj.every(item => typeof item === 'object' && item[sortKey])) {
        obj.sort((a, b) => (a[sortKey] > b[sortKey]) ? 1 : ((b[sortKey] > a[sortKey]) ? -1 : 0));
      }
      // Recursively sort each item in the array
      return obj.map(item => deepSortObject(item, sortKey));
    }
    else if (Object.prototype.toString.call(obj) === '[object Object]') {
      const sortedObj = {};
      Object.keys(obj).sort().forEach(key => {
        // Recursively apply sorting to each property if it's an object
        sortedObj[key] = deepSortObject(obj[key], sortKey);
      });
      return sortedObj;
    }
    return obj;
  }

  let differences = {};

  const current = deepSortObject(extractFields(currentForm, keysToExtract));
  const updated = deepSortObject(extractFields(newForm, keysToExtract));

  Object.keys(current).forEach(key => {
    if (JSON.stringify(current[key]) !== JSON.stringify(deepSortObject(updated[key]))) {
      console.info('current[key]', current[key], 'updated[key]', updated[key])
      differences[key] = updated[key];
    }
  });

  return differences;
};
