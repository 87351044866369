import {
  TYPE_REQUEST_ACTION_SPEC,
  TYPE_RECEIVE_ACTION_SPEC,
  TYPE_RECEIVE_ACTION_SPEC_ERROR,
  TYPE_UPDATE_ACTION_SPEC,
  TYPE_UPDATE_ACTION_SPEC_SUCCESS,
  TYPE_UPDATE_ACTION_SPEC_ERROR,
  TYPE_CREATE_ACTION_SPEC,
  TYPE_CREATE_ACTION_SPEC_SUCCESS,
  TYPE_CREATE_ACTION_SPEC_ERROR,
  TYPE_RESET_ACTION_SPEC
} from 'data/actionSpecs';

export const initialState = {
  actionSpec: {},
  isFetching: false,
  lastUpdated: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_ACTION_SPEC:
    case TYPE_CREATE_ACTION_SPEC:
      return {
        ...state,
        isFetching: true
      };
    case TYPE_UPDATE_ACTION_SPEC:
      return {
        ...state,
        isFetching: false,
        actionSpec: action.actionSpec
      };
    case TYPE_CREATE_ACTION_SPEC_SUCCESS:
      return {
        ...state,
        isFetching: false,
        actionSpec: action.actionSpec
      };
    case TYPE_UPDATE_ACTION_SPEC_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tenantsConflicts: action.tenantsConflicts,
        testPassed: action.testPassed,
        testResults: action.testResults,
        actionSpec: action.actionSpec
      };
    case TYPE_RECEIVE_ACTION_SPEC:
      return {
        ...state,
        isFetching: false,
        actionSpec: action.actionSpec,
        testPassed: action.testPassed,
        testResults: action.testResults
      };
    case TYPE_RECEIVE_ACTION_SPEC_ERROR:
    case TYPE_UPDATE_ACTION_SPEC_ERROR:
    case TYPE_CREATE_ACTION_SPEC_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case TYPE_RESET_ACTION_SPEC:
      return { ...initialState };
    default:
      return state;
  }
};
