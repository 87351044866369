import React, { useEffect, useMemo } from 'react';

import Sidebar from 'components/templates/sidebar';
import { Columns } from 'views';

import useStyles from './ActionSpecs.styles';
import ActionSpec from 'components/views/action-specs/action-spec';
import { ACTION_SPEC_ACTION_CATEGORY_OPTIONS, ACTION_SPEC_STATUS_OPTIONS } from 'data/actionSpecs';

const ActionSpecs = ({ actions, tenants }) => {
  const classes = useStyles();

  const menuOptions = useMemo(() => {
    const TENANT_OPTIONS = tenants
      ? tenants.tenants.map((tenant) => ({ value: tenant.id, text: tenant.name }))
      : [];

    return [
      {
        value: 'category',
        text: 'Action Category',
        children: ACTION_SPEC_ACTION_CATEGORY_OPTIONS,
      },
      {
        value: 'status',
        text: 'Status',
        children: ACTION_SPEC_STATUS_OPTIONS,
      },
      {
        value: 'tenant',
        text: 'Tenant',
        children: TENANT_OPTIONS,
      },
    ];
  }, [tenants]);

  useEffect(() => {
    actions.fetchTenantsIfNeeded();
  }, [actions]);

  const cardBuilder = (actionSpec, index, searchedText) => {
    return <ActionSpec key={index} {...{ actions, actionSpec, index, searchedText }} />;
  };

  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Action Specs"
        mainButtonAction={() => actions.push('/action-specs/create')}
        mainButtonText="Create Action Spec"
      />
      <div className={classes.actionSpecsContainer}>
        <Columns
          name="actionSpecs"
          service={actions.fetchActionSpecs}
          menuOptions={menuOptions}
          cardBuilder={cardBuilder}
        />
      </div>
    </div>
  );
};

export default ActionSpecs;
