import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { highlighter } from '../../../../utils/columns';
import useStyles from './Survey.styles';

const Survey = ({ actions, survey, searchedText }) => {
  const classes = useStyles();

  // const questionsPlural = survey.questions.length === 1 ? 'questions' : 'question';
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body1">{highlighter(searchedText, survey.name)}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.infoContainer}>
          <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              Type:{' '}
            </Typography>
            <Typography variant="body2" display="inline">
              {survey.type}
            </Typography>
          </div>
          <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              State:{' '}
            </Typography>
            <Typography variant="body2" display="inline">
              {survey.status}
            </Typography>
          </div>
          {/* // TODO: This is pending on backend */}
          {/* <div>
            <Typography className={classes.boldText} variant="body2" display="inline">
              Questions:{' '}
            </Typography>
            <Typography
              variant="body2"
              display="inline"
            >{`${survey.questions.length} ${questionsPlural}`}</Typography>
          </div> */}
          <Typography variant="h6">{highlighter(searchedText, survey.introHeader)}</Typography>
          <Typography variant="body1">{highlighter(searchedText, survey.introBody)}</Typography>
        </div>
      </AccordionDetails>
      <AccordionActions>
        <Button size="small" onClick={() => actions.push(`/surveys/${survey.id}/edit`)}>
          Edit
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default Survey;
