import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.black,
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.custom.grayA,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '432px',
    backgroundColor: theme.palette.custom.white,
    borderRadius: '12px',
    border: `2px solid ${theme.palette.custom.black20}`,
    padding: '96px 48px',
    gap: '48px',
  },
  logo: {
    color: theme.palette.custom.black,
    fontSize: 72,
    width: '99px',
    height: '72px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
    width: '100%',
  },
  button: {
    '&.MuiButton-root': {
      ...theme.typography.header12,
      fontFamily: 'Gotham',
      height: '48px',
      textTransform: 'none',
      boxShadow: '0px 15px 18px -11px rgba(80, 86, 97, 0.2)',
      borderRadius: '3px',
      padding: '9px 18px',
      transition: 'none',

      // below is a trick to transition solid color to a gradient
      // https://css-tricks.com/transitioning-gradients/
      position: 'relative',
      zIndex: 0,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '3px',
        opacity: 0,
        transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
        zIndex: -1,
      },
      '&:hover': {
        '&.MuiButton-root': {},
        '&::before': {
          opacity: 1,
        },
      },

      '&.Mui-disabled': {
        // background: theme.palette.action.disabledBackground,
        // color: theme.palette.action.disabled,
        boxShadow: 'none',
      },

      color: theme.palette.custom.white,
      background: theme.palette.custom.black,
    },

    '&:hover': {
      '&.MuiButton-root': {
        background: theme.palette.custom.black,
      },
    },
    '&::before': {
      background: theme.palette.custom.gradientBlack,
    },
  },
  azureContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '9px',
  },
  azureText: {
    color: theme.palette.custom.black80,
  },
  connectingContainer: {
    width: '100%',
    height: '48px',
    borderRadius: '3px',
    backgroundColor: theme.palette.custom.black10,
    padding: '15px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '6px',
  },
  footerContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: '-80px',
  },
}));
