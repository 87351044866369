export const getHost = () => {
  let host = window.location.host.toLocaleLowerCase();

  if (host.includes('aristotle')) {
    host = host.split('aristotle.').pop();
  } else {
    host = 'radius-sboxa.all.health';
  }

  return host;
};
