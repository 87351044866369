import { authorizedAxios } from 'config/axios';
import ActionSpecFactory from 'factories/actionSpecs';

const FACTUARY_URL = '/factuary/api';

export const fetchActionSpecs = (filters, page = 0) => {
  const params = new URLSearchParams();

  const copyFilters = { ...filters };

  if (copyFilters['searched text']?.length) {
    params.append('title', copyFilters['searched text'].join('|'));
    params.append('type_content', copyFilters['searched text'].join('|'));
    delete copyFilters['searched text'];
  }

  if (copyFilters) {
    Object.entries(copyFilters).forEach(([type, values]) => {
      if (values.length) params.append(type, values.join('|'));
    });
  }

  if (page) params.append('page', page);
  params.append('per_page', 10);

  return authorizedAxios
    .get(`${FACTUARY_URL}/v2/content/filter?content_type=action_spec&${params}`)
    .then((response) => {
      return {
        meta: {
          nextPage: response.meta.next_page,
          currentPage: response.meta.page,
        },
        data: {
          actionSpecs: response.data.map(ActionSpecFactory.toModel),
        },
      };
    });
};

export const createActionSpec = (actionSpec) => {
  return authorizedAxios.post(
    `${FACTUARY_URL}/v2/action/spec`,
    ActionSpecFactory.toDTO(actionSpec)
  );
};

export const updateActionSpec = (actionSpec) => {
  return authorizedAxios.put(
    `${FACTUARY_URL}/v2/action/spec/${actionSpec.id}`,
    ActionSpecFactory.toDTO(actionSpec)
  );
};

export const fetchActionSpec = (id) => {
  return authorizedAxios.get(`${FACTUARY_URL}/v2/action/spec/${id}`).then((response) => ({
    ...response,
    actionSpec: ActionSpecFactory.toModel(response.data),
  }));
};

export const getLanguageFile = (actionSpecId, languageCode) => {
  return authorizedAxios.get(
    `${FACTUARY_URL}/v2/strings/action_spec/csv?content_id=${actionSpecId}&lang=${languageCode}`
  );
};

export const getLanguageFileTemplate = (actionSpecId) => {
  return authorizedAxios
    .get(`${FACTUARY_URL}/v2/strings/action_spec/csv?content_id=${actionSpecId}`)
    .then((response) => response)
    .catch((err) => '');
};

export const postLanguageFile = (languageFile, actionSpecId, languageCode) => {
  return authorizedAxios.post(
    `${FACTUARY_URL}/v2/strings/action_spec/csv?content_id=${actionSpecId}&lang=${languageCode}`,
    languageFile
  );
};

export const postMessageCSV = (messageCSV, actionSpecId, category) => {
  return authorizedAxios.post(
    `${FACTUARY_URL}/v2/strings/action_spec_strings/csv?content_id=${actionSpecId}&category=${category}`,
    messageCSV
  );
};

export const fetchMessageCsv = (actionSpecId, category) => {
  return authorizedAxios.get(
    `${FACTUARY_URL}/v2/strings/action_spec_strings/csv?content_id=${actionSpecId}&category=${category}`
  );
};
