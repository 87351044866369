import React from 'react';
import { AppBar, Toolbar } from '@mui/material';

import useStyles from './HeaderAuth.styles';
import { LogoAllIcon } from 'assets/icons';

const HeaderAuth = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="header">
      <AppBar elevation={0} position="fixed">
        <Toolbar className={classes.toolbar} classes={{ root: classes.toolbarRoot }}>
          <LogoAllIcon className={classes.icon} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderAuth;
