export const getOperator = (op) => {
  switch (op) {
    case '=':
      return 'eq';

    case '≠':
      return 'neq';

    case '‏≤':
      return '<=';

    case '‏≥':
      return '>=';

    case '÷':
      return '/';

    case '×':
      return '*';

    // This is necessary, please don't delete it
    case '‏>':
      return '>';

    // This is necessary, please don't delete it
    case '‏<':
      return '<';

    default:
      return op;
  }
};

export const getTerm = (expr) => {
  if (expr.getField('TEXT')) {
    return `"${expr.getField('TEXT').getText()}"`;
  } else if (expr.getField('NUM')) {
    return expr.getField('NUM').getText();
  } else if (expr.getField('BOOL')) {
    return expr.getField('BOOL').getText();
  } else if (expr.getField('fact')) {
    return '?' + expr.getField('fact').getText();
  } else {
    return 'undefined';
  }
};

export const getOperands = (terms) => {
  if (terms[1].getField('fact')) {
    return `${terms[1].getField('fact').getText()} ${getTerm(terms[2])}`;
  } else if (terms[2].getField('fact')) {
    return `${terms[2].getField('fact').getText()} ${getTerm(terms[1])}`;
  } else {
    return `${getTerm(terms[1])} ${getTerm(terms[2])}`;
  }
};

const isUnitaryOp = (block) => {
  return block.type === 'logic_negate';
};

const isBinaryOp = (block) => {
  return (
    block.type === 'logic_compare' ||
    block.type === 'logic_operation' ||
    block.type === 'math_arithmetic' ||
    block.type === 'user_cohort'
  );
};

const isOp = (block) => {
  return isBinaryOp(block) || isUnitaryOp(block) || isUserCohortOp(block) || isModOp(block);
};

const getUnitaryOperator = (block) => {
  switch (block.type) {
    case 'logic_negate':
      return 'not';

    default:
      return 'UNDEFINED_UNITARY_OP';
  }
};

const isUserCohortOp = (block) => {
  return block.type === 'user_cohort';
};

const isModOp = (block) => {
  return block.type === 'math_modulo';
};

var index = 0;
var rs;
var ls;

// Main function
export const getExpr = (block) => {
  index = 0;
  return getExprR(block);
};

// Recursive function to generate expressions
const getExprR = (block) => {
  // If it is an unitary operation
  if (isUnitaryOp(block[index])) {
    const op = getUnitaryOperator(block[index]);
    if (block.length > 2) {
      index++;
      return `(${op} ${getExprR(block)})`;
    } else {
      return `(${op} ${getTerm(block[rs])})`;
    }
  }
  rs = index + 1;
  ls = index + 2;

  // If it is user cohort operation
  if (isUserCohortOp(block[index])) {
    const alias = block[rs].getFieldValue('TEXT');
    const value = block[ls].getFieldValue('TEXT');
    return `(${block[index].type} $? ?${alias}&${value} $?)`;
  }

  // If it is mod operation
  if (isModOp(block[index])) {
    var op = 'mod';
  } else {
    // eslint-disable-next-line no-redeclare
    var op = block[index].getField('OP').getText();
  }

  var result = `(${getOperator(op)}`;

  if (isOp(block[rs])) {
    index = rs;
    result += ' ' + getExprR(block);
    ls = ls + 1;
  } else {
    result += ' ' + getTerm(block[rs]);
  }

  if (isOp(block[ls])) {
    index = ls;
    result += ' ' + getExprR(block) + ')';
  } else {
    result += ' ' + getTerm(block[ls]) + ')';
  }

  return result;
};

export class UserCohortException extends Error {
  constructor() {
    super('User Cohort Alias Error: Please check that all your alias are unique');
    this.code = 'USER_COHORT_ERROR';
  }
}
