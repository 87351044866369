import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  Box,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { highlighter } from '../../../../utils/columns';
import { ACTION_SPEC_ACTION_CATEGORY_NAMES, ACTION_SPEC_PRIORITY_NAMES } from 'data/actionSpecs';
import useStyles from './ActionSpec.styles';

const ActionSpec = ({ actions, actionSpec, searchedText }) => {
  const classes = useStyles();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.titleText} variant="body1">
          {highlighter(searchedText, actionSpec.title)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.infoContainer}>
        <Box className={classes.propertyContainer}>
          <Typography className={classes.boldText} variant="body2">
            Type:{' '}
          </Typography>
          <Typography className={classes.propertyText} variant="body2">
            {highlighter(searchedText, actionSpec.type)}
          </Typography>
        </Box>
        <Box className={classes.propertyContainer}>
          <Typography className={classes.boldText} variant="body2">
            Action Category:{' '}
          </Typography>
          <Typography className={classes.propertyText} variant="body2">
            {ACTION_SPEC_ACTION_CATEGORY_NAMES[actionSpec.category]}
          </Typography>
        </Box>
        <Box className={classes.propertyContainer}>
          <Typography className={classes.boldText} variant="body2">
            Priority:{' '}
          </Typography>
          <Typography className={classes.propertyText} variant="body2">
            {ACTION_SPEC_PRIORITY_NAMES[actionSpec.priority]}
          </Typography>
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <Button size="small" onClick={() => actions.push(`/action-specs/${actionSpec.id}/edit`)}>
          Edit
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default ActionSpec;
