import TestFactory from './tests';
import { idGenerator } from 'utils/generator';
import { cleanConditions } from './utils';

export default class ActionSpecFactory {
  static toDTO(model) {
    return {
      id: model.id || undefined,
      title: model.title,
      type: model.type,
      name: model.name,
      action_category: model.actionCategory,
      priority: model.priority,
      status: model.status,
      cooldown: parseInt(model.cooldown),
      ttl: parseInt(model.ttl),
      flags: [model.flags],
      evidence: model.evidence.map(ActionSpecFactory.evidenceToDTO),
      tasks: model.tasks.map(ActionSpecFactory.taskToDTO),
      messages: model.messages.map(ActionSpecFactory.messageToDTO),
      params: model.params,
      conditions: cleanConditions(model.conditions),
      tests: TestFactory.testToDTO(model.tests),
      tenants: model.tenants,
      available_langs: model.availableLangs,
      enable_langs: model.enableLangs,
      lang: model.lang,
      visual_conditions: model.visualConditions,
      ab_test_name: model.abTestName,
      ab_test_side: model.abTestSide,
      group_identifier: model.groupIdentifier,
      group_name: model.groupName,
      group_name_cooldown: model.groupNameCooldown,
    };
  }

  static toModel(dto) {
    return {
      id: dto.id,
      title: dto.title?.toString(),
      type: dto.type?.toString(),
      name: dto.name?.toString(),
      actionCategory: dto.action_category?.toString(),
      category: dto.category?.toString(),
      priority: dto.priority?.toString() ?? '',
      status: dto.status?.toString() ?? '',
      cooldown: dto.cooldown?.toString() ?? '',
      ttl: dto.ttl?.toString() ?? '',
      flags: dto.flags ? dto.flags[0] : '',
      evidence: dto.evidence?.map(ActionSpecFactory.evidenceToModel) ?? [],
      tasks: dto.tasks?.map(ActionSpecFactory.taskToModel) ?? [],
      messages: dto.messages?.map(ActionSpecFactory.messageToModel) ?? [],
      params: dto.params ?? {},
      conditions: dto.conditions,
      tests: dto.tests ? TestFactory.testToModel(dto.tests) : { conditions: [], params: [] },
      tenants: dto.tenants,
      availableLangs: dto.available_langs,
      enableLangs: dto.enable_langs,
      lang: dto.lang ?? '',
      visualConditions: dto.visual_conditions,
      abTestName: dto.ab_test_name ?? '',
      abTestSide: dto.ab_test_side ?? '',
      groupIdentifier: dto.group_identifier ?? '',
      groupName: dto.group_name ?? '',
      groupNameCooldown: dto.group_name_cooldown ?? '',
    };
  }

  static evidenceToDTO(evidenceModel) {
    return {
      id: evidenceModel.id ?? undefined,
      metric: evidenceModel.metric,
      order: evidenceModel.order,
      timeframe_average: evidenceModel.timeframeAverage ?? '',
      timeframe_baseline: evidenceModel.timeframeBaseline ?? '',
    };
  }

  static evidenceToModel(evidenceDto) {
    return {
      id: evidenceDto.id,
      internalId: idGenerator.next().value,
      metric: evidenceDto.metric,
      order: evidenceDto.order,
      timeframeAverage: evidenceDto.timeframe_average ?? '',
      timeframeBaseline: evidenceDto.timeframe_baseline ?? '',
    };
  }

  static taskToDTO(taskModel) {
    return {
      id: taskModel.id ?? undefined,
      task_type: taskModel.taskType,
      complete: false,
      optional: taskModel.optional === 'true',
      default: taskModel.default === 'true',
      label: taskModel.label,
      trigger: taskModel.trigger ?? [],
    };
  }

  static taskToModel(taskDto) {
    return {
      id: taskDto.id,
      internalId: idGenerator.next().value,
      taskType: taskDto.task_type,
      complete: 'false',
      optional: taskDto.optional ? 'true' : 'false',
      default: taskDto.default ? 'true' : 'false',
      label: taskDto.label,
      trigger: taskDto.trigger ?? [],
    };
  }

  static messageToDTO(messageModel) {
    return {
      id: messageModel.id ?? undefined,
      message: messageModel.message,
      complete: false,
      default: messageModel.default === 'true',
      dynamic_message: !messageModel.dynamicMessage ? null : messageModel.dynamicMessage,
      ...(messageModel.msgCategory !== undefined ? {category: messageModel.msgCategory} : {}),
      ...(messageModel.timeoutSecs !== undefined ? {timeout: messageModel.timeoutSecs} : {})
    };
  }

  static messageToModel(messageDto) {
    return {
      id: messageDto.id,
      internalId: idGenerator.next().value,
      message: messageDto.message,
      complete: 'false',
      default: messageDto.default ? 'true' : 'false',
      dynamicMessage: messageDto.dynamic_message ?? '',
      ...(messageDto.category !== undefined ? {msgCategory: messageDto.category} : {}),
      ...(messageDto.timeout !== undefined ? {timeoutSecs: messageDto.timeout} : {})
    };
  }

  static displayConditionToDTO(displayConditionModel) {
    return {
      question_name: displayConditionModel.questionName ?? null,
      answer_indices:
        displayConditionModel.answerIndices
          .split(', ')
          .map((ind) => parseInt(ind) - 1)
          .filter((ind) => !isNaN(ind)) ?? null,
    };
  }

  static displayConditionToModel(displayConditionDto) {
    return {
      questionName: displayConditionDto.question_name ?? '',
      answerIndices: displayConditionDto.answer_indices
        .map((ind) => parseInt(ind) + 1)
        .filter((ind) => !isNaN(ind))
        .join(', '),
    };
  }
}
