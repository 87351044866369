import React from 'react';
import { Grid, Typography, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Button from "components/commons/button";
import { CHECK_BOX_MARGIN, TENANT_NAME_MARGIN, TENANT_INCLUDE, TENANT_EXCLUDE, TENNANTS_MESSAGE } from '../../../data/tenants';

const TenantsField = ({ setForm = () => { }, form, tenantList = [], initialValue = [], handleTenantCheck, title }) => {

  const tenantId = localStorage.getItem('tenantId');

  const selectAll = (exclude = true) => () => {
    var newInclude = tenantList.map(tenant => tenant.id);
    if (exclude) {
      newInclude = [tenantId];
    }
    setForm({ ...form, tenants: newInclude });
  };


  const renderItem = (tenant, index) => {
    return (
      <RadioGroup key={index} row onChange={handleTenantCheck(index)}>
        <Grid container>
          <Grid item xs={9} sm={4}>
            <Typography variant="subtitle2" gutterBottom style={{ marginTop: TENANT_NAME_MARGIN }}>
              {tenant.name}
            </Typography>
          </Grid>
          <Grid item xs={9} sm={4}>
            <FormControlLabel checked={initialValue.includes(tenant.id)} value={TENANT_INCLUDE} control={<Radio />} style={{ marginLeft: CHECK_BOX_MARGIN }} disabled={tenant.id === tenantId} />
          </Grid>
          <Grid item xs={9} sm={4}>
            <FormControlLabel checked={!initialValue.includes(tenant.id)} value={TENANT_EXCLUDE} control={<Radio />} style={{ marginLeft: CHECK_BOX_MARGIN }} disabled={tenant.id === tenantId} />
          </Grid>
        </Grid>
      </RadioGroup>
    );
  };

  return (
    <>
      <Typography variant="h6" gutterBottom >
        {title || 'Tenants'}
        <Tooltip title={TENNANTS_MESSAGE} placement="bottom-start">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <Typography variant="body1" gutterBottom>
        {"By default, all tenants are included. Use the buttons below to include "}
        {"or exclude specific tenants for this content's audience."}
      </Typography>
      <Grid container>
        <Grid item xs={9} sm={4}></Grid>
        <Grid item xs={9} sm={4}>
          <Typography variant="subtitle2" gutterBottom >
            Include
          </Typography>
        </Grid>
        <Grid item xs={9} sm={4}>
          <Typography variant="subtitle2" gutterBottom >
            Exclude
          </Typography>
        </Grid>
      </Grid>
      {tenantList.map(renderItem)}
      <Grid container>
        <Grid item xs={9} sm={4}></Grid>
        <Grid item xs={9} sm={4}>
          <Button onClick={selectAll(false)}>Select all</Button>
        </Grid>
        <Grid item xs={9} sm={4}>
          <Button onClick={selectAll(true)}>Select all</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default TenantsField;
