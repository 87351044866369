import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Typography, CircularProgress } from '@material-ui/core';

import TestResults from 'components/commons/test-results';
import useStyles from './EditActionSpec.styles';
import { tenantHandler } from 'services/shared/handlers';
import ActionSpecForm from 'components/views/action-specs/form';

const EditActionSpec = ({ actions, actionSpec, actionSpecId, tenants, facts }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  const { testPassed, testResults } = actionSpec;

  useEffect(() => {
    actions.onMount();
    actions.fetchActionSpec(actionSpecId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(actionSpec.actionSpec)) setForm({ ...actionSpec.actionSpec });
  }, [actionSpec]);

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setForm({
        ...form,
        [name]: value,
      });
    };

  const handleTenantCheck =
    (tenantIndex) =>
    ({ target }) => {
      tenantHandler(tenantIndex, target, form, tenants, setForm);
    };

  const handleSubmit = () => {
    actions.updateActionSpec(form);
  };

  const handleFetchSurvey = (searchText, filter_tenants = false) => {
    return actions.fetchSurvey(searchText, filter_tenants);
  };

  const handleFileDownload = (language) => {
    return actions.getLanguageFile(form.id, language);
  };

  const handleMessageCsvDownload = (actionSpecId, category) => {
    return actions.getMessageCsv(actionSpecId, category);
  };

  const handleMessageCsvUpload = (file, actionSpecId, category) => {
    let messageCsv = new FormData();
    messageCsv.append('file', file, file.name);
    actions.saveMessageCsv(messageCsv, actionSpecId, category);
  };

  const handleTemplateFileDownload = () => {
    return actions.getLanguageFileTemplate(form.id);
  };

  const handleFileUpload = (file, language) => {
    let languageFile = new FormData();
    languageFile.append('file', file, file.name);
    actions.saveLanguageFile(languageFile, form.id, language);
  };

  const handleStatusChange = (availableLanguages, disabledLanguages) => {
    setForm({
      ...form,
      availableLangs: [...availableLanguages],
      enableLangs: [...disabledLanguages],
    });
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5">Edit Action Spec</Typography>
        <div className={classes.testResultContainer}>
          <TestResults testPassed={testPassed} testResults={testResults} />
        </div>
      </div>
      {isEmpty(form) || actionSpec.isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <ActionSpecForm
          handleChange={handleChange}
          form={form}
          onSubmit={handleSubmit}
          handleFetchSurvey={handleFetchSurvey}
          handleFileDownload={handleFileDownload}
          handleTemplateFileDownload={handleTemplateFileDownload}
          handleFileUpload={handleFileUpload}
          handleStatusChange={handleStatusChange}
          tenants={tenants}
          handleTenantCheck={handleTenantCheck}
          handleMessageCsvDownload={handleMessageCsvDownload}
          handleMessageCsvUpload={handleMessageCsvUpload}
          nonEditableName={false}
          testResults={[]}
          facts={facts}
          setForm={setForm}
        />
      )}
    </div>
  );
};

export default EditActionSpec;
