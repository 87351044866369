import React, { useRef, useEffect, useState } from 'react';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  Container,
  Grid,
  Paper,
  TextField,
  Tooltip,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';

import Button from 'components/commons/button';
import Form from 'components/commons/form';
import { ItemConditionsField, ItemTestsField } from 'components/commons';

import useStyles from './Form.styles';

const RulesForm = ({ handlers, setForm, currentForm, refId, id, itemSelected}) => {
  const classes = useStyles();
  const errorRef = useRef();
  const [formContext, setFormContext] = useState(
    currentForm.steps.find((step) => {
      if(refId === undefined && step.id === id && itemSelected.contentIdentifier === step.contentIdentifier){
         return step;
      }
      if(refId !== undefined && step.refId === refId){
        return step;
      }
      return null;
    })
  );

  useEffect(() => {
    setForm(formContext);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext]);

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      if (
        name === 'tests' &&
        value.conditions !== undefined &&
        value.conditions[0] !== undefined &&
        value.conditions[0].expected === undefined
      ) {
        return;
      }

      let dynamicName;
      if (name === 'conditions') {
        dynamicName = 'stepConditions';
      } else if (name === 'conditions') {
        dynamicName = 'stepConditions';
      } else if (name === 'tests') {
        dynamicName = 'stepTest';
      } else {
        dynamicName = name;
      }
      setFormContext({
        ...formContext,
        [dynamicName]: value,
      });
    };

  const handleSubmitForm = () => {
    handlers.handleSubmit(formContext);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper>
        <Form onSubmit={handleSubmitForm} className={classes.formContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.headerContainer}>
                Edit Rules
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="ttl"
                label="Time To Live (TTL)"
                type="number"
                value={formContext.ttl || ''}
                onChange={handleChange('ttl')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Amount of time in days the content should be displayed."
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="cooldown"
                label="Cooldown"
                type="number"
                value={formContext.cooldown || ''}
                onChange={handleChange('cooldown')}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Amount of time in days between showing the content for the first time, and showing it again."
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <section className={classes.conditionSection}>
            <Grid item xs={12} sm={8}>
              <ItemConditionsField
                handleChange={handleChange}
                value={formContext.stepConditions}
                title="Conditions"
              />
            </Grid>
            <Grid ref={errorRef} item xs={12} sm={8}>
              <ItemTestsField
                handleChange={handleChange}
                value={formContext.stepTest}
                title={'Tests'}
              />
            </Grid>
          </section>
          <section className={classes.actionsContainer}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                handlers.handleCancel();
              }}
              size="medium"
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit" size="medium">
              Save
            </Button>
          </section>
        </Form>
      </Paper>
    </Container>
  );
};

export default RulesForm;
