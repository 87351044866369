import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { Delete as DeleteIcon } from '@material-ui/icons';
import classNames from 'classnames';
import { constant, isEmpty, update } from 'lodash';
import { useDebounce } from 'utils/hooks';
import useStyles from './ActionSpecMessage.styles';

const ActionSpecMessage = ({
  onClick,
  onChange,
  onDelete,
  initialValue,
  title,
  help,
  isSelected,
  onChangePosition,
  handleMessageCsvDownload,
  handleMessageCsvUpload,
  msgCategory,
}) => {
  const classes = useStyles();
  const [message, setMessage] = useState(null);
  const inputCsv = useRef({});
  const csvLink = useRef({});
  const [csv, setCSV] = useState([]);
  let { id: actionSpecId } = useParams();
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedMessage = useDebounce(message, 500);
  const [showDynamicMessage, setShowDynamicMessage] = useState(false);

  const handleDeleteDynamicMessage = () => {
    setShowDynamicMessage(false);
    inputCsv.current = {};
    csvLink.current = {};
    setCSV([]);
    setMessage({ ...message, dynamicMessage: '' });
    handleChange('message');
    // Create an empty file input to reset the file upload
    const headers = ['location', 'lang', 'category', 'strings'];
    const csvData = headers.join(',') + '\n';
    let file = new File([csvData], 'empty.csv', { type: 'text/csv' });
    handleMessageCsvUpload(file, actionSpecId, msgCategory);
  };

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setMessage(initialValue);
      setGotInitialValue(true);

      if (initialValue.dynamicMessage) {
        setShowDynamicMessage(true);
      }
    }
  }, [initialValue, gotInitialValue]);

  useEffect(() => {
    if (!isEmpty(debouncedMessage)) {
      onChange(debouncedMessage);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMessage]);

  const handleInnerDownload = () => {
    handleMessageCsvDownload(actionSpecId, msgCategory).then((blob) => {
      setCSV(blob);
      csvLink.current.link.click();
    });
  };

  const uploadFile = () => {
    const currentElement = inputCsv.current;
    return currentElement.click();
  };

  const onFileChange = (e) => {
    let file = e.target?.files[0];
    if (file) {
      handleMessageCsvUpload(file, actionSpecId, msgCategory);
    }
  };

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      // Avoid negative and empty timeoutSecs
      if (name === 'timeoutSecs') {
        if (value === '') value = 0;
        else if (value < 0) value = 0;
        else {
          try {
            value = parseInt(value, 10);
          } catch (e) {
            value = 0;
          }
        }
      }
      setMessage({ ...update(message, name, constant(value)) });
      if (name === 'position') onChangePosition(message.name, 0, value);
    };

  return (
    <>
      {message && (
        <Paper
          className={classNames(classes.container, {
            [classes.selected]: isSelected,
          })}
          onClick={onClick}
          elevation={isSelected ? 8 : 1}
          style={
            isSelected ?
            { backgroundColor: '#F4FFFA' } :
            { backgroundColor: '#FAFAFA' }
          }
          square
        >
          <Typography variant="h6" gutterBottom >
            {title}
          </Typography>
          <Typography variant="caption" gutterBottom >
            {help}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextareaAutosize
                className={classes.textarea}
                aria-label="message"
                placeholder="Type generic message"
                value={message.message}
                minRows={5}
                onChange={handleChange('message')}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                {// If message.default is defined
                  message.default && (
                    <FormControl>
                    <FormLabel id="default-radio-buttons-group-label">Default</FormLabel>
                    <RadioGroup
                      aria-labelledby="default-radio-buttons-group-label"
                      row
                      name="default-radio-buttons-group"
                      value={message.default}
                      onChange={handleChange('default')}
                    >
                      <FormControlLabel value="false" control={<Radio />} label="False" />
                      <FormControlLabel value="true" control={<Radio />} label="True" />
                    </RadioGroup>
                    </FormControl>
                    )
                }

                {
                 msgCategory === 'TIMEOUT' && (
                    <FormControl>
                      <TextField
                        id="timeoutSecs"
                        label="Timeout in seconds"
                        type="number"
                        value={message.timeoutSecs}
                        onChange={handleChange('timeoutSecs')}
                        margin="normal"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                          <Tooltip title="Time to await in seconds to before automatically sending the message in the chat channel when the action is triggered.">
                            <IconButton aria-label="timeout">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          </InputAdornment>,
                        }}
                        />
                    </FormControl>
                    )
                }

            </Grid>
            {showDynamicMessage ? (
              <Button
                className={classes.deleteParameterizedMessageButton}
                color="secondary"
                onClick={handleDeleteDynamicMessage}
              >
                Delete message based on location
              </Button>
            ) : (
              <Button color="primary" onClick={() => setShowDynamicMessage(true)}>
                Add message based on location
              </Button>
            )}
          </Grid>
          {showDynamicMessage && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextareaAutosize
                  className={classes.textarea}
                  aria-label="dynamic-message"
                  placeholder="Type message based on location"
                  value={message.dynamicMessage}
                  minRows={5}
                  onChange={handleChange('dynamicMessage')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <input
                  type="file"
                  max="1"
                  ref={(element) => (inputCsv.current = element)}
                  style={{
                    visibility: 'hidden',
                    position: 'absolute',
                  }}
                  accept=".csv"
                  name="file"
                  onChange={(e) => onFileChange(e)}
                />
                {actionSpecId && (
                  <>
                    <CSVLink
                      data={csv}
                      filename="dynamicMessage.csv"
                      className="hidden"
                      ref={csvLink}
                      target="_blank"
                    />
                    <Button
                      className={classes.downloadParamsButton}
                      onClick={handleInnerDownload}
                      color="primary"
                      variant="contained"
                    >
                      Download parameters
                    </Button>
                  </>
                )}
                <Button onClick={uploadFile} color="primary" variant="outlined">
                  Upload parameters
                </Button>
              </Grid>
            </Grid>
          )}
          {isSelected && (
            <div className={classes.actionsContainer}>
              {!!onDelete && (
                <Tooltip title="Delete message">
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </Paper>
      )}
    </>
  );
};

export default ActionSpecMessage;
