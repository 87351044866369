import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TableChartIcon from '@material-ui/icons/TableChart';
import { Typography, CircularProgress } from '@material-ui/core';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import Button from 'components/commons/button';
import CustomSelect from 'components/commons/custom-select';
import { getOp } from 'utils/reports';

import useStyles from './Reports.styles';

const INIT_ROWS_STATE = {
  rowCount: 0,
  page: 0,
  pageSize: 50,
  rows: [],
  loading: false,
};

const CustomToolbar = ({ handleParamsChange }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState({
    Type: { Insight: { status: false }, Pledge: { status: false }, Survey: { status: false } },
    Status: {
      Live: { status: false },
      Draft: { status: false },
      Deactivated: { status: false },
      Review: { status: false },
      Prerelease: { status: false },
    },
  });
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    if (
      (selected.Type.Insight.status ||
        selected.Type.Pledge.status ||
        selected.Type.Survey.status) &&
      (selected.Status.Live.status ||
        selected.Status.Draft.status ||
        selected.Status.Deactivated.status ||
        selected.Status.Review.status ||
        selected.Status.Prerelease.status)
    ) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [selected]);

  const handleClick = () => {
    let type;
    const typeArray = Object.keys(selected.Type);
    typeArray.forEach((typeString) => {
      if (selected.Type[typeString].status) {
        type = typeString.toLowerCase();
      }
    });
    let selectedStatusArray = [];
    const statusKeys = Object.keys(selected.Status);
    statusKeys.forEach((status) => {
      if (selected.Status[status].status) {
        selectedStatusArray.push(status);
      }
    });
    let status = selectedStatusArray.join('|').toLowerCase();
    if (status.includes('deactivated') && type !== 'pledge' && type !== 'insight') {
      status = status.replace('deactivated', 'deprecated');
    }
    handleParamsChange({
      type,
      status,
      page: 0,
    });
  };

  const handleSelect = (selection) => {
    const entryState =
      selection.type === 'Type'
        ? { Type: { ...selection.checkboxesState } }
        : { Status: { ...selection.checkboxesState } };
    if (selection.type === 'Type') {
      let selectedStatusReset = { ...selected };
      selectedStatusReset.Status.Live.status = false;
      selectedStatusReset.Status.Draft.status = false;
      selectedStatusReset.Status.Deactivated.status = false;
      selectedStatusReset.Status.Review.status = false;
      selectedStatusReset.Status.Prerelease.status = false;
      const selectedCopy = { ...selectedStatusReset, ...entryState };
      setSelected(selectedCopy);
    } else {
      const selectedCopy = { ...selected, ...entryState };
      setSelected(selectedCopy);
    }
  };

  return (
    <div className={classes.actionsContainer}>
      <div className={classes.itemsContainer}>
        <CustomSelect
          setSelection={handleSelect}
          multiple={false}
          header="Type"
          options={selected.Type}
        />
      </div>
      <div className={classes.itemsContainer}>
        <CustomSelect
          setSelection={handleSelect}
          multiple={true}
          header="Status"
          options={selected.Status}
          type={selected.Type}
        />
      </div>
      <div className={classes.toolbarContainer}>
        <div className={classes.itemsContainer}>
          <Button
            color="primary"
            disabled={!enable}
            onClick={handleClick}
            startIcon={<TableChartIcon className={classes.tableIcon} />}
          >
            Generate Report
          </Button>
        </div>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      </div>
    </div>
  );
};

const DataTable = ({ actions, reportsData: data, tenants }) => {
  const classes = useStyles();
  const [rowsState, setRowsState] = useState(INIT_ROWS_STATE);
  const [params, setParams] = useState({
    type: '',
    status: '',
  });

  useEffect(() => {
    if (params.type && params.status) {
      actions.getReports(
        params.type,
        params.status,
        rowsState.page + 1,
        rowsState.pageSize,
        tenants
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, rowsState.page, rowsState.pageSize]);

  useEffect(() => {
    setRowsState((prev) => ({
      ...prev,
      rows: data.reports,
      rowCount: data.meta.count,
      loading: false,
    }));
    // eslint-disable-next-line
  }, [data.reports]);

  const Cell = (cellValues) => {
    return (
      <Tooltip title={cellValues.formattedValue || 'empty'}>
        <span className={classes.columnTextWrapper}>{cellValues.formattedValue}</span>
      </Tooltip>
    );
  };

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      headerClassName: classes.columnHeader,
      renderCell: Cell,
    },
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: Cell,
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'string',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: Cell,
    },
    {
      field: 'priority',
      headerName: 'Priority',
      type: 'number',
      width: 110,
      headerAlign: 'center',
      align: 'center',
      renderCell: Cell,
    },
    {
      field: 'ttl',
      headerName: 'TTL',
      type: 'number',
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: Cell,
    },
    {
      field: 'cooldown',
      headerName: 'Cooldown',
      type: 'number',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      renderCell: Cell,
    },
    {
      field: 'contentType',
      headerName: 'Content Type',
      type: 'string',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: Cell,
    },
    {
      field: 'contentIdentifier',
      headerName: 'Identifier',
      type: 'string',
      width: 200,
      headerAlign: 'center',
      renderCell: Cell,
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      width: 200,
      headerAlign: 'center',
      renderCell: Cell,
    },
    {
      field: 'introHeader',
      headerName: 'Header',
      type: 'string',
      width: 200,
      headerAlign: 'center',
      renderCell: Cell,
    },
    {
      field: 'introBody',
      headerName: 'Body',
      type: 'string',
      width: 300,
      headerAlign: 'center',
      renderCell: Cell,
    },
    {
      field: 'conditions',
      headerName: 'Conditions',
      type: 'string',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      renderCell: Cell,
    },
    {
      field: 'tenants',
      headerName: 'Tenants',
      type: 'string',
      width: 140,
      headerAlign: 'center',
      renderCell: Cell,
    },
  ];

  const handleParamsChange = (params) => {
    const { type, status, page } = params;
    setParams({ type, status });
    setRowsState({ ...rowsState, page, rows: [], loading: true });
  };

  const filter = (e) => {
    setRowsState({ ...rowsState, rows: data.reports });
    // eslint-disable-next-line eqeqeq
    if (e.items[0] == null || e.items.length == 0) {
      return;
    }
    const column = e.items[0].columnField;
    const op = e.items[0].operatorValue;
    const operation = getOp[op];
    const value = parseInt(e.items[0].value);
    if (
      (column === 'cooldown' || column === 'ttl' || column === 'id') &&
      !isNaN(value) &&
      operation
    ) {
      const filteredRows = data.reports.filter((row) => operation(parseInt(row[column]), value));
      setRowsState({ ...rowsState, rows: filteredRows });
    }
  };

  return (
    <div>
      <div className={classes.dataGridContainer} id="dataGridId">
        <DataGrid
          className={classes.dataGrid}
          {...rowsState}
          paginationMode="server"
          onFilterModelChange={filter}
          columns={columns}
          autoHeight={true}
          checkboxSelection
          disableSelectionOnClick
          disableColumnMenu
          rowsPerPageOptions={[10, 50, 100]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { handleParamsChange } }}
          onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
          onPageSizeChange={(pageSize) => setRowsState((prev) => ({ ...prev, pageSize }))}
        />
      </div>
      {data.isFetching && !rowsState.loading ? (
        <CircularProgress className={classes.circularProgress} />
      ) : null}
    </div>
  );
};

const Reports = ({ actions, reportsData, tenants }) => {
  const classes = useStyles();

  useEffect(() => {
    actions.onMount();
    if (reportsData.reports.length !== 0) {
      actions.resetReports();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5" gutterBottom>
        Reports
      </Typography>
      {tenants.isFetching ? (
        <CircularProgress className={classes.circularProgress} />
      ) : (
        <DataTable actions={actions} reportsData={reportsData} tenants={tenants.tenants} />
      )}
    </div>
  );
};

export default Reports;
