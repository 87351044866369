import * as service from 'services/tenants';

import { TYPE_RECEIVE_TENANTS, TYPE_REQUEST_TENANTS } from 'data/tenants';

import isEmpty from 'lodash/isEmpty';
import store from 'config/store';

const requestTenants = () => ({ type: TYPE_REQUEST_TENANTS, isFetching: true });

const receiveTenants = (json) => ({
  type: TYPE_RECEIVE_TENANTS,
  tenants: json.map((tenant) => ({
    id: tenant.tid,
    name: tenant.common_name,
  })),
  isFetching: false,
});

const fetchTenants = () => {
  return (dispatch) => {
    dispatch(requestTenants());
    return service
      .fetchTenants()
      .then((response) => dispatch(receiveTenants(response)))
      .catch((error) => {
        console.error(error);
      });
  };
};

export const fetchTenantsIfNeeded = () => {
  return (dispatch) => {
    const state = store.getState();
    const tenants = state.tenants;
    const isFetching = tenants.isFetching;
    if (isEmpty(tenants.tenants) && !isFetching) {
      return dispatch(fetchTenants());
    }
  };
};

export default fetchTenantsIfNeeded;
