import { authorizedAxios } from 'config/axios';
import SurveyFactory from 'factories/surveys';

const FACTUARY_URL = '/factuary/api';

export const fetchSurveys = (filters, page = 0, filter_tenants = false) => {
  const params = new URLSearchParams();

  const copyFilters = { ...filters };

  if (copyFilters['searched text']?.length) {
    params.append('name', copyFilters['searched text'].join('|'))
    params.append('intro_header', copyFilters['searched text'].join('|'))
    params.append('intro_body', copyFilters['searched text'].join('|'))
    params.append('question_text', copyFilters['searched text'].join('|'))
    delete copyFilters['searched text']
  }

  if (copyFilters) {
    Object.entries(copyFilters).forEach(([type, values]) => {
      if (values.length) params.append(type, values.join('|'));
    });
  }

  if (page >= 0) {  // allow disabling pagination
    if (page) params.append('page', page);
    params.append('per_page', 10);
  }

  if (filter_tenants) {
    params.append('tenant', localStorage.getItem('tenantId'));
  }

  return authorizedAxios.get(`${FACTUARY_URL}/v2/content/filter?content_type=survey&${params}`).then(response => {

    return ({
      meta: {
        nextPage: response.meta.next_page,
        currentPage: response.meta.page
      },
      data: {
        surveys: response.data.map(SurveyFactory.toModel)
      }
    })
  }
  );
};

export const createSurvey = survey => {
  return authorizedAxios.post(`${FACTUARY_URL}/v1/surveys`, SurveyFactory.toDTO(survey));
};

export const updateSurvey = survey => {
  return authorizedAxios.put(`${FACTUARY_URL}/v1/surveys/${survey.id}`, SurveyFactory.toDTO(survey));
};

export const fetchSurvey = id => {
  return authorizedAxios.get(`${FACTUARY_URL}/v1/surveys/${id}`).then(response => ({
    ...response,
    survey: SurveyFactory.toModel(response.survey),
  }));
};

export const getLanguageFile = (surveyId, languageCode) => {
  return authorizedAxios.get(`/factuary/api/v2/strings/survey/csv?content_id=${surveyId}&lang=${languageCode}`);
};

export const getLanguageFileTemplate = (surveyId) => {
  return authorizedAxios.get(`/factuary/api/v2/strings/survey/csv?content_id=${surveyId}`)
    .then((response) => response)
    .catch(err => '');
};


export const postLanguageFile = (languageFile, surveyId, languageCode) => {
  return authorizedAxios.post(`/factuary/api/v2/strings/survey/csv?content_id=${surveyId}&lang=${languageCode}`, languageFile);
};
