import { goBack } from 'connected-react-router';

import * as service from 'services/modules';
import * as data from 'data/modules';

const requestModule = () => ({
  type: data.TYPE_REQUEST_MODULE,
});

const receiveModule = (json) => {
  return {
    type: data.TYPE_RECEIVE_MODULE,
    module: json.programModule,
  };
};

const receiveModuleError = (error) => ({
  type: data.TYPE_RECEIVE_MODULE_ERROR,
  error: error,
});

const requestContent = () => ({
  type: data.TYPE_REQUEST_CONTENT,
});

const reiceiveContent = (json) => ({
  type: data.TYPE_RECEIVE_CONTENT,
  content: json.content,
  isFetching: false,
});

const postCreateModule = (module) => ({
  type: data.TYPE_CREATE_MODULE,
  module,
});

const postCreateModuleSuccess = (module, json) => ({
  type: data.TYPE_CREATE_MODULE_SUCCESS,
  module,
});

const postCreateModuleError = (module, error) => ({
  type: data.TYPE_CREATE_MODULE_ERROR,
  error,
  module,
});

const postUpdateModule = (module) => ({
  type: data.TYPE_UPDATE_MODULE,
  module,
});

const postUpdateModuleSuccess = (module, json) => ({
  type: data.TYPE_UPDATE_MODULE_SUCCESS,
  module: module,
});

const postUpdateModuleError = (module, error) => ({
  type: data.TYPE_UPDATE_MODULE_ERROR,
  error,
});

const postUpgradeModule = (module) => ({
  type: data.TYPE_UPGRADE_MODULE,
  module,
});

const postUpgradeModuleSuccess = (module, json) => ({
  type: data.TYPE_UPGRADE_MODULE_SUCCESS,
  module: module,
});

const postUpgradeModuleError = (module, error) => ({
  type: data.TYPE_UPGRADE_MODULE_ERROR,
  error,
});

export const resetModule = () => ({
  type: data.TYPE_RESET_MODULE,
});

export const fetchModule = (id) => {
  return (dispatch) => {
    dispatch(requestModule());
    return service
      .fetchModule(id)
      .then((response) => {
        dispatch(receiveModule(response));
      })
      .catch((error) => dispatch(receiveModuleError(error)));
  };
};

export const createModule = (programModule, cancel, back = false) => {
  if (cancel)
    return (dispatch) => {
      dispatch(goBack());
    };
  return (dispatch) => {
    dispatch(postCreateModule(programModule));
    return service
      .createModule(programModule)
      .then((response) => {
        dispatch(postCreateModuleSuccess(programModule, response));
        if (back) dispatch(goBack());
      })
      .catch((error) => dispatch(postCreateModuleError(programModule, error)));
  };
};

export const updateModule = (module, cancel, back = false) => {
  if (cancel)
    return (dispatch) => {
      dispatch(goBack());
    };
  return (dispatch) => {
    dispatch(postUpdateModule(module));
    return service
      .updateModule(module)
      .then((response) => {
        dispatch(postUpdateModuleSuccess(module, response));
        if (back) dispatch(goBack());
      })
      .catch((error) => dispatch(postUpdateModuleError(module, error)));
  };
};

export const upgradeModule = (module, cancel, back = false) => {
  if (cancel)
    return (dispatch) => {
      dispatch(goBack());
    };
  return (dispatch) => {
    dispatch(postUpgradeModule(module));
    return service
      .upgradeModule(module)
      .then((response) => {
        dispatch(postUpgradeModuleSuccess(module, response));
        if (back) dispatch(goBack());
      })
      .catch((error) => dispatch(postUpgradeModuleError(module, error)));
  };
};

export const getContent = (query, content_type, type) => {
  return (dispatch) => {
    dispatch(requestContent());
    return service
      .getContent(query, content_type, type)
      .then((response) => {
        dispatch(reiceiveContent(response));
      })
      .catch((error) => dispatch(postUpdateModuleError(query, error)));
  };
};

export const getContentIfNeeded = ({ query, content_type, type }) => {
  return (dispatch) => {
    if (query.length > 2 && content_type) {
      return dispatch(getContent(query, content_type, type));
    }
  };
};

export const getProgramsModuleByOngoingState = (id) => {
  return async () => {
    try {
      const response = await service.programsModuleByOngoingState(id);
      return response
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
