import { pink, teal } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Gotham', 'Helvetica Neue', Arial, Helvetica, sans-serif",
    header19: { fontSize: '1.9rem', fontWeight: 700, lineHeight: 1.26315789 },
    header12: { fontSize: '12px', fontWeight: 700, lineHeight: 1.5 },
    para12: { fontSize: '12px', fontWeight: 500, lineHeight: 1.5 },
    icon: { fontSize: '18px' },
    button: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: 1.5,
    },
  },
  palette: {
    primary: teal,
    secondary: pink,
    customBackground: {
      input: 'rgba(255, 255, 255, 0.12)',
    },
    custom: {
      cyan: '#15bccf',
      red: '#e66067',
      white: '#fff',
      white20: 'rgba(255, 255, 255, 0.2)',
      white40: 'rgba(255, 255, 255, 0.4)',
      white60: 'rgba(255, 255, 255, 0.6)',
      white80: 'rgba(255, 255, 255, 0.8)',
      black: '#505661',
      black80: 'rgba(80, 86, 97, 0.8)',
      black60: 'rgba(80, 86, 97, 0.6)',
      black40: 'rgba(80, 86, 97, 0.4)',
      black20: 'rgba(80, 86, 97, 0.2)',
      black10: 'rgba(80, 86, 97, 0.1)',
      blackFill: '#434852',
      blue: '#516fad',
      brown: '#8f757b',
      cerulean: '#4697c4',
      gold: '#fcb500',
      green: '#40b89c',
      orange: '#fc9c42',
      magenta: '#d46ab7',
      pink: '#eb719a',
      purple: '#a66ccc',
      yellow: '#fcca00',
      gradientBlack: 'linear-gradient(180deg, #5e6570 0%, #505661 100%)',
      gradientBlue: 'linear-gradient(0deg, #516ead 36.98%, #6285d1 100%)',
      gradientCyan: 'linear-gradient(0deg, #08bbcf 0%, #12cee3 100%)',
      gradientGold: 'linear-gradient(0deg, #f0ac00 2.35%, #fcb500 100%)',
      gradientGreen: 'linear-gradient(180deg, #2fcca7 0%, #2ab897 100%)',
      gradientRed: 'linear-gradient(180deg, #e66067 0%, #d65158 100%)',
      greyA: '#fafafa',
      greyB: '#f7f7f7',
      greyC: ' #f2f2f2',
      greyD: ' #eeeeee',
      greyE: '#e0e0e0',
      lightBlue: ' #edf1fa',
      lightBrown: '#f5f0f1',
      lightCerulean: '#e8f4fa',
      lightCyan: '#ebf8fa',
      lightGold: '#fff6e0',
      lightGreen: '#e8faf6',
      lightOrange: '#fff4eb',
      lightMagenta: '#fff2fc',
      lightPink: '#fff2f7',
      lightPurple: '#f6f0fa',
      lightRed: '#fff2f3',
      lightYellow: '#fff8db',
      darkLightCyan: '#e1f2f5',
      cyan20: 'rgba(113, 226, 244, 0.2)',
      cyan40: 'rgba(113, 226, 244, 0.4)',
      gold20: 'rgba(255, 204, 77, 0.2)',
      green20: 'rgba(87, 244, 210, 0.2)',
      green40: 'rgba(33, 184, 153, 0.4)',
      red5: 'rgba(230, 96, 103, 0.05)',
      red20: 'rgba(230, 96, 103, 0.2)',
      red40: 'rgba(230, 96, 103, 0.4)',
      purple5: 'rgba(166, 108, 204, 0.05)',
      purple20: 'rgba(166, 108, 204, 0.2)',
      purple40: 'rgba(115, 90, 214, 0.4)',
      magenta5: 'rgba(224, 110, 186, 0.05)',
      blue5: 'rgba(81, 111, 173, 0.05)',
    },
  },
  overrides: {
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: teal[500],
        },
      },
    },
  },
});

export default theme;
