import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import {
  createPledge,
  resetPledge,
  getLanguageFile,
  saveLanguageFile,
  getLanguageFileTemplate,
} from 'actions/pledge';
import { fetchLabelsIfNeeded } from 'actions/labels';
import { fetchFactsIfNeeded } from 'actions/facts';

import { CreatePledge } from 'views';
import { fetchTenantsIfNeeded } from '../../../actions/tenants';

const mapStateToProps = state => ({
  facts: state.facts,
  labels: state.labels,
  pledge: state.pledge,
  tenants: state.tenants
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        createPledge: createPledge,
        getLanguageFile,
        saveLanguageFile,
        getLanguageFileTemplate,
        push
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchLabelsIfNeeded({ fields: ['id', 'name'] }));
      dispatch(fetchFactsIfNeeded());
      dispatch(fetchTenantsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetPledge());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePledge);
