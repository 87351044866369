import React, { useEffect, useState } from 'react';
import { isEmpty, padStart } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { idGenerator } from 'utils/generator';
import { useDebounce } from 'utils/hooks';

import { Button } from '@material-ui/core';

import useStyles from './TasksList.styles';
import TasksListItem from './TasksListItem';
import { ACTION_SPEC_NEW_TASK } from 'data/actionSpecs';

const sortTask = (taskA, taskB) => {
  return taskA.position - taskB.position;
};

const TasksList = ({ initialValue, onChange, fetchSurvey }) => {
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedTasks = useDebounce(tasks, 500);
  const [tasksOrder, setTasksOrder] = useState(tasks);
  const [showOnlyInput, setShowOnlyInput] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setTasks(initialValue);
      setGotInitialValue(true);
    }
  }, [initialValue, gotInitialValue]);

  useEffect(() => {
    if (debouncedTasks) {
      onChange({ target: { value: tasks } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTasks]);

  useEffect(() => {
    setTasksOrder(tasks);
  }, [tasks]);

  const handleChange = (taskKey) => (value) => {
    let newTasks = [...tasks];
    newTasks[taskKey] = value;
    setTasks(newTasks);
  };

  const handlePosition = (name, pos, newPos) => {
    let newTasks = tasks.map((q) => orderMapTasks(q, pos, newPos, name));
    setTasks(newTasks);
  };

  const orderMapTasks = (task, pos, newPos, name) => {
    if (newPos > pos && task.position <= newPos && task.position > pos && task.name !== name) {
      task.position -= 1;
    } else if (
      newPos < pos &&
      task.position >= newPos &&
      task.position < pos &&
      task.name !== name
    ) {
      task.position += 1;
    }
    return task;
  };

  const handleAddTask = () => {
    const newTask = {
      ...ACTION_SPEC_NEW_TASK,
      position: tasks.length + 1,
      internalId: idGenerator.next().value,
      name: `task_${padStart(tasks.length + 1, 3, '0')}`,
    };

    setTasks([...tasks, newTask]);
  };

  const handleDeleteTask = (deleteKey) => () => {
    const newTasks = tasks.filter((_, taskKey) => taskKey !== deleteKey);
    setTasks(newTasks);
  };

  const handleOnDragStart = (result) => {
    const taskSelected = tasksOrder.filter((task) => task.internalId === result.draggableId);
    setSelectedTask(taskSelected[0].position);
    setShowOnlyInput(false);
    setIsDragging(true);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(tasksOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    const itemsReordered = items.map((task, ind) => {
      task.position = ind;
      return task;
    });

    setTasksOrder(itemsReordered);
    setTasks(itemsReordered);

    const taskSelected = tasksOrder.filter((task) => task.internalId === result.draggableId);

    setSelectedTask(taskSelected[0].position);
    setIsDragging(false);
  };

  const renderTask = (task, taskKey) => {
    const isSelected = taskKey === selectedTask;

    return (
      <Draggable key={task.internalId} draggableId={task.internalId} index={taskKey}>
        {(provided) => (
          <li
            className={classes.li}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            id={task.id}
          >
            <TasksListItem
              key={task.internalId}
              size={tasks.length}
              fetchSurvey={fetchSurvey}
              onChange={handleChange(taskKey)}
              onChangePosition={handlePosition}
              onClick={() => setSelectedTask(taskKey)}
              initialValue={task}
              isSelected={isSelected}
              showOnlyInput={showOnlyInput}
              isDragging={isDragging}
              onDelete={handleDeleteTask(taskKey)}
            />
          </li>
        )}
      </Draggable>
    );
  };

  return (
    <>
      <DragDropContext
        onBeforeCapture={() => setShowOnlyInput(true)}
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
      >
        <Droppable droppableId="tasks">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className={classes.ul}>
              {tasksOrder.sort(sortTask).map(renderTask)}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className={classes.addContainer}>
        <Button color="primary" onClick={handleAddTask}>
          Add Task
        </Button>
      </div>
    </>
  );
};

export default TasksList;
