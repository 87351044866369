import React from 'react';

import { Box } from '@material-ui/core';

import EvidenceList from '../evidence/EvidenceList';

const EvidenceForm = ({ form, handleChange }) => {
  return (
    <Box pt={4} pb={4}>
      <EvidenceList initialValue={form.evidence} onChange={handleChange('evidence')} />
    </Box>
  );
};

export default EvidenceForm;
