import React, { useEffect, useState } from 'react';
import { ACTION_SPEC_NEW_EVIDENCE } from 'data/actionSpecs';
import { isEmpty, padStart } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { idGenerator } from 'utils/generator';
import { useDebounce } from 'utils/hooks';

import { Button } from '@material-ui/core';

import useStyles from './EvidenceList.styles';
import EvidenceListItem from './EvidenceListItem';

const sortEvidence = (evidenceA, evidenceB) => {
  return evidenceA.position - evidenceB.position;
};

const EvidenceList = ({ initialValue, onChange }) => {
  const classes = useStyles();
  const [evidence, setEvidence] = useState([]);
  const [selectedEvidence, setSelectedEvidence] = useState(null);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedEvidence = useDebounce(evidence, 500);
  const [evidenceOrder, setEvidenceOrder] = useState(evidence);
  const [showOnlyInput, setShowOnlyInput] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setEvidence(initialValue);
      setGotInitialValue(true);
    }
  }, [initialValue, gotInitialValue]);

  useEffect(() => {
    setEvidenceOrder(evidence);
  }, [evidence]);

  useEffect(() => {
    if (debouncedEvidence) {
      onChange({ target: { value: evidence } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEvidence]);

  const handleChange = (evidenceKey) => (value) => {
    let newEvidence = [...evidence];
    newEvidence[evidenceKey] = value;
    setEvidence(newEvidence);
  };

  const handlePosition = (name, pos, newPos) => {
    let newEvidence = evidence.map((q) => orderMapEvidence(q, pos, newPos, name));
    setEvidence(newEvidence);
  };

  const orderMapEvidence = (evidence, pos, newPos, name) => {
    if (
      newPos > pos &&
      evidence.position <= newPos &&
      evidence.position > pos &&
      evidence.name !== name
    ) {
      evidence.position -= 1;
    } else if (
      newPos < pos &&
      evidence.position >= newPos &&
      evidence.position < pos &&
      evidence.name !== name
    ) {
      evidence.position += 1;
    }

    return evidence;
  };

  const handleAddEvidence = () => {
    const newEvidence = {
      ...ACTION_SPEC_NEW_EVIDENCE,
      position: evidence.length + 1,
      internalId: idGenerator.next().value,
      name: `${padStart(evidence.length + 1, 3, '0')}`,
    };

    setEvidence([...evidence, newEvidence]);
  };

  const handleDeleteEvidence = (deleteKey) => () => {
    const newEvidence = evidence.filter((_, evidenceKey) => evidenceKey !== deleteKey);
    setEvidence(newEvidence);
  };

  const handleOnDragStart = (result) => {
    const evidenceSelected = evidenceOrder.filter(
      (evidence) => evidence.internalId === result.draggableId
    );
    
    setSelectedEvidence(evidenceSelected[0].position);
    setShowOnlyInput(false);
    setIsDragging(true);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(evidenceOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    const itemsReordered = items.map((evidence, ind) => {
      evidence.position = ind;
      return evidence;
    });

    setEvidenceOrder(itemsReordered);
    setEvidence(itemsReordered);

    const evidenceSelected = evidenceOrder.filter(
      (evidence) => evidence.internalId === result.draggableId
    );

    setSelectedEvidence(evidenceSelected[0].position);
    setIsDragging(false);
  };

  const renderEvidence = (evidence, evidenceKey) => {
    const isSelected = evidenceKey === selectedEvidence;
    const hasPrimary = evidenceOrder.some((evidence) => evidence.order === "PRIMARY");

    return (
      <Draggable key={evidence.internalId} draggableId={evidence.internalId} index={evidenceKey}>
        {(provided) => (
          <li
            className={classes.li}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            id={evidence.id}
          >
            <EvidenceListItem
              key={evidence.internalId}
              size={evidence.length}
              onChange={handleChange(evidenceKey)}
              onChangePosition={handlePosition}
              onClick={() => setSelectedEvidence(evidenceKey)}
              initialValue={evidence}
              isSelected={isSelected}
              showOnlyInput={showOnlyInput}
              isDragging={isDragging}
              hasPrimary={hasPrimary}
              onDelete={handleDeleteEvidence(evidenceKey)}
            />
          </li>
        )}
      </Draggable>
    );
  };

  return (
    <>
      <DragDropContext
        onBeforeCapture={() => setShowOnlyInput(true)}
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
      >
        <Droppable droppableId="evidence">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className={classes.ul}>
              {evidenceOrder.sort(sortEvidence).map(renderEvidence)}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className={classes.addContainer}>
        <Button color="primary" onClick={handleAddEvidence}>
          Add Evidence
        </Button>
      </div>
    </>
  );
};

export default EvidenceList;
