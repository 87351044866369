import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchActionSpecs } from 'actions/actionSpecs';
import { ActionSpecs } from 'views';
import fetchTenantsIfNeeded from '../../../actions/tenants';

const mapStateToProps = (state) => ({
  programs: state.programs,
  modules: state.modules,
  tenants: state.tenants,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        push,
        fetchActionSpecs,
        fetchTenantsIfNeeded,
      },
      dispatch
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionSpecs);
