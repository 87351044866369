import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Typography, CircularProgress } from '@material-ui/core';

import PopUpOkCancel from 'components/commons/pop-up-ok-cancel';
import TestResults from 'components/commons/test-results';
import ModuleForm from 'components/views/programs/modules/form';
import useStyles from './EditModule.styles';
import { ADDITIONAL_CONTENT_CONDITIONS } from 'data/modules';
import { compareForm } from 'utils/commons';


const EditModule = ({ actions, programs, programModule, programModuleId, content, facts }) => {
  const classes = useStyles();
  const [initialForm, setInitialForm] = useState({});
  const [form, setForm] = useState({});
  const [contentForm, setContentForm] = useState({
    query: { name: '', index: null },
    options: [],
    programs: [],
    type: [],
    conditions: {},
    tests: {},
  });
  const [versioningModulePopup, setVersioningModulePopup] = useState(false);
  const [affectedPrograms, setAffectedPrograms] = useState([]);
  const [edgesState, setEdgesState] = useState([]);
  const[cancelState, setCancelState] = useState(false);
  const [backState, setBackState] = useState(false);

  const keysToCompare = {
    edges: {
      conditions: true,
      contentSubStatus: true,
      delay: true,
      dst: true,
      src: true,
      trigger: true,
    },
    steps: {
      contentIdentifier: true,
      id: true,
      refId: true,
      stepConditions: true,
    },
  }

  useEffect(() => {
    actions.onMount();
    actions.fetchModule(programModuleId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(programModule.module)) {
      const newModule = { ...programModule.module };
      if (newModule.steps) {
        const newSteps = newModule?.steps?.map((item, index) => {
          const edge = newModule.edges[index] != null ? newModule.edges[index] : {};
          return {
            ...item,
            trigger: edge.trigger,
            tests: edge.tests,
            edgeConditions: edge.conditions,
            contentSubStatus: edge.contentSubStatus ? edge.contentSubStatus : ADDITIONAL_CONTENT_CONDITIONS[item.contentType].map(() => ""),
            visualConditions: edge.visualConditions,
            delay: edge.delay
          };
        });
        setForm({ ...newModule, steps: newSteps });
        if (isEmpty(initialForm)) {
          setInitialForm({ ...newModule, steps: [...newSteps] })
        }
      } else {
        setForm(newModule);
      }
      setContentForm({
        ...contentForm,
        programs: programs.programs.map((program) => {
          return { value: program.id, text: program.name };
        }),
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programModule.module]);

  const handleVersioningModulePopupCancel = () => {
    setVersioningModulePopup(false);
  }

  const handleVersioningModulePopupOK = () => {
    setVersioningModulePopup(false);
    setInitialForm({ ...form, edges: [...edgesState] })
    actions.upgradeModule(
      { ...form, edges: edgesState },
      cancelState,
      backState
    );
  }

  const handleSubmit = (cancel = false, back = false) => {
    const newEdges = form.steps.map((item, index) => {
      if (index + 1 >= form.steps.length) {
        return {};
      }
      const nextItem = form.steps[index + 1];
      return {
        delay: item.delay,
        src: item.contentIdentifier,
        dst: nextItem.contentIdentifier,
        conditions: item.edgeConditions,
        trigger: item.trigger,
        tests: item.tests,
        contentSubStatus: item.contentSubStatus,
        visualConditions: item.visualConditions
      };
    });
    newEdges.pop();
    if (form.steps.length === 1) {
      newEdges.unshift({
        dst: form.steps[0].contentIdentifier,
        conditions: [],
      });
    }

    // Check if there are any 'important' changes in the form
    const diff = compareForm(initialForm, form, keysToCompare);
    if (cancel || (Object.keys(diff).length === 0)) {
      actions.updateModule(
        { ...form, edges: newEdges },
        cancel,
        cancel || back
      );
      return;
    }

    actions.getProgramsModuleByOngoingState(form.id)
      .then((response) => {
        if (response.ongoing.length > 0 || response.not_ongoing.length > 0) {
          const totalPrograms = response.ongoing.length + response.not_ongoing.length + response.outdated.length;
          const ongoingList = <ul>
            {response.ongoing.map(program => (
              <li key={program.id}>({program.id}) {program.name}</li>
            ))}
          </ul>
          const notOngoingList = <ul>
            {response.not_ongoing.map(program => (
              <li key={program.id}>({program.id}) {program.name}</li>
            ))}
          </ul>
          const body = (<>
            This module is currently assigned to <strong>{totalPrograms} programs</strong>.
            <br /><br />
            <strong>Ongoing Programs</strong> - {response.ongoing.length} of {totalPrograms} are going to be upgraded:
            {ongoingList}
            <br />
            <strong>Not Ongoing Programs</strong> - {response.not_ongoing.length} of {totalPrograms} are going to be updated:
            {notOngoingList}
            <br />
            {response.outdated.length} outdated programs won't be changed
            <br /><br />
            Click <strong>OK</strong> to proceed or <strong>CANCEL</strong> to go back to the edit page.
          </>);
          setVersioningModulePopup(true);
          setAffectedPrograms(body);
          setEdgesState(newEdges);
          setCancelState(cancel);
          setBackState(back);
        } else {
          setInitialForm({ ...form, edges: [...newEdges] });
          actions.updateModule(
            { ...form, edges: newEdges },
            cancel,
            back
          );
        }
    })
    .catch((error) => console.error(error));
  };

  const handlers = {
    handleSubmit,
  };

  const isFetching = programs.isFetching || programModule.isFetching;

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5">Edit Module</Typography>
        <div className={classes.testContainer}>
          <TestResults testPassed={programModule.testResult} />
        </div>
      </div>
      {versioningModulePopup ? (
        <PopUpOkCancel
          isOpen={true}
          handleOk={handleVersioningModulePopupOK}
          handleCancel={handleVersioningModulePopupCancel}
          title="Module versioning"
          body={affectedPrograms}
        />
      ) : (
        ''
      )}
      {isEmpty(form) || isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <ModuleForm
          {...{
            form,
            contentForm,
            handlers,
            setForm,
            setContentForm,
            edit: true,
            actions,
            content,
            facts
          }}
        />
      )}
    </div>
  );
};

export default EditModule;
