import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.custom.black,
    marginTop: '48px',
    backgroundColor: theme.palette.custom.greyA,
    borderRadius: '6px',
    padding: '9px 15px',
    display: 'flex',
    gap: 1,
  },
  whiteBackground: {
    backgroundColor: theme.palette.custom.white,
  },
  heartIcon: {
    color: theme.palette.custom.red,
    margin: '0 6px',
  },
}));

export default useStyles;
