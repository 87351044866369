import React, { useMemo, useEffect } from 'react';
import Sidebar from 'components/templates/sidebar';
import Survey from 'components/views/surveys/survey';

import Columns from '../columns';
import useStyles from './Surveys.styles';

const Surveys = ({ actions, tenants }) => {
  const classes = useStyles();

  const menuOptions = useMemo(() => {
    const TENANT_OPTIONS = tenants
      ? tenants.tenants.map((tenant) => ({ value: tenant.id, text: tenant.name }))
      : [];

    return [
      {
        text: 'Type',
        value: 'type',
        children: [
          {
            text: 'Baselining',
            value: 'baselining',
          },
          {
            text: 'Hypertension',
            value: 'hypertension',
          },
          {
            text: 'Sleep',
            value: 'sleep',
          },
          {
            text: 'Arrhythmia',
            value: 'arrhythmia',
          },
          {
            text: 'Heart',
            value: 'heart',
          },
          {
            text: 'Diabetes',
            value: 'diabetes',
          },
          {
            text: 'Ad hoc',
            value: 'ad_hoc',
          },
          {
            text: 'Review',
            value: 'review',
          },
          {
            text: 'Single question',
            value: 'single_question',
          },
        ],
      },
      {
        text: 'Status',
        value: 'status',
        children: [
          {
            text: 'Draft',
            value: 'draft',
          },
          {
            text: 'Review',
            value: 'review',
          },
          {
            text: 'Prerelease',
            value: 'prerelease',
          },
          {
            text: 'Live',
            value: 'live',
          },
          {
            text: 'Deprecated',
            value: 'deprecated',
          },
        ],
      },
      {
        value: 'tenant',
        text: 'Tenant',
        children: TENANT_OPTIONS,
      },
    ];
  }, [tenants]);

  useEffect(() => {
    actions.fetchTenantsIfNeeded();
  }, [actions]);

  const cardBuilder = (survey, index, searchedText) => {
    return <Survey key={index} {...{ actions, survey, index, searchedText }} />;
  };

  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Surveys"
        mainButtonAction={() => actions.push('/surveys/create')}
        mainButtonText="Create survey"
      />
      <div className={classes.surveysContainer}>
        <Columns
          name="surveys"
          service={actions.fetchSurveys}
          menuOptions={menuOptions}
          cardBuilder={cardBuilder}
        />
      </div>
    </div>
  );
};

export default Surveys;
