import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ConditionsTab, ParametersTab, SettingsTab, StringsTab, TranslationsTab } from '../tabs';
import {
  PLEDGE_CATEGORIES,
  PLEDGE_STATUSES,
  PLEDGE_TYPES,
  PRIORITY_LIMIT_EXCEEDED,
  TTL_ERROR_MESSAGE,
} from 'data/pledges';

import ABTestingAndGrouping from 'components/commons/ab-testing-and-grouping';
import Button from 'components/commons/button';
import Form from 'components/commons/form';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { LANGUAGES } from '../../../../data/shared';
import PropTypes from 'prop-types';
import Select from 'components/commons/select';
import { UserCohortException } from 'components/commons/blockly-conditions/utils/index';
import { cloneDeep } from 'lodash';
import { hasDuplicates } from 'utils/commons';
import { openInNewTab } from 'utils/navigation';
import useStyles from './Form.styles';

const tabs = [
  ConditionsTab,
  SettingsTab,
  ParametersTab,
  StringsTab,
  TranslationsTab,
  ABTestingAndGrouping,
];

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography
    component="div"
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    <Box p={3}>{children}</Box>
  </Typography>
);

const languageOptions = Object.keys(LANGUAGES).map((item) => {
  return { value: item, text: LANGUAGES[item].name };
});

const PledgeForm = ({
  facts,
  form,
  setForm,
  handleChange,
  handleCheck,
  handleTenantCheck,
  handleFileDownload,
  handleTemplateFileDownload,
  handleFileUpload,
  handleStatusChange,
  labels,
  tenants,
  onSubmit,
  nonEditableName = false,
  testResults,
}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleTabChange = (e, newValue) => setTabValue(newValue);

  const handleConditions =
    (name) =>
    ({ target: { value } }) => {
      if (value != null) {
        setForm({
          ...form,
          [name]: value,
        });
      }
    };

  const handleBlocklyCondition = (code, diff, XML, clear = false) => {
    const newForm = cloneDeep(form);
    if (XML?.childNodes?.length > 0) {
      newForm.visualConditions = new XMLSerializer().serializeToString(XML);
    }

    if (clear) {
      newForm.conditions = [];
      newForm.visualConditions = '<xml></xml>';
    }

    if (code) {
      const newConditions = code;
      var aliasArray = [];
      newConditions.forEach((condition) => {
        if (condition.includes('user_cohort')) {
          aliasArray = aliasArray.concat(condition.match(/\?\w+&/g));
        }
      });

      if (hasDuplicates(aliasArray)) {
        throw new UserCohortException();
      }
      newForm.conditions = newConditions;
    }

    setForm(newForm);
  };

  const handleContentPreview = () => {
    openInNewTab(`/previews/content/pledges/${form.id}`);
  };

  return (
    <Form className={classes.form} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Select
            label="Category"
            id="category"
            onChange={handleChange('category')}
            value={form.category}
            options={PLEDGE_CATEGORIES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Type"
            id="type"
            onChange={handleChange('type')}
            value={form.type}
            options={PLEDGE_TYPES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Status"
            id="status"
            onChange={handleChange('status')}
            value={form.status}
            options={PLEDGE_STATUSES}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Language"
            id="lang"
            onChange={(e) => handleChange('lang')(e)}
            value={form.lang}
            options={languageOptions}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            label="Name"
            type="text"
            value={form.name}
            onChange={handleChange('name')}
            disabled={nonEditableName}
            InputProps={{
              readOnly: nonEditableName,
            }}
            margin="normal"
            fullWidth
            required
            inputProps={{
              maxLength: 150,
              minLength: 5,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="priority"
            label="Priority"
            type="number"
            value={form.priority}
            onChange={handleChange('priority')}
            margin="normal"
            error={form.priority > 200}
            helperText={form.priority > 200 ? PRIORITY_LIMIT_EXCEEDED : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Value between 0 and 200." placement="top-start">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="ttl"
            label="Time To Live (TTL)"
            type="number"
            value={form.ttl || ''}
            onChange={handleChange('ttl')}
            margin="normal"
            error={!form.multiDay && form.ttl > 1}
            helperText={!form.multiDay && form.ttl > 1 ? TTL_ERROR_MESSAGE : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days the pledge should be displayed."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="cooldown"
            label="Cooldown"
            type="number"
            value={form.cooldown || ''}
            onChange={handleChange('cooldown')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Amount of time in days between showing the pledge for the first time, and showing it again."
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Title"
            type="text"
            value={form.headerText}
            onChange={handleChange('headerText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Body"
            type="text"
            value={form.bodyText}
            onChange={handleChange('bodyText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Accept"
            type="text"
            value={form.acceptText}
            onChange={handleChange('acceptText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Decline"
            type="text"
            value={form.declineText}
            onChange={handleChange('declineText')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Duration"
            type="number"
            value={form.durationEstimate}
            onChange={handleChange('durationEstimate')}
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Value in seconds." placement="top-start">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Button color="primary" variant="contained" type="submit" size="medium">
        Save
      </Button>
      {form.id && (
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={handleContentPreview}
          className={classes.previewButton}
        >
          Preview
        </Button>
      )}
      <AppBar elevation={0} position="static" className={classes.tabs}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="Conditions" {...a11yProps(3)} />
          <Tab label="Settings" {...a11yProps(0)} />
          <Tab label="Parameters" {...a11yProps(1)} />
          <Tab label="Strings" {...a11yProps(2)} />
          <Tab label="Translations" {...a11yProps(4)} />
          <Tab label="AB Testing & Grouping" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {tabs.map((TabComponent, index) => (
        <TabPanel key={index} value={tabValue} index={index}>
          <TabComponent
            classes={classes}
            form={form}
            setForm={setForm}
            handleChange={handleChange}
            handleConditions={handleConditions}
            handleBlocklyCondition={handleBlocklyCondition}
            handleCheck={handleCheck}
            handleTenantCheck={handleTenantCheck}
            handleFileDownload={handleFileDownload}
            handleTemplateFileDownload={handleTemplateFileDownload}
            handleFileUpload={handleFileUpload}
            handleStatusChange={handleStatusChange}
            facts={facts.facts}
            labels={labels.labels}
            tenants={tenants.tenants}
            testResults={testResults}
            type="pledge"
          />
        </TabPanel>
      ))}
    </Form>
  );
};

PledgeForm.propTypes = {
  facts: PropTypes.object,
  form: PropTypes.object,
  handleChange: PropTypes.func,
  handleCheck: PropTypes.func,
  handleTenantCheck: PropTypes.func,
  handleFileDownload: PropTypes.func,
  handleTemplateFileDownload: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleStatusChange: PropTypes.func,
  labels: PropTypes.object,
  tenants: PropTypes.object,
  testResults: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default PledgeForm;
