import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

import useStyles from './LoadingComponent.styles';

const LoadingKeycloakInstance = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress color="inherit" />
      </Box>
    </div>
  );
};

export default LoadingKeycloakInstance;
