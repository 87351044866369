import {
  TYPE_REQUEST_ACTION_SPECS,
  TYPE_RECEIVE_ACTION_SPECS,
  TYPE_RECEIVE_ACTION_SPECS_CACHED,
  TYPE_RECEIVE_ACTION_SPECS_ERROR,
} from 'data/actionSpecs';

const initialState = {
  actionSpecs: [],
  isFetching: false,
  lastUpdated: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_ACTION_SPECS:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case TYPE_RECEIVE_ACTION_SPECS:
    case TYPE_RECEIVE_ACTION_SPECS_CACHED:
      return {
        ...state,
        actionSpecs: action.actionSpecs,
        lastUpdated: action.lastUpdated,
        isFetching: action.isFetching,
      };
    case TYPE_RECEIVE_ACTION_SPECS_ERROR:
      return {
        ...state,
        isFetching: action.isFetching,
        error: action.error,
      };
    default:
      return state;
  }
};
