import React from 'react';

import { Box } from '@material-ui/core';

import TasksList from '../tasks/TasksList';

const TasksForm = ({ form, handleChange, handleFetchSurvey }) => {
  return (
    <Box pt={4} pb={4}>
      <TasksList initialValue={form.tasks} onChange={handleChange('tasks')} fetchSurvey={handleFetchSurvey} />
    </Box>
  );
};

export default TasksForm;
