import {
  TYPE_REQUEST_MODULE,
  TYPE_RECEIVE_MODULE,
  TYPE_RECEIVE_MODULE_ERROR,
  TYPE_UPDATE_MODULE,
  TYPE_UPDATE_MODULE_SUCCESS,
  TYPE_UPDATE_MODULE_ERROR,
  TYPE_UPGRADE_MODULE,
  TYPE_UPGRADE_MODULE_SUCCESS,
  TYPE_UPGRADE_MODULE_ERROR,
  TYPE_CREATE_MODULE,
  TYPE_CREATE_MODULE_SUCCESS,
  TYPE_CREATE_MODULE_ERROR,
  TYPE_RESET_MODULE,
  TYPE_RECEIVE_CONTENT
} from 'data/modules';

export const initialState = {
  module: {},
  isFetching: false,
  lastUpdated: null,
  testResult: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPE_REQUEST_MODULE:
    case TYPE_UPDATE_MODULE:
    case TYPE_UPGRADE_MODULE:
    case TYPE_CREATE_MODULE:
      return {
        ...state,
        isFetching: true,
        testResult: true,
      };
    case TYPE_UPDATE_MODULE_SUCCESS:
    case TYPE_UPGRADE_MODULE_SUCCESS:
    case TYPE_CREATE_MODULE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        module: action.module,
        testResult: true,
      };
    case TYPE_RECEIVE_MODULE:
      return {
        ...state,
        isFetching: false,
        module: action.module,
        testResult: true,
      };
    case TYPE_RECEIVE_CONTENT:
      return {
        ...state,
        isFetching: false,
        content: action.content,
      };
    case TYPE_RECEIVE_MODULE_ERROR:
    case TYPE_UPDATE_MODULE_ERROR:
    case TYPE_UPGRADE_MODULE_ERROR:
    case TYPE_CREATE_MODULE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        testResult: false,
      };
    case TYPE_RESET_MODULE:
      return { ...initialState };
    default:
      return state;
  }
};
