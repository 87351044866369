import React from 'react';
import { Grid, IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';

const WeightTemperatureForm = ({ onChange, option, isTemperature }) => {
  const UNITS_TEXT = isTemperature ? 'value to be set on Celsius' : 'value to be set on Grams';
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          label="Input min"
          type="number"
          value={option.inputMin}
          onChange={onChange(`options[${option.optionKey}].inputMin`)}
          required
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={'Minimum ' + UNITS_TEXT} placement="top-start">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          label="Input max"
          type="number"
          value={option.inputMax}
          onChange={onChange(`options[${option.optionKey}].inputMax`)}
          required
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={'Maximum ' + UNITS_TEXT} placement="top-start">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default WeightTemperatureForm;
