import React from 'react';

import { Box } from '@material-ui/core';

import ActionSpecMessages from '../messages/ActionSpecMessages';

const MessagesForm = ({ form, handleChange, handleMessageCsvDownload, handleMessageCsvUpload }) => {
  return (
    <Box pt={4} pb={4}>
      <ActionSpecMessages
        initialValue={form.messages}
        onChange={handleChange('messages')}
        handleMessageCsvDownload={handleMessageCsvDownload}
        handleMessageCsvUpload={handleMessageCsvUpload}
      />
    </Box>
  );
};

export default MessagesForm;
