import React, { useState } from 'react';
import { Box, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import classNames from 'classnames';
import useStyles from './styles';

const LikertScaleForm = ({ onChange, option }) => {
  const classes = useStyles();
  const [inputIntermediateScale, setIntermediateInputs] = useState(
    option.inputIntermediateScale || []
  );

  const handleAddNewIntermediateInput = () => {
    const newIntermidiateInputs = [...inputIntermediateScale, { label: '' }];
    option.inputIntermediateScale = newIntermidiateInputs;
    setIntermediateInputs(newIntermidiateInputs);
  };

  return (
    <Box spacing={2} className={classes.root}>
      <Grid item xs={12} sm={4}>
        <TextField
          autoFocus
          margin="dense"
          label="Input min"
          type="text"
          value={option.inputMinScale}
          onChange={onChange(`options[${option.optionKey}].inputMinScale`)}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4} className={classes.grid}>
        <Box className={classNames(classes.grid, classes.paddingX)}>
          {inputIntermediateScale.map((input, index) => (
            <TextField
              key={index}
              autoFocus
              margin="dense"
              label={`Input ${index + 2}`}
              type="text"
              defaultValue={input.label}
              value={input.value}
              onChange={onChange(
                `options[${option.optionKey}].inputIntermediateScale[${index}].label`
              )}
              required
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {inputIntermediateScale.length === index + 1 && (
                      <IconButton onClick={handleAddNewIntermediateInput}>
                        <Tooltip title={'Add new intermediate field'} placement="bottom-start">
                          <AddCircleOutlineIcon />
                        </Tooltip>
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          ))}
          {inputIntermediateScale.length <= 0 && (
            <IconButton onClick={handleAddNewIntermediateInput}>
              <Tooltip title={'Add new intermediate field'} placement="bottom-start">
                <AddCircleOutlineIcon />
              </Tooltip>
            </IconButton>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          autoFocus
          margin="dense"
          label="Input max"
          type="text"
          value={option.inputMaxScale}
          onChange={onChange(`options[${option.optionKey}].inputMaxScale`)}
          required
          fullWidth
        />
      </Grid>
    </Box>
  );
};

export default LikertScaleForm;
