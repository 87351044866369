import React, { useState, useEffect } from 'react';
import { update, constant } from 'lodash';
import Typography from '@material-ui/core/Typography';

import InsightForm from 'components/views/insights/form';
import useStyles from './CreateInsight.styles';
import { tenantHandler } from 'services/shared/handlers';

const CreateInsight = ({ actions, facts, insights, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    cooldown: '60',
    name: '',
    type: '',
    priority: '10',
    category: '0',
    status: '0',
    title: '',
    body: '',
    linkSource: '',
    bodyHtml: '',
    referencesHtml: '',
    clientType: '',
    isSmartCoach: false,
    doNotTranslate: false,
    clientConditions: false,
    themeAgnostic: false,
    labels: [],
    facts: [],
    params: {},
    abTestName: '',
    abTestSide: '',
    identifier: '',
    groupName: '',
    watchHead: '',
    watchBody: '',
    hasNotification: false,
    headAlt: '',
    bodyAlt: '',
    appId: '',
    appXid: '',
    conditions: [],
    ttl: '',
    tests: {
      conditions: [],
      params: []
    },
    tenants: [],
    availableLangs: [],
    enableLangs: [],
    lang: 'en',
    durationEstimate: '',
  });

  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = name => e => {
    setForm({ ...update(form, name, constant(e.target.value)) });
  };

  useEffect(() => {
    if (tenants.tenants?.length) {
      setForm({ ...form, tenants: [localStorage.getItem('tenantId')] });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  const updatedLabels = () => {
    const newLabels = form.labels.map(index => {
      const { name, flags, id, type } = labels.labels[index];
      return { name, flags, id, type };
    });
    return newLabels;
  };

  const handleCheck = name => e => setForm({ ...update(form, name, constant(e.target.checked)) });

  const handleTenantCheck = (tenantIndex) => ({ target }) => {
    tenantHandler(tenantIndex, target, form, tenants, setForm);
  };

  const handleSubmit = () => {
    if (validateFields())
      actions.createInsight({ ...form, labels: updatedLabels() });
  };

  const validateFields = () => {
    return !(form.priority > 200);
  };

  const handleFileDownload = (language) => {
    return actions.getLanguageFile(form.id, language);
  };

  const handleTemplateFileDownload = () => {
    return actions.getLanguageFileTemplate(form.id);
  };

  const handleFileUpload = (file, language) => {
    let languageFile = new FormData();
    languageFile.append('file', file, file.name);
    actions.saveLanguageFile(languageFile, form.id, language);
  };

  const handleStatusChange = (availableLanguages, disabledLanguages) => {
    setForm({
      ...form,
      availableLangs: [...availableLanguages],
      enableLangs: [...disabledLanguages],
    });
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Insight</Typography>
      <InsightForm
        facts={facts}
        tenants={tenants}
        form={form}
        setForm={setForm}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleTenantCheck={handleTenantCheck}
        labels={labels}
        onSubmit={handleSubmit}
        nonEditableName={false}
        handleFileDownload={handleFileDownload}
        handleTemplateFileDownload={handleTemplateFileDownload}
        handleFileUpload={handleFileUpload}
        handleStatusChange={handleStatusChange}
      />
    </div>
  );
};

export default CreateInsight;
