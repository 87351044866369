import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette?.background?.default || '#fff',
    color: theme.palette?.text?.primary ?? '#505661',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '425px',
    gap: '6px',
  },
  button: {
    '&.MuiButton-root': {
      ...theme.typography.header12,
      fontFamily: 'Gotham',
      height: '48px',
      textTransform: 'none',
      boxShadow: '0px 15px 18px -11px rgba(80, 86, 97, 0.2)',
      borderRadius: '3px',
      padding: '9px 18px',
      transition: 'none',

      // below is a trick to transition solid color to a gradient
      // https://css-tricks.com/transitioning-gradients/
      position: 'relative',
      zIndex: 0,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '3px',
        opacity: 0,
        transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)',
        zIndex: -1,
      },
      '&:hover': {
        '&.MuiButton-root': {},
        '&::before': {
          opacity: 1,
        },
      },

      '&.Mui-disabled': {
        // background: theme.palette.action.disabledBackground,
        // color: theme.palette.action.disabled,
        boxShadow: 'none',
      },

      color: theme.palette.custom.white,
      background: theme.palette.custom.black,
    },

    '&:hover': {
      '&.MuiButton-root': {
        background: theme.palette.custom.black,
      },
    },
    '&::before': {
      background: theme.palette.custom.gradientBlack,
    },
  },
}));

export default useStyles;
