import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './CreateActionSpec.styles';
import { tenantHandler } from 'services/shared/handlers';
import ActionSpecForm from 'components/views/action-specs/form';

const CreateActionSpec = ({ actions, tenants, facts }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    title: '',
    actionCategory: '',
    type: '',
    name: '',
    priority: '',
    status: '',
    ttl: '',
    cooldown: '',
    flags: '',
    evidence: [],
    tasks: [],
    messages: [],
    params: {},
    conditions: [],
    tests: {
      conditions: [],
      params: [],
    },
    availableLangs: [],
    enableLangs: [],
    tenants: [],
    lang: 'en',
  });

  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenants.tenants?.length) {
      setForm({ ...form, tenants: [localStorage.getItem('tenantId')] });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setForm({
        ...form,
        [name]: value,
      });
    };

  const handleTenantCheck =
    (tenantIndex) =>
    ({ target }) => {
      tenantHandler(tenantIndex, target, form, tenants, setForm);
    };

  const handleSubmit = () => {
    actions.createActionSpec(form);
  };

  const handleFetchSurvey = (searchText, filter_tenants = false) => {
    return actions.fetchSurvey(searchText, filter_tenants);
  };

  const handleFileDownload = (language) => {
    return actions.getLanguageFile(form.id, language);
  };

  const handleMessageCsvDownload = (actionSpecId, category) => {
    return actions.getMessageCsv(actionSpecId, category);
  };

  const handleMessageCsvUpload = (file, actionSpecId, category) => {
    let messageCsv = new FormData();
    messageCsv.append('file', file, file.name);
    actions.saveMessageCsv(messageCsv, actionSpecId, category);
  };

  const handleTemplateFileDownload = () => {
    return actions.getLanguageFileTemplate(form.id);
  };

  const handleFileUpload = (file, language) => {
    let languageFile = new FormData();
    languageFile.append('file', file, file.name);
    actions.saveLanguageFile(languageFile, form.id, language);
  };

  const handleStatusChange = (availableLanguages, disabledLanguages) => {
    setForm({
      ...form,
      availableLangs: [...availableLanguages],
      enableLangs: [...disabledLanguages],
    });
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Action Spec</Typography>
      <ActionSpecForm
        handleChange={handleChange}
        form={form}
        onSubmit={handleSubmit}
        handleFetchSurvey={handleFetchSurvey}
        tenants={tenants}
        handleTenantCheck={handleTenantCheck}
        facts={facts}
        setForm={setForm}
        handleFileDownload={handleFileDownload}
        handleTemplateFileDownload={handleTemplateFileDownload}
        handleFileUpload={handleFileUpload}
        handleStatusChange={handleStatusChange}
        handleMessageCsvDownload={handleMessageCsvDownload}
        handleMessageCsvUpload={handleMessageCsvUpload}
      />
    </div>
  );
};

export default CreateActionSpec;
