import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import fetchTenantsIfNeeded from 'actions/tenants';
import fetchModules from 'actions/modules';
import { createProgramNoDispatch, fetchProgram, getIsProgramOngoing, markProgramAsOutdated, resetProgram, updateProgram } from 'actions/program';
import { EditProgram } from 'views';
import { fetchFactsIfNeeded } from 'actions/facts';

const mapStateToProps = (
  {
    program,
    modules: { modules },
    tenants,
    facts
  },
  {
    match: {
      params: { id: programId },
    },
  }
) => ({ program, programId, modules, tenants, facts });

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        createProgramNoDispatch,
        fetchProgram,
        getIsProgramOngoing,
        updateProgram,
        push,
        markProgramAsOutdated,
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchModules());
      dispatch(fetchTenantsIfNeeded());
      dispatch(fetchFactsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetProgram());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProgram);
