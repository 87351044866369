import { teal, grey } from '@material-ui/core/colors';
import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 4),
    margin: theme.spacing(2, 0),
  },
  selected: {
    padding: theme.spacing(2, 4),
  },
  textarea: {
    width: '100%',
    border: `1px dashed ${grey[500]}`,
    borderRadius: '5px',
    outlineColor: teal[500],
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteParameterizedMessageButton: {
    marginBottom: '5px',
  },
  downloadParamsButton: {
    marginRight: '5px',
  },
}));
