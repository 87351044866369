import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { idGenerator } from 'utils/generator';
import { useDebounce } from 'utils/hooks';

import { Button } from '@material-ui/core';

import useStyles from './ActionSpecMessages.styles';
import ActionSpecMessage from './ActionSpecMessage';
import { ACTION_SPEC_NEW_MESSAGE } from 'data/actionSpecs';


/* Create an enum for msgCategory */
const msgCategory = {
  REGULAR: 'REGULAR',
  TIMEOUT: 'TIMEOUT',
  OFFHOUR: 'OFFHOUR',
  AUTOMATED: 'AUTOMATED',
};

const sortMessage = (messageA, messageB) => {
  return messageA.position - messageB.position;
};

/* Check if the message have the regular message.
 * The regular message can be identified by the presence of the field named "msgCategory"
 * or if the field is not present.
 * @param {Array} messages - The messages to check.
 * @return {Boolean} - True if the message have the regular message.
 *                     False otherwise.
 */
function have_regular_message(messages) {

  return messages.some((message) => {
    return message.msgCategory === msgCategory.REGULAR || !message.msgCategory;
  });
}

/* Check if the message have the automated message.
  * The automated message can be identified by the presence of the field named "msgCategory"
  * @param {Array} messages - The messages to check.
  * @return {Boolean} - True if the message have the automated message.
  *                    False otherwise.
  */
function have_automated_message(messages) {
  return messages.some((message) => message?.msgCategory === msgCategory.AUTOMATED);
}

/* Check if the message have the timeout message.
 * The timeout message can be identified by the presence of the field named "msgCategory"
 * @param {Array} messages - The messages to check.
 * @return {Boolean} - True if the message have the timeout message.
 *                    False otherwise.
 */
function have_timeout_message(messages) {
  return messages.some((message) => message?.msgCategory === msgCategory.TIMEOUT);
}

/* Check if the message have the offhour message.
 * The offhour message can be identified by the presence of the field named "msgCategory"
  * @param {Array} messages - The messages to check.
  * @return {Boolean} - True if the message have the offhour message.
  *                   False otherwise.
  */
function have_offhour_message(messages) {
  return messages.some((message) => message?.msgCategory === msgCategory.OFFHOUR);
}

/* Build the message based on the msgCategory.
  * @param {String} msgCategory - The type of the message.
  * @param {title} title - The title of the message.
  * @return {Object} - The message object.
  */
function build_message(msgCat) {
  switch (msgCat) {
    case msgCategory.TIMEOUT:
      return {
        message: '',
        dynamicMessage: '',
        position: 0,
        internalId: idGenerator.next().value,
        name: 'message',
        msgCategory: msgCat,
        timeoutSecs: 3600
      };
    case msgCategory.OFFHOUR:
      return {
        message: '',
        dynamicMessage: '',
        position: 0,
        internalId: idGenerator.next().value,
        name: 'message',
        msgCategory: msgCat,
      };
    case msgCategory.AUTOMATED:
      return {
        message: '',
        dynamicMessage: '',
        position: 0,
        internalId: idGenerator.next().value,
        name: 'message',
        msgCategory: msgCat,
      };
    default:
      return {
        ...ACTION_SPEC_NEW_MESSAGE,
        position: 0,
        internalId: idGenerator.next().value,
        name: 'message',
        msgCategory: msgCat,
      };
  }
}

function get_title(msgCat) {
  switch (msgCat) {
    case msgCategory.TIMEOUT:
      return "Timeout Message";
    case msgCategory.OFFHOUR:
      return "Off Hour Message";
    case msgCategory.AUTOMATED:
      return "Automated Message";
    default:
      return "Regular Message";
  }
}

function get_help(msgCat) {
  switch (msgCat) {
    case msgCategory.TIMEOUT:
      return "This message will be send to the patient if the action is not completed within the timeout period.";
    case msgCategory.OFFHOUR:
      return "This message will be send to the patient if the action is not triggered within the tenant working hour. The working hour is defined by tenant settings.";
    case msgCategory.AUTOMATED:
      return "When the action is triggered this message will be send to the patient.";
    default:
      return "When the action is triggered this message will await for a clinician to access the portal and really send it to the patient.";
  }
}

const ActionSpecMessages = ({ initialValue, onChange, handleMessageCsvDownload, handleMessageCsvUpload }) => {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const debouncedMessages = useDebounce(messages, 500);
  const [messagesOrder, setMessagesOrder] = useState(messages);
  const [showOnlyInput, setShowOnlyInput] = useState(true);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (!isEmpty(initialValue)) {
      // Append msg type to initialValue if it doesn't exist
      //eslint-disable-next-line react-hooks/exhaustive-deps
      initialValue = initialValue.map((message) => {
        if (!message.msgCategory) {
          message.msgCategory = msgCategory.REGULAR;
          message.title = "Regular Message";
          message.help = "This message will be sent to the patient when the action is triggered.";
        }
        /* Rationale: The default message is the message that will be sent to the patient.
         * The other messages should not have a default field.
         */
        if (message.msgCategory !== msgCategory.REGULAR) {
          delete message.default;
        }
        return message;
      });
      setMessages(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    setMessagesOrder(messages);
  }, [messages]);

  useEffect(() => {
    if (debouncedMessages) {
      onChange({ target: { value: messages } });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedMessages]);

  const handleChange = (messageKey) => (value) => {
    let newMessages = [...messages];
    newMessages[messageKey] = value;
    setMessages(newMessages);
  };

  const handlePosition = (name, pos, newPos) => {
    let newMessages = messages.map((q) => orderMapMessages(q, pos, newPos, name));
    setMessages(newMessages);
  };

  const orderMapMessages = (message, pos, newPos, name) => {
    if (
      newPos > pos &&
      message.position <= newPos &&
      message.position > pos &&
      message.name !== name
    ) {
      message.position -= 1;
    } else if (
      newPos < pos &&
      message.position >= newPos &&
      message.position < pos &&
      message.name !== name
    ) {
      message.position += 1;
    }

    return message;
  };

  const handleAddMessage = (msgCategory) => {
    const newMessage = build_message(msgCategory);
    setMessages([...messages, newMessage]);
  };

  const handleDeleteMessage = (deleteKey) => () => {
    const newMessages = messages.filter((_, MessageKey) => MessageKey !== deleteKey);
    setMessages(newMessages);
  };

  const handleOnDragStart = (result) => {
    const MessageSelected = messagesOrder.filter(
      (Message) => Message.internalId === result.draggableId
    );

    setSelectedMessage(MessageSelected[0].position);
    setShowOnlyInput(false);
    setIsDragging(true);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(messagesOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItem);

    const itemsReordered = items.map((Message, ind) => {
      Message.position = ind;
      return Message;
    });

    setMessagesOrder(itemsReordered);
    setMessages(itemsReordered);

    const messageSelected = messagesOrder.filter(
      (message) => message.internalId === result.draggableId
    );

    setSelectedMessage(messageSelected[0].position);
    setIsDragging(false);
  };

  const renderMessage = (message, messageKey) => {
    const isSelected = messageKey === selectedMessage;

    return (
      <Draggable key={message.internalId} draggableId={message.internalId} index={messageKey}>
        {(provided) => (
          <li
            className={classes.li}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            id={message.id}
          >
            <ActionSpecMessage
              key={message.internalId}
              size={messages.length}
              onChange={handleChange(messageKey)}
              onChangePosition={handlePosition}
              onClick={() => setSelectedMessage(messageKey)}
              initialValue={message}
              title={get_title(message.msgCategory)}
              help={get_help(message.msgCategory)}
              isSelected={isSelected}
              showOnlyInput={showOnlyInput}
              isDragging={isDragging}
              onDelete={handleDeleteMessage(messageKey)}
              handleMessageCsvDownload={handleMessageCsvDownload}
              handleMessageCsvUpload={handleMessageCsvUpload}
              msgCategory={message.msgCategory}
            />
          </li>
        )}
      </Draggable>
    );
  };
  return (
    <>
      <DragDropContext
        onBeforeCapture={() => setShowOnlyInput(true)}
        onDragStart={handleOnDragStart}
        onDragEnd={handleOnDragEnd}
      >
        <Droppable droppableId="Messages">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className={classes.ul}>
              {messagesOrder.sort(sortMessage).map(renderMessage)}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      {
        !have_regular_message(messages) ? (
          <div className={classes.addMessageContainer}>
            <Button color="primary" onClick={e => handleAddMessage(msgCategory.REGULAR)}>
              Add Regular Message
            </Button>
          </div>
        ) : null
      }

      {
        !have_automated_message(messages) ? (
        <div className={classes.addMessageContainer}>
          <Button color="primary" onClick={e => handleAddMessage(msgCategory.AUTOMATED)}>
            Add Automated Message
          </Button>
        </div>
        ) : null
      }

      {
        !have_timeout_message(messages) ? (
        <div className={classes.addMessageContainer}>
          <Button color="primary" onClick={e => handleAddMessage(msgCategory.TIMEOUT)}>
            Add Timeout Message
          </Button>
        </div>
        ) : null
      }

      {
        !have_offhour_message(messages) ? (
        <div className={classes.addMessageContainer}>
          <Button color="primary" onClick={e => handleAddMessage(msgCategory.OFFHOUR)}>
            Add Off Hour Message
          </Button>
        </div>
        ) : null
      }

      </>
  );
};

export default ActionSpecMessages;
