import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Select from 'components/commons/select';
import { constant, isEmpty, update } from 'lodash';
import { useDebounce } from 'utils/hooks';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
  Grid,
  Button,
  IconButton,
  Paper,
  Tooltip,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { Delete as DeleteIcon, InfoOutlined as InfoIcon } from '@material-ui/icons';

import useStyles from './TasksListItem.styles';
import { ACTION_SPEC_TASK_TYPE_OPTIONS } from 'data/actionSpecs';


function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const TasksListItem = ({
  fetchSurvey,
  onClick,
  onChange,
  onDelete,
  initialValue,
  isSelected,
  onChangePosition,
}) => {
  const classes = useStyles();
  const [task, setTask] = useState(null);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const [isPushSurvey, setIsPushSurvey] = useState(false);
  const [surveyChecked, setSurveyChecked] = useState([]);
  const [surveyAvailable, setSurveyAvailable] = useState([]);
  const [surveySelected, setSurveySelected] = useState([]);
  const [surveyMap, setSurveyMap] = useState({});
  const [initSurvey, setInitSurvey] = useState(true);
  const debouncedTask = useDebounce(task, 500);
  const surveyAvailableChecked = intersection(surveyChecked, surveyAvailable);
  const surveySelectedChecked = intersection(surveyChecked, surveySelected);
  const [form, setForm] = useState({
    highlightedText: '',
    searchText: ''
  });

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setTask(initialValue);
      setGotInitialValue(true);
    }
  }, [initialValue, gotInitialValue]);

  useEffect(() => {
    if (!isEmpty(debouncedTask)) {
      onChange(debouncedTask);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTask]);

  useEffect(() => {
    async function fetchSurveyFirstTime() {
      setInitSurvey(true);
      setForm({ ...form, highlightedText: form.searchText });
      var res = await fetchSurvey(form.searchText, true);
      if (res && res.surveys) {
        var surveys = {};
        res.surveys.forEach(element => {
          surveys[element.id] = element.name;
        });
        setSurveyMap(surveys);
        setSurveyAvailable(not(Object.keys(surveys), initialValue?.trigger ? initialValue.trigger : []));
      } else {
        console.error('Error fetching surveys');
      }
    }

    if (isEmpty(initSurvey)) {
      fetchSurveyFirstTime();
    }

    if (initialValue?.taskType === 'PUSH_SURVEY') {
      setIsPushSurvey(true);
    }

    if (!isEmpty(initialValue?.trigger)) {
      setSurveySelected(initialValue.trigger);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSurvey]);

  useEffect(() => {
    if (!isEmpty(task)) {
      setTask({ ...update(task, 'trigger', constant(surveySelected)) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveySelected]);

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      let pos = task.position;
      setTask({ ...update(task, name, constant(value)) });
      if (name === 'position') onChangePosition(task.name, pos, value);
      if (name === 'taskType') {
        setIsPushSurvey(value === 'PUSH_SURVEY');
        if (value !== 'PUSH_SURVEY') {
          setSurveyChecked([]);
          setSurveySelected([]);
        }
      }
    };

    async function handleSearchSurveyClick() {
      setForm({ ...form, highlightedText: form.searchText });
      var array = [];
      var res = await fetchSurvey(form.searchText);
      res.surveys.forEach(element => {
        array.push(element.id);
      });
      setSurveyAvailable(not(array, surveySelected));
    }

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        // Enter key is pressed
        event.preventDefault();
        handleSearchSurveyClick();
      }
    };

    const handleCheckedSelected = () => {
      setSurveySelected(surveySelected.concat(surveyAvailableChecked));
      setSurveyAvailable(not(surveyAvailable, surveyAvailableChecked));
      setSurveyChecked(not(surveyChecked, surveyAvailableChecked));
    };
  
    const handleCheckedAvailable = () => {
      setSurveyAvailable(surveyAvailable.concat(surveySelectedChecked));
      setSurveySelected(not(surveySelected, surveySelectedChecked));
      setSurveyChecked(not(surveyChecked, surveySelectedChecked));
    };

    const customList = (title, items) => (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list" sx={{
          width: '100%',
          maxWidth: 344,
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 500,
          '& ul': { padding: 0 },
        }}>
          {items.map((value) => {
            const labelId = `transfer-list-all-item-${value}-label`;

            return (
              <ListItem key={value} role="listitem" onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={surveyChecked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={surveyMap[value]} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );

    const numberOfChecked = (items) => intersection(surveyChecked, items).length;

    const handleToggle = (value) => () => {
      const currentIndex = surveyChecked.indexOf(value);
      const newChecked = [...surveyChecked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setSurveyChecked(newChecked);
    };

    const handleToggleAll = (items) => () => {
      if (numberOfChecked(items) === items.length) {
        setSurveyChecked(not(surveyChecked, items));
      } else {
        setSurveyChecked(union(surveyChecked, items));
      }
    };

  return (
    <>
      {task && (
        <Paper
          className={classNames(classes.container, {
            [classes.selected]: isSelected,
          })}
          onClick={onClick}
          elevation={isSelected ? 4 : 0}
          square
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Select
                label="Type"
                onChange={handleChange('taskType')}
                value={task.taskType}
                options={ACTION_SPEC_TASK_TYPE_OPTIONS}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <FormLabel id="optional-radio-buttons-group-label">Optional</FormLabel>
                <RadioGroup
                  aria-labelledby="optional-radio-buttons-group-label"
                  row
                  name="optional-radio-buttons-group"
                  value={task.optional}
                  onChange={handleChange('optional')}
                >
                  <FormControlLabel value="false" control={<Radio />} label="False" />
                  <FormControlLabel value="true" control={<Radio />} label="True" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <FormLabel id="default-radio-buttons-group-label">Default</FormLabel>
                <RadioGroup
                  aria-labelledby="default-radio-buttons-group-label"
                  row
                  name="default-radio-buttons-group"
                  value={task.default}
                  onChange={handleChange('default')}
                >
                  <FormControlLabel value="false" control={<Radio />} label="False" />
                  <FormControlLabel value="true" control={<Radio />} label="True" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="task-label"
                label="Label"
                type="text"
                className={classes.textField}
                value={task.label}
                onChange={handleChange('label')}
                margin="normal"
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="This is the text that will be shown on the portal when intervening an action"
                        placement="top-start"
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {isPushSurvey && (
            <Paper
              className={classNames(classes.container, {
                [classes.selected]: isSelected,
              })}
              elevation = {1}
              square
            >
              <div style={{marginTop: '....'}}>
                  Select surveys to push
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="title"
                    label="Search for title or body"
                    type="text"
                    margin="dense"
                    fullWidth
                    onChange={({ currentTarget: { value } }) => setForm({ ...form, searchText: value })}
                    onKeyDown={handleKeyPress}
                    value={form.searchText}
                    size="small"
                  />
                  <Button
                    className={classes.applyBtn}
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={() => handleSearchSurveyClick()}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                className={classes.root}
              >
                <Grid item>{customList('Available', surveyAvailable)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedSelected}
                      disabled={surveyAvailableChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={handleCheckedAvailable}
                      disabled={surveySelectedChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>{customList('Selected', surveySelected)}</Grid>
              </Grid>
            </Paper>
          )}

          {isSelected && (
            <div className={classes.actionsContainer}>
              {!!onDelete && (
                <Tooltip title="Delete task">
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </Paper>
      )}
    </>
  );
};

export default TasksListItem;
