import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import { fetchSurveys } from 'actions/surveys';
import { Surveys } from 'views';
import fetchTenantsIfNeeded from '../../../actions/tenants';

const mapStateToProps = state => ({
  surveys: state.surveys,
  tenants: state.tenants,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    ...bindActionCreators(
      {
        push,
        fetchSurveys,
        fetchTenantsIfNeeded
      },
      dispatch
    )
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Surveys);
