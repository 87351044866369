import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { BlocklyConditionsAndTests, ListItemSelector } from 'components/commons';
import {
  ADD_ITEM_MESSAGE,
  ADD_NEW_ITEM_MESSAGE,
  BRIEF_TYPE,
  MODULE_CONDITIONS_MESSAGE,
  MODULE_MESSAGE,
  MODULE_TESTS_MESSAGE,
  PROGRAM_CATEGORIES,
  PROGRAM_MESSAGE,
  PROGRAM_STATE,
  PROGRAM_TYPE,
} from 'data/programs';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { UserCohortException } from 'components/commons/blockly-conditions/utils/index';
import Button from 'components/commons/button';
import Form from 'components/commons/form';
import Params from 'components/commons/params-field';
import TestParams from 'components/commons/tests-field/Params';
import useTestsStyles from 'components/commons/tests-field/TestsField.styles';
import Select from 'components/commons/select';
import TenantsField from 'components/commons/tenants-field';
import { cloneDeep } from 'lodash';
import { hasDuplicates } from 'utils/commons';
import { useDebounce } from 'utils/hooks';
import ClipsTimeSwappable from '../../pledges/tabs/clips-time-swappable';
import AbTestingField from '../ab-testing-field';
import useStyles from './Form.styles';

const ProgramForm = ({ form, contentForm, handlers, editing, setForm, tenants, facts }) => {
  const outdatedCondition = '(test (eq ?non-existent-fact "do not touch me"))';
  const testsClasses = useTestsStyles();
  const classes = useStyles();

  const [params, setParams] = useState([]);
  const debouncedParams = useDebounce(params, 500);

  useEffect(() => {
    if (debouncedParams) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);

  const handleExternalChange = () => {
    const newState = {
      conditions: [...form.tests.conditions],
      params: debouncedParams.map((param) => ({
        expected: Object.fromEntries(param.expected),
        facts: Object.fromEntries(param.facts),
      })),
    };
    handleChange('tests')({ target: { value: newState } });
  };

  const handleAdd =
    ({ listIndex }) =>
    () => {
      const newList = form.modules;
      newList.splice(listIndex + 1, 0, { tests: { conditions: [] } });
      setForm({
        ...form,
        modules: newList,
      });
    };

  const handleRemove =
    ({ listIndex }) =>
    () => {
      const newList = form.modules;
      newList.splice(listIndex, 1);
      setForm({
        ...form,
        modules: newList,
      });
    };

  const handleOnDragEnd = (result) => {
    if (result.destination == null) return;
    const newList = form.modules;
    const [reorderedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, reorderedItem);
    setForm({
      ...form,
      modules: newList,
    });
  };

  const handleProgramConditions =
    (name) =>
    ({ target: { value } }) => {
      if (value != null) {
        setForm({
          ...form,
          [name]: value,
        });
      }
    };

  const handleProgramBlocklyCondition = (code, diff, XML, clear = false) => {
    const newForm = cloneDeep(form);
    if (XML?.childNodes?.length > 0) {
      newForm.visualConditions = new XMLSerializer().serializeToString(XML);
    }

    if (clear) {
      newForm.conditions = [];
      newForm.visualConditions = '<xml></xml>';
    }

    if (code) {
      const newConditions = code;
      var aliasArray = [];
      newConditions.forEach((condition) => {
        if (condition.includes('user_cohort')) {
          aliasArray = aliasArray.concat(condition.match(/\?\w+&/g));
        }
      });

      if (hasDuplicates(aliasArray)) {
        throw new UserCohortException();
      }
      newForm.conditions = newConditions;
    }
    setForm(newForm);
  };

  const handleConditions =
    ({ listIndex }) =>
    (name) =>
    ({ target: { value } }) => {
      if (value != null) {
        const newList = form.modules;
        if (name === 'conditions') {
          newList[listIndex].conditions = value;
        } else if (value?.conditions) {
          newList[listIndex].tests = value;
        }
        setForm({
          ...form,
          modules: newList,
        });
      }
    };

  const handleBlocklyCondition =
    ({ listIndex }) =>
    (code, diff, XML, clear = false) => {
      const newModules = form.modules;

      if (XML) {
        newModules[listIndex].visualConditions = new XMLSerializer().serializeToString(XML);
      }

      if (code) {
        const newConditions = code;
        var aliasArray = [];
        newConditions.forEach((condition) => {
          if (condition.includes('user_cohort')) {
            aliasArray = aliasArray.concat(condition.match(/\?\w+&/g));
          }
        });

        if (hasDuplicates(aliasArray)) {
          throw new UserCohortException();
        }
        newModules[listIndex].conditions = newConditions;
      }

      if (clear) {
        newModules[listIndex].conditions = [];
        newModules[listIndex].visualConditions = '<xml></xml>';
      }

      setForm({
        ...form,
        modules: newModules,
      });
    };

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setForm({
        ...form,
        [name]: value,
      });
    };

  const handleTransitionField =
    ({ listIndex }) =>
    (name) =>
    ({ target: { value } }) => {
      if (value != null) {
        const newList = form.modules;
        newList[listIndex][name] = value;
        setForm({
          ...form,
          modules: newList,
        });
      }
    };

  const handleSubmitForm = () => {
    var isValid = true;
    const modulesWithMsgs = form.modules.map((element) => {
      isValid = !!element.contentIdentifier;
      return { ...element, invalid: !element.contentIdentifier };
    });
    if (!isValid) {
      setForm({
        ...form,
        modules: modulesWithMsgs,
      });
      return;
    }
    handlers.handleSubmit(false);
  };

  const briefFields = () => {
    return form.program_type === 'brief' ? (
      <>
        <Grid item xs={12} sm={3}>
          <Select
            label="Category"
            onChange={(e) => handlers.handleChange('category')(e)}
            value={form.category}
            options={PROGRAM_CATEGORIES}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            label="Brief type"
            onChange={(e) => handlers.handleChange('brief_type')(e)}
            value={form.brief_type}
            options={BRIEF_TYPE}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="title"
            label="Title"
            type="text"
            value={form.title}
            onChange={handleChange('title')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="text"
            label="Text"
            type="text"
            value={form.text}
            onChange={handleChange('text')}
            margin="normal"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Time Started
          </Typography>
          <Typography variant="body2" gutterBottom>
            {'This field can contain an integer value or CLIPS logic to be evaluated.'}
          </Typography>
          <ClipsTimeSwappable
            name="time_started"
            onChange={handleChange('time_started')}
            initialValue={form.time_started || '0'}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.titleSpacing} variant="h6" gutterBottom>
            Time Ended
          </Typography>
          <Typography variant="body2" gutterBottom>
            {'This field can contain an integer value or CLIPS logic to be evaluated.'}
          </Typography>
          <ClipsTimeSwappable
            name="time_ended"
            onChange={handleChange('time_ended')}
            initialValue={form.time_ended || '1440'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              id="state"
              label="State"
              type="text"
              value={form.state}
              onChange={handleChange('state')}
              margin="normal"
              fullWidth
              required
            />
        </Grid>
      </>
    ) : (
      <></>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper>
        {form ? (
          <Form onSubmit={handleSubmitForm} className={classes.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Program Name"
                  type="text"
                  value={form.name}
                  onChange={(e) => handlers.handleChange('name')(e)}
                  margin="normal"
                  disabled={editing}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={PROGRAM_MESSAGE} placement="top-start">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  label="Status"
                  onChange={(e) => handlers.handleChange('status')(e)}
                  value={form.status}
                  options={PROGRAM_STATE}
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="ttl"
                  label="Time To Live (TTL)"
                  type="number"
                  value={form.ttl || ''}
                  onChange={handleChange('ttl')}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="Amount of time in days the programs should be displayed."
                          placement="top-start"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="cooldown"
                  label="Cooldown"
                  type="number"
                  value={form.cooldown || ''}
                  onChange={handleChange('cooldown')}
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="Amount of time in days between showing the programs for the first time, and showing it again."
                          placement="top-start"
                        >
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  label="Program Type"
                  onChange={(e) => handlers.handleChange('program_type')(e)}
                  value={form.program_type}
                  options={PROGRAM_TYPE}
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              {briefFields()}
            </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Parameters
          </Typography>
          <Params handleChange={handleChange('params')} initialValue={form.params} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Tests for params
          </Typography>
          <TestParams
            classes={testsClasses}
            onChange={setParams}
            initialValue={form.tests ? form.tests.params ?? [] : []}
          />
        </Grid>
      </Grid>
            
            <section className={classes.conditionSection}>
              <BlocklyConditionsAndTests
                content={form}
                conditionsTitle={'Program conditions'}
                testsTitle={'Program Tests'}
                handleChange={handleProgramConditions}
                blocklyHandleChange={handleProgramBlocklyCondition}
                facts={facts.facts}
              />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TenantsField
                    handleTenantCheck={handlers.handleTenantCheck}
                    initialValue={form.tenants}
                    tenantList={tenants.tenants}
                    form={form}
                    setForm={setForm}
                    title="Program Tenants"
                  />
                </Grid>
              </Grid>
            </section>
            {editing ? (
              <div className={classes.moduleSection}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <AbTestingField handleChange={handleChange} form={form} />
                  </Grid>
                </Grid>

                <Typography variant="h6" display="inline">
                  {'Modules'}
                </Typography>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="modules">
                    {(provided) => (
                      <ul
                        className={classes.moduleList}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {form.modules.map((item, index) => {
                          return (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                              {(provided) => (
                                <div
                                  className={classes.itemContainer}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={classes.blockContainer}>
                                    <li className={classes.modulesListItem}>
                                      <FormatLineSpacingIcon className={classes.draggableIcon} />
                                      <ListItemSelector
                                        value={item.name}
                                        options={contentForm.options}
                                        onValueSelect={(selected) =>
                                          handlers.handleSelect({
                                            listIndex: index,
                                            item: selected,
                                          })
                                        }
                                        onSearchChange={(e) =>
                                          handlers.handleContent('query', e.target.value)
                                        }
                                        onRemove={() => handleRemove({ listIndex: index })()}
                                        onEdit={() => handlers.handleEdit('modules', item.id)}
                                        multiple={form.modules.length > 1}
                                        canEdit={!item.contentIdentifier}
                                        errorMessage={
                                          item.invalid ? 'Please add a valid module.' : ''
                                        }
                                        tooltip={MODULE_MESSAGE}
                                      />
                                    </li>
                                  </div>
                                  <Accordion>
                                    <AccordionSummary disabled={index >= form.modules.length - 1}>
                                      <Typography variant="h6" gutterBottom>
                                        Transition Conditions
                                      </Typography>
                                      <ExpandMoreIcon className={classes.expandMore} />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {index < form.modules.length - 1 ? (
                                        <>
                                          <div className={classes.conditionsContainer}>
                                            <div className={classes.additionalConditionsContainer}>
                                              <TextField
                                                className={classes.delayField}
                                                id="delay"
                                                label="Delay"
                                                type="number"
                                                value={
                                                  !isNaN(form.modules[index].delay) &&
                                                  form.modules[index].delay >= 0
                                                    ? form.modules[index].delay
                                                    : '1'
                                                }
                                                onChange={handleTransitionField({
                                                  listIndex: index,
                                                })('delay')}
                                                margin="normal"
                                                InputProps={{
                                                  endAdornment: (
                                                    <InputAdornment position="end">
                                                      <Tooltip
                                                        title="Minimun delay time in seconds to be triggered"
                                                        placement="top-start"
                                                      >
                                                        <IconButton>
                                                          <InfoIcon />
                                                        </IconButton>
                                                      </Tooltip>
                                                    </InputAdornment>
                                                  ),
                                                }}
                                                fullWidth
                                                required
                                              />
                                            </div>
                                            <BlocklyConditionsAndTests
                                              content={form.modules[index]}
                                              handleChange={handleConditions({ listIndex: index })}
                                              conditionsTitle="Module Transition Conditions"
                                              conditionsTipMessage={MODULE_CONDITIONS_MESSAGE}
                                              testsTitle="Module Transition Tests"
                                              testsTipMessage={MODULE_TESTS_MESSAGE}
                                              blocklyHandleChange={handleBlocklyCondition({
                                                listIndex: index,
                                              })}
                                              facts={facts.facts}
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                  <div className={classes.addBtn}>
                                    <IconButton
                                      onClick={handleAdd({ listIndex: index })}
                                      className={classes.addIcon}
                                    >
                                      <Tooltip title={ADD_ITEM_MESSAGE} placement="bottom-start">
                                        <AddCircleOutlineIcon />
                                      </Tooltip>
                                    </IconButton>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
                <Tooltip title={ADD_NEW_ITEM_MESSAGE}>
                  <div className={classes.newModuleButton}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="small"
                      onClick={handlers.handleNewModule}
                    >
                      Create Module
                    </Button>
                  </div>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
            <section className={classes.actionsContainer}>
              <Button
                color="secondary"
                variant="contained"
                size="medium"
                onClick={() => {
                  handlers.handleSubmit(true);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                size="medium"
                disabled={
                  (form?.tests?.conditions?.length < 2 || form?.conditions?.length <= 0 || form?.conditions?.includes(outdatedCondition)) && true
                }
              >
                Save
              </Button>
            </section>
          </Form>
        ) : (
          ''
        )}
      </Paper>
    </Container>
  );
};

export default ProgramForm;
