import React, { useState, useEffect } from 'react';
import { update, constant } from 'lodash';
import Typography from '@material-ui/core/Typography';

import ObservationForm from 'components/views/observations/form';
import useStyles from './CreateObservation.styles';

const CreateObservation = ({ actions, facts, labels, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    cooldown: '60',
    name: '',
    type: '2',
    priority: '10',
    category: '0',
    status: '0',
    body: '',
    ttl: '',
    labels: [],
    facts: [],
    params: {},
    conditions: [],
    tests: {
      conditions: [],
      params: []
    },
  });
  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenants.tenants?.length) {
      setForm({ ...form, tenants: [localStorage.getItem('tenantId')] });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  const updatedLabels = () => {
    const newLabels = form.labels.map(index => {
      const { name, flags, id, type } = labels.labels[index];
      return { name, flags, id, type };
    });
    return newLabels;
  };

  const handleChange = name => e => setForm({ ...update(form, name, constant(e.target.value)) });

  const handleSubmit = () => actions.createObservation({ ...form, labels: updatedLabels() });

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Observation</Typography>
      <ObservationForm
        facts={facts}
        tenants={tenants.tenants}
        form={form}
        setForm={setForm}
        handleChange={handleChange}
        labels={labels}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CreateObservation;
