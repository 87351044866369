import { authorizedAxios } from 'config/axios';
import ProgramFactory from 'factories/programs';

const FACTUARY_URL = '/factuary/api/v2';

export const fetchProgram = (id) => {
  return authorizedAxios.get(`${FACTUARY_URL}/programs/${id}`).then((response) => {
    return {
      ...response,
      program: ProgramFactory.toModel(response.program),
    };
  });
};

export const fetchPrograms = (filters, page) => {
  const params = new URLSearchParams();

  const copyFilters = { ...filters };

  if (copyFilters['searched text']?.length) {
    params.append('name', copyFilters['searched text'].join('|'));
    delete copyFilters['searched text'];
  }

  if (copyFilters) {
    Object.entries(copyFilters).forEach(([type, values]) => {
      if (values.length) params.append(type, values.join(','));
    });
  }

  if (page) params.append('page', page);
  params.append('per_page', 10);

  return authorizedAxios
    .get(`${FACTUARY_URL}/programs/filter?${params}`)
    .then((response) => {
      return {
        meta: {
          nextPage: response.meta.next_page,
          currentPage: response.meta.page,
        },
        data: {
          programs: response.programs.map(ProgramFactory.toModel),
        },
      };
    })
    .catch((err) => console.error(err));
};

export const updateProgram = (program) => {
  return authorizedAxios.post(`${FACTUARY_URL}/programs`, ProgramFactory.toDTO(program));
};

export const createProgram = (program) => {
  return authorizedAxios
    .post(`${FACTUARY_URL}/programs`, ProgramFactory.toNewProgramDTO(program))
    .then((response) => {
      return response;
    });
};

export const isProgramOngoing = (id) => {
  return authorizedAxios
    .get(`${FACTUARY_URL}/programs/${id}/ongoing`)
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

export const programOutdated = (id) => {
  return authorizedAxios
    .post(`${FACTUARY_URL}/programs/${id}/outdated`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

export const nextVersionedProgramName = (name) => {
  const regex = /#v(\d+)$/;
  const match = name.match(regex);
  if (!match) {
    return `${name}#v1`;
  } else {
    const version = parseInt(match[1]);
    return name.replace(regex, `#v${version + 1}`);
  }
}
