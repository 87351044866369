import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';

import KeypadForm from './KeypadForm';
import LikertScaleForm from './LikertScaleForm';
import MoreIcon from '@material-ui/icons/MoreVert';
import PickerForm from './PickerForm';
import { SURVEY_OPTION_INPUT_TYPES } from 'data/surveys';
import Select from 'components/commons/select';
import TextForm from './TextForm';
import TimeForm from './TimeForm';
import WeightTemperatureForm from './WeightTemperatureForm';
import useStyles from './SingleAnswerOptions.styles';

const inputTypeComponents = {
  keypad: { component: KeypadForm, unnecessaryFields: ['text', 'inputIs24Hr', 'inputIncrement'] },
  time: {
    component: TimeForm,
    unnecessaryFields: ['text', 'inputMin', 'inputMax', 'inputIncrement'],
  },
  picker: { component: PickerForm, unnecessaryFields: ['text', 'inputIs24Hr'] },
  text: {
    component: TextForm,
    unnecessaryFields: ['inputMin', 'inputMax', 'inputIncrement', 'inputIs24Hr'],
  },
  date: {
    component: () => null,
    unnecessaryFields: ['text', 'inputMin', 'inputMax', 'inputIncrement', 'inputIs24Hr'],
  },
  likert_scale: {
    component: LikertScaleForm,
    unnecessaryFields: ['text', 'inputMin', 'inputMax', 'inputIncrement', 'inputIs24Hr'],
  },
  weight: {
    component: WeightTemperatureForm,
    unnecessaryFields: ['inputIncrement', 'text', 'inputIs24Hr'],
  },
  temperature: {
    component: WeightTemperatureForm,
    unnecessaryFields: ['inputIncrement', 'text', 'inputIs24Hr'],
  },
};

const SingleAnswerOptions = ({ onChange, hidden, options }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOptionKey, setDialogOptionKey] = useState(0);

  const handleOpenDialog = (optionKey) => () => {
    setDialogOptionKey(optionKey);
    setOpenDialog(true);
  };

  const handleInputTypeChange =
    (optionKey) =>
    ({ target: { value } }) => {
      const optionLocal = options[optionKey];
      const selectedType = inputTypeComponents[value];
      selectedType.unnecessaryFields.forEach((field) => (optionLocal[field] = ''));
      optionLocal['inputType'] = value;
      onChange(`options[${optionKey}]`)({ target: { value: optionLocal } });
    };

  return (
    <>
      {options
        ? options.map((option, optionKey) => {
            const InputTypeForm = inputTypeComponents[option.inputType] || '';
            const isTemperature = option.inputType === 'temperature';
            const InputTypeFormComponent = InputTypeForm ? (
              <InputTypeForm.component
                onChange={onChange}
                option={{ optionKey, ...option }}
                isTemperature={isTemperature}
              />
            ) : null;
            return (
              <div key={optionKey}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Select
                      id="type"
                      label="Input type"
                      onChange={handleInputTypeChange(optionKey)}
                      value={option.inputType}
                      options={SURVEY_OPTION_INPUT_TYPES}
                      margin="normal"
                      required
                      fullWidth
                    />
                  </Grid>
                  {!hidden && (
                    <Grid item xs={1} className={classes.moreContainer}>
                      <IconButton onClick={handleOpenDialog(optionKey)}>
                        <MoreIcon size="small" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                {InputTypeFormComponent}
              </div>
            );
          })
        : null}
      <OptionsDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onChange={onChange}
        optionKey={dialogOptionKey}
        option={options[dialogOptionKey]}
      />
    </>
  );
};

const OptionsDialog = ({ open, onClose, onChange, optionKey, option }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Extra fields</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Option position (index)"
          type="text"
          value={option?.position}
          onChange={onChange(`options[${optionKey}].position`)}
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Next question"
          type="text"
          value={option?.nextQuestion}
          onChange={onChange(`options[${optionKey}].nextQuestion`)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SingleAnswerOptions;
