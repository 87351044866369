import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';

import {
  fetchSurvey,
  fetchActionSpec,
  resetActionSpec,
  updateActionSpec,
  getLanguageFile,
  saveLanguageFile,
  getLanguageFileTemplate,
  getMessageCsv,
  saveMessageCsv,
} from 'actions/actionSpec';
import { fetchTenantsIfNeeded } from '../../../actions/tenants';
import { fetchFactsIfNeeded } from 'actions/facts';
import { EditActionSpec } from 'views';

const mapStateToProps = (state, ownProps) => ({
  actionSpec: state.actionSpec,
  actionSpecId: ownProps.match.params.id,
  tenants: state.tenants,
  facts: state.facts,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        fetchSurvey,
        fetchActionSpec,
        updateActionSpec,
        getLanguageFile,
        saveLanguageFile,
        getMessageCsv,
        saveMessageCsv,
        getLanguageFileTemplate,
        push,
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchTenantsIfNeeded());
      dispatch(fetchFactsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetActionSpec());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditActionSpec);
