import TestFactory from './tests';
import { cleanConditions } from "./utils";

export default class InsightFactory {
  static toDTO(model) {
    return {
      id: model.id,
      category: parseInt(model.category),
      priority: parseInt(model.priority),
      type: parseInt(model.type),
      cooldown: parseInt(model.cooldown),
      status: parseInt(model.status),
      name: model.name,
      title: model.title,
      body: model.body,
      link_source: model.linkSource,
      body_html: model.bodyHtml,
      references_html: model.referencesHtml,
      client_type: model.clientType,
      is_smart_coach: model.isSmartCoach,
      do_not_translate: model.doNotTranslate,
      client_conditions: parseInt(model.clientConditions) ?? undefined,
      theme_agnostic: model.themeAgnostic,
      labels: model.labels,
      facts: model.facts,
      params: model.params,
      ab_test_name: model.abTestName,
      ab_test_side: model.abTestSide,
      group_identifier: model.groupIdentifier,
      group_name: model.groupName,
      group_name_cooldown: model.groupNameCooldown,
      segment_anchor: model.segmentAnchor,
      watch_head: model.watchHead,
      watch_body: model.watchBody,
      has_notification: model.hasNotification,
      head_alt: model.headAlt,
      body_alt: model.bodyAlt,
      app_id: parseInt(model.appId) ?? undefined,
      app_xid: model.appXid?.toString() ?? undefined,
      tests: TestFactory.testToDTO(model.tests),
      visual_conditions: model.visualConditions,
      conditions: cleanConditions(model.conditions),
      ttl: parseInt(model.ttl),
      tenants: model.tenants,
      available_langs: model.availableLangs,
      enable_langs: model.enableLangs,
      lang: model.lang,
      tenant_id: model.tenantID,
      duration_estimate: parseInt(model.durationEstimate)
    };
  }

  static toModel(dto) {
    return {
      id: dto.id,
      category: dto.category?.toString() ?? '',
      priority: dto.priority?.toString() ?? '',
      type: dto.type?.toString() ?? '',
      cooldown: dto.cooldown?.toString() ?? '',
      name: dto.name,
      title: dto.title,
      body: dto.body,
      linkSource: dto.link_source,
      bodyHtml: dto.body_html,
      referencesHtml: dto.references_html,
      clientType: dto.client_type ?? '',
      isSmartCoach: Boolean(dto.is_smart_coach),
      doNotTranslate: Boolean(dto.do_not_translate),
      clientConditions: dto.client_conditions?.toString() ?? false,
      themeAgnostic: Boolean(dto.theme_agnostic),
      labels: dto.labels?.map(label => label.id),
      facts: dto.facts ?? [],
      params: dto.params ?? {},
      abTestName: dto.ab_test_name ?? '',
      abTestSide: dto.ab_test_side ?? '',
      groupIdentifier: dto.group_identifier ?? '',
      groupName: dto.group_name ?? '',
      groupNameCooldown: dto.group_name_cooldown ?? '',
      segmentAnchor: Boolean(dto.segment_anchor),
      watchHead: dto.watch_head ?? '',
      watchBody: dto.watch_body ?? '',
      hasNotification: Boolean(dto.has_notification),
      headAlt: dto.head_alt ?? '',
      bodyAlt: dto.body_alt ?? '',
      appId: dto.app_id?.toString() ?? '',
      appXid: dto.app_xid?.toString() ?? '',
      tests: dto.tests ? TestFactory.testToModel(dto.tests) : { conditions: [] },
      status: dto.status?.toString() ?? '',
      conditions: dto.conditions,
      visualConditions: dto.visual_conditions,
      ttl: dto.ttl?.toString() ?? '',
      tenants: dto.tenants,
      availableLangs: dto.available_langs,
      enableLangs: dto.enable_langs,
      lang: dto.lang ?? '',
      tenantID: dto.tenant_id,
      durationEstimate: dto.duration_estimate?.toString() ?? ''
    };
  }
}
