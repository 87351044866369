import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paddingX: {
    padding: '0 14px',
  },
}));
