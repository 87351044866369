import React from 'react';
import { Typography, Box } from '@mui/material';
import useStyles from './Footer.styles';
import cx from 'classnames';
import { HeartIcon } from 'assets/icons';

const Footer = ({ sx, isWhiteBackground }) => {
  const classes = useStyles();

  return (
    <Box
      className={cx(classes.root, { [classes.whiteBackground]: isWhiteBackground })}
      sx={sx}
      id="footer"
    >
      <Typography variant="para12" sx={{ fontSize: 12 }}>
        Made with
      </Typography>
      <HeartIcon className={classes.heartIcon} />
      <Typography variant="para12" sx={{ fontSize: 12 }}>
        by the all.health team
      </Typography>
    </Box>
  );
};

export default Footer;
