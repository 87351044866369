export const TYPE_REQUEST_ACTION_SPECS = 'action-specs/REQUEST_ACTION_SPECS';
export const TYPE_RECEIVE_ACTION_SPECS = 'action-specs/RECEIVE_ACTION_SPECS';
export const TYPE_RECEIVE_ACTION_SPECS_CACHED = 'action-specs/RECEIVE_ACTION_SPECS_CACHED';
export const TYPE_RECEIVE_ACTION_SPECS_ERROR = 'action-specs/RECEIVE_ACTION_SPECS_ERROR';

export const TYPE_REQUEST_ACTION_SPEC = 'action-spec/REQUEST_ACTION_SPEC';
export const TYPE_RECEIVE_ACTION_SPEC = 'action-spec/RECEIVE_ACTION_SPEC';
export const TYPE_RECEIVE_ACTION_SPEC_ERROR = 'action-spec/RECEIVE_ACTION_SPEC_ERROR';
export const TYPE_UPDATE_ACTION_SPEC = 'action-spec/UPDATE_ACTION_SPEC';
export const TYPE_UPDATE_ACTION_SPEC_SUCCESS = 'action-spec/UPDATE_ACTION_SPEC_SUCCESS';
export const TYPE_UPDATE_ACTION_SPEC_ERROR = 'action-spec/UPDATE_ACTION_SPEC_ERROR';
export const TYPE_CREATE_ACTION_SPEC = 'action-spec/CREATE_ACTION_SPEC';
export const TYPE_CREATE_ACTION_SPEC_SUCCESS = 'action-spec/CREATE_ACTION_SPEC_SUCCESS';
export const TYPE_CREATE_ACTION_SPEC_ERROR = 'action-spec/CREATE_ACTION_SPEC_ERROR';
export const TYPE_RESET_ACTION_SPEC = 'action-spec/RESET_ACTION_SPEC';
export const TYPE_REQUEST_LANGUAGE_FILE = 'action-spec/REQUEST_LANGUAGE_FILE';
export const TYPE_POST_ACTION_SPEC_LANGUAGE_FILE = 'action-spec/POST_ACTION_SPEC_LANGUAGE_FILE';
export const TYPE_POST_ACTION_SPEC_LANGUAGE_FILE_SUCCESS =
  'action-spec/POST_ACTION_SPEC_LANGUAGE_FILE_SUCCESS';
export const TYPE_POST_ACTION_SPEC_LANGUAGE_FILE_ERROR =
  'action-spec/POST_ACTION_SPEC_LANGUAGE_FILE_ERROR';

export const ACTION_SPEC_ACTION_CATEGORY_OPTIONS = [
  { value: 'HF_BIOMETRICS', text: 'High Frequency Biometrics' },
  { value: 'PATIENT_FOLLOWUP', text: 'Patient Follow-Up' },
  { value: 'PANEL_PATIENT_FOLLOWUP', text: 'Panel Patient Follow-Up' },
  { value: 'NEW_PATIENT', text: 'New Patient' },
];

// This is used to map the 'category' field returned
// from the get all action-specs method since the
// response is shared among content
export const ACTION_SPEC_ACTION_CATEGORY_NAMES = {
  4: 'High Frequency Biometrics',
  2: 'Patient Follow-Up',
  5: 'Panel Patient Follow-Up',
  1: 'New Patient',
};

export const ACTION_SPEC_PRIORITY_OPTIONS = [
  { value: 'LOW', text: 'LOW' },
  { value: 'MEDIUM', text: 'MEDIUM' },
  { value: 'HIGH', text: 'HIGH' },
  { value: 'CRITICAL', text: 'CRITICAL' },
];

// This is used to map the 'priority' field returned
// from the get all action-specs method since the
// response is shared among content
export const ACTION_SPEC_PRIORITY_NAMES = {
  4: 'LOW',
  3: 'MEDIUM',
  2: 'HIGH',
  1: 'CRITICAL',
};

export const ACTION_SPEC_TRIGGER_WAY_OPTIONS = [
  { value: 'external_trigger', text: 'External Trigger' },
  { value: 'rules_based', text: 'Rules Based' },
];

export const ACTION_SPEC_STATUS_OPTIONS = [
  { value: 'draft', text: 'Draft' },
  { value: 'live', text: 'Live' },
  { value: 'deprecated', text: 'Deprecated' },
];

export const ACTION_SPEC_NEW_EVIDENCE = {
  position: 1,
  metric: '',
  order: '',
  timeframeAverage: '',
  timeframeBaseline: '',
};

export const ACTION_SPEC_METRIC_OPTIONS = [
  { value: 'BG', text: 'Blood Glucose' },
  { value: 'MEALS', text: 'Meals' },
  { value: 'SLEEP', text: 'Sleep' },
  { value: 'BP', text: 'Blood Pressure' },
  { value: 'TEMP', text: 'Temperature' },
  { value: 'HR', text: 'Heart Rate' },
  { value: 'RR', text: 'Respiration Rate' },
  { value: 'SpO2', text: 'SpO2' },
  { value: 'STEPS', text: 'Movement' },
  { value: 'HRV', text: 'HRV' },
  { value: 'GSR', text: 'GSR' },
  { value: 'WEIGHT', text: 'Weight' },
  { value: 'RHR', text: 'Resting Heart Rate' },
  { value: 'ACTIVITY', text: 'Activity' },
  { value: 'STRESS', text: 'Stress' },
  { value: 'BAND_STATUS', text: 'Band Status' },
];

export const ACTION_SPEC_ORDER_OPTIONS = [
  { value: 'PRIMARY', text: 'Primary' },
  { value: 'SECONDARY', text: 'Secondary' },
  { value: 'TERTIARY', text: 'Tertiary' },
  { value: 'ASSOCIATED', text: 'Associated' },
];

export const ACTION_SPEC_NEW_TASK = {
  position: 1,
  taskType: '',
  complete: 'false',
  optional: 'false',
  default: 'false',
  label: '',
};

export const ACTION_SPEC_TASK_TYPE_OPTIONS = [
  { value: 'AUDIO_CALL_PATIENT', text: 'Audio Call Patient' },
  { value: 'VIDEO_CALL_PATIENT', text: 'Video Call Patient' },
  { value: 'FOLLOW_UP', text: 'Follow Up' },
  { value: 'REMINDER', text: 'Reminder' },
  { value: 'EMAIL_PATIENT', text: 'Email Patient' },
  { value: 'PUSH_SURVEY', text: 'Push Survey' },
];

export const ACTION_SPEC_NEW_MESSAGE = {
  position: 1,
  message: '',
  complete: 'false',
  default: 'false',
  dynamicMessage: '',
};
