import React, { useState, useEffect } from 'react';
import { update, constant, isEmpty } from 'lodash';
import { Typography, CircularProgress } from '@material-ui/core';

import ProgramForm from 'components/views/programs/form';
import TestResults from 'components/commons/test-results';
import PopUpOkCancel from 'components/commons/pop-up-ok-cancel';
import useStyles from './EditProgram.styles';
import { tenantHandler } from 'services/shared/handlers';
import { nextVersionedProgramName } from 'services/programs';
import { compareForm } from 'utils/commons';


const EditProgram = ({ actions, facts, program, programId, modules, tenants }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});
  const [initialForm, setInitialForm] = useState({});
  const [contentForm, setContentForm] = useState({
    query: '',
    options: [],
  });
  const [versioningProgramPopup, setVersioningProgramPopup] = useState(false);

  const keysToCompare = {
    edges: {
      delay: true,
      dst: true,
      src: true,
      conditions: true
    },
    modules: {
      conditions: true,
      contentIdentifier: true,
      delay: true,
      id: true,
      name: true,
      steps: true,
    },
  }

  useEffect(() => {
    actions.onMount();
    actions.fetchProgram(programId);
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(program.program)) {
      const newProgram = program.program;
      if (modules) {
        const options = modules.map((piece) => {
          return { label: piece.name, value: piece.contentIdentifier };
        });
        handleContent('options', options);
      }
      if (newProgram.modules) {
        const positions = newProgram.edges.map((e, index) => {
          return { module: e.dst.module, index };
        });
        const orderedModules = positions.map(p => {
          return newProgram.modules.find(m => m.contentIdentifier === p.module);
        });
        const programModules = orderedModules.length === newProgram.modules.length ? orderedModules : newProgram.modules;
        const newModules = programModules.map((item, index) => {
          const edge = newProgram.edges[index + 1] != null ? newProgram.edges[index + 1] : {};
          return ({
            ...item,
            delay: edge.delay,
            tests: edge.tests,
            conditions: edge.conditions,
            visualConditions: edge.visualConditions
          });
        });
        setForm({
          ...newProgram,
          modules: newModules,
        });
        if (isEmpty(initialForm)) {
          setInitialForm({ ...newProgram, modules: [...newModules] });
        }
      } else {
        setForm({
          ...newProgram,
          modules: [{}],
        });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [program.program, modules]);

  const handleChange =
    (name) =>
      ({ target: { value } }) => {
        setForm({
          ...update(form, name, constant(value)),
        });
      };

  const handleVersioningProgramPopupCancel = () => {
    setVersioningProgramPopup(false);
  }

  const handleVersioningProgramPopupOK = () => {
    setVersioningProgramPopup(false);

    actions.markProgramAsOutdated(form.id);

    form.name = nextVersionedProgramName(form.name)
    actions.createProgramNoDispatch(form)
      .then((response) => {
        form.id = response.id
        form.contentIdentifier = response.content_identifier
        form.name = response.name
        postSubmit();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const postSubmit = () => {
    const newEdges = form.modules.map((item, index) => {
      if (index + 1 >= form.modules.length) {
        return {};
      }
      const nextItem = form.modules[index + 1];
      if (nextItem.contentIdentifier === undefined) {
        return {};
      }
      return {
        delay: item.delay,
        src: { module: item.contentIdentifier, step: item.steps[item.steps.length - 1] },
        dst: { module: nextItem.contentIdentifier, step: nextItem.steps[0] },
        conditions: item.conditions,
        tests: item.tests,
        visualConditions: item.visualConditions
      };
    });
    newEdges.pop();
    if (form.modules.length >= 1) {
      newEdges.unshift({
        dst: { step: form.modules[0].steps[0], module: form.modules[0].contentIdentifier },
        conditions: [],
      });
    }
    setInitialForm({ ...form, edges: [...newEdges] });
    actions.updateProgram({ ...form, edges: newEdges }, false);
  };

  const handleSubmit = (cancel = false) => {
    if (cancel) {
      actions.updateProgram({ ...form }, true);
      return;
    }

    // Check if there are any 'important' changes in the form
    const diff = compareForm(initialForm, form, keysToCompare);
    if (Object.keys(diff).length === 0) {
      postSubmit();
      return;
    }

    actions.getIsProgramOngoing(form.id)
      .then((ongoing) => {
        if (ongoing === true) {
          setVersioningProgramPopup(true);
        } else {
          postSubmit();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleNewModule = () => {
    actions.push(`/programs/${programId}/modules/create`);
  };

  const isFetching = program.isFetching;

  const handleSelect = ({ listIndex, item }) => {
    if (item) {
      const newList = form.modules;
      const newItem = modules.find((module) => module.name === item);
      newList[listIndex] = { ...newItem, tests: { conditions: [] } };
      setForm({
        ...form,
        modules: newList,
      });
    }
  };

  const handleContent = (name, value) => {
    setContentForm({
      ...contentForm,
      [name]: value,
    });
  };

  const handleTenantCheck =
    (tenantIndex) =>
      ({ target }) => {
        tenantHandler(tenantIndex, target, form, tenants, setForm);
      };

  const handleEdit = (type, id) => {
    if (id) {
      actions.push(`/${type}/${id}/edit`);
    }
  };

  const handlers = {
    handleChange,
    handleSubmit,
    handleNewModule,
    handleContent,
    handleSelect,
    handleEdit,
    handleTenantCheck,
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography variant="h5">Edit Program</Typography>
        <div className={classes.testContainer}>
          <TestResults testPassed={program.testResult} />
        </div>
      </div>
      {versioningProgramPopup ? (
        <PopUpOkCancel
          isOpen={true}
          handleOk={handleVersioningProgramPopupOK}
          handleCancel={handleVersioningProgramPopupCancel}
          title="Program versioning"
          body="This program is ongoing, i.e., patients are assigned to and making progress. Click OK if you want to create a new version for this program or CANCEL to go back to edit page."
        />
      ) : (
        ''
      )}
      {isEmpty(form) || isFetching ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <ProgramForm
          {...{
            form: {
              ...form,
              modules: form.modules?.length ? form.modules : [{}]
            },
            handlers,
            contentForm,
            editing: true,
            setForm,
            setContentForm,
            tenants,
            facts
          }}
        />
      )}
    </div>
  );
};

export default EditProgram;
