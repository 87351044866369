import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleText: {
    wordBreak: 'break-word',
  },
  propertyContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25em',
  },
  propertyText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  boldText: {
    fontWeight: 500,
  },
}));
