import React from 'react';
import { Box } from '@mui/system';
import { Typography, Button } from '@mui/material';
import useStyles from './ErrorPage.styles';
import { useMsal } from '@azure/msal-react';
import { clearSession } from 'msal-config';

const ErrorPage = ({ isSignError, title, description }) => {
  const { instance } = useMsal();
  const classes = useStyles();
  const defaultTitle =
    "We're sorry, but the application failed to start due to a configuration error";
  const defaultDescription = ' Please contact your system administrator to resolve this issue';

  const logout = () => {
    clearSession();
    instance.logoutRedirect({
      postLogoutRedirectUri: `${window.location.origin}/`,
    });
  };
  return (
    <div className={classes.root}>
      <Box className={classes.errorContainer}>
        <Typography variant="header24" sx={{ fontSize: 24, fontWeight: 700 }}>
          {title ?? defaultTitle}
        </Typography>
        <Typography variant="header15" sx={{ fontSize: 15, fontWeight: 500 }}>
          {description ?? defaultDescription}
        </Typography>
        {isSignError ? (
          <Button sx={{ width: '100%' }} className={classes.button} onClick={logout}>
            Sign in
          </Button>
        ) : null}
      </Box>
    </div>
  );
};

export default ErrorPage;
