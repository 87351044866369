import { API_URL, HOST_URL } from 'constants/hosts';

const envConstants = {
  development: {
    HOST_URL: HOST_URL,
    API_URL: API_URL,
    BASENAME: '',
    ENV: process.env.REACT_APP_ENV,
  },
  production: {
    HOST_URL: HOST_URL,
    API_URL: API_URL,
    BASENAME: '',
    ENV: process.env.REACT_APP_ENV,
  },
};

const env = envConstants[process.env.REACT_APP_ENV] || envConstants.development;

export default env;
