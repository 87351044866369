import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import Sidebar from 'components/templates/sidebar';
import Program from 'components/views/programs/program';
import { Columns } from 'views';

import useStyles from './Programs.styles';

const menuOptions = [
  {
    text: 'Status',
    value: 'status',
    children: [
      {
        text: 'Draft',
        value: 'draft',
      },
      {
        text: 'Live',
        value: 'live',
      },
      {
        text: 'Deactivated',
        value: 'deactivated',
      },
    ],
  },
  {
    text: 'Outdated',
    value: 'outdated',
    children: [
      {
        text: 'Yes',
        value: 'yes'
      },
      {
        text: 'No',
        value: 'no'
      },
    ]
  },
];

const Programs = ({ actions, programs }) => {
  const classes = useStyles();
  const [form, setForm] = useState({});

  useEffect(() => {
    if (!isEmpty(programs.programs)) setForm(programs.programs);
  }, [programs.programs]);

  const handleChange = (id) => (e) => {
    const newForm = form.map((row) => {
      if (row.id === id) {
        const updatedRow = {
          ...row,
          status: e.target.value,
        };
        return updatedRow;
      }
      return row;
    });
    setForm(newForm);
  };

  const removeHandler = (id, name) => {
    const newForm = form.map((row) => {
      if (row.id === id) {
        let newModules = row.modules || [];
        newModules = newModules.filter((item) => item.name !== name);
        const updatedRow = {
          ...row,
          modules: newModules,
        };
        return updatedRow;
      }
      return row;
    });
    setForm(newForm);
  };

  const editHandler = (type, id) => {
    actions.push(`/${type}/${id}/edit`);
  };

  const handleUpdate = (form) => {
    actions.updateProgram(form);
  };

  const cardBuilder = (formProgram, index, searchedText) => {
    return (
      <Program
        key={index}
        {...{
          actions,
          form: formProgram,
          handleChange,
          handleUpdate,
          removeHandler,
          editHandler,
          index,
          searchedText,
        }}
      />
    );
  };
  
  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Programs"
        mainButtonAction={() => actions.push('/programs/create')}
        mainButtonText="Create program"
      />
      <div className={classes.programsContainer}>
        <Columns
          name="programs"
          service={actions.fetchPrograms}
          menuOptions={menuOptions}
          cardBuilder={cardBuilder}
        />
      </div>
    </div>
  );
};

export default Programs;
