import React, { useState, useEffect } from 'react';
import {
  Loader1Icon,
  Loader2Icon,
  Loader3Icon,
  Loader4Icon,
  Loader5Icon,
  Loader6Icon,
  Loader7Icon,
} from 'assets/icons';

const LoaderAuth = () => {
  const icons = [
    Loader1Icon,
    Loader2Icon,
    Loader3Icon,
    Loader4Icon,
    Loader5Icon,
    Loader6Icon,
    Loader7Icon,
  ];
  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % icons.length);
    }, 125);

    return () => clearInterval(interval);
  }, [icons.length]);

  const CurrentIcon = icons[currentIconIndex];

  return (
    <div style={{ width: '36px', height: '18px' }}>
      <CurrentIcon width="100%" height="100%" />
    </div>
  );
};

export default LoaderAuth;
