import { TENANT_INCLUDE } from 'data/tenants';

export const tenantHandler = (tenantIndex, target, form, tenants, setForm) => {
  let tenantList = form.tenants || [];
  const selectedId = tenants.tenants[tenantIndex].id;
  if (target.value === TENANT_INCLUDE) {
    if (!tenantList.includes(selectedId)) {
      tenantList.push(selectedId);
    }
  } else {
    tenantList = tenantList.filter(tenant => tenant !== selectedId);
  }
  setForm({
    ...form,
    tenants: tenantList,
  });
};