import React from 'react';
import { Stack, Box, Typography, Button } from '@mui/material';

import useStyles from './Auth.styles';

import HeaderAuth from 'components/commons/header-auth';
import { Footer, LoaderAuth } from 'components/commons';
import { AzureIcon, LogoAllIcon } from 'assets/icons';
import { useTheme } from '@material-ui/core';
import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';

const AuthPage = ({ isConnecting, onSignIn }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Stack className={classes.root}>
      <HeaderAuth />
      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <LogoAllIcon className={classes.logo} />
          <Box className={classes.buttonContainer}>
            <UnauthenticatedTemplate>
              {!isConnecting ? (
                <>
                  <Typography
                    variant="header19"
                    sx={{ fontSize: 19, fontWeight: 700, color: theme.palette.custom.black }}
                  >
                    sign in to your account
                  </Typography>
                  <Button onClick={onSignIn} className={classes.button} fullWidth>
                    Sign In
                  </Button>{' '}
                </>
              ) : (
                <>
                  <Box sx={{ height: '24px' }}></Box>
                  <Box className={classes.connectingContainer}>
                    <Typography variant="header12" sx={{ fontSize: 12, fontWeight: 500 }}>
                      Connecting...
                    </Typography>
                    <LoaderAuth />
                  </Box>
                </>
              )}
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Box sx={{ height: '24px' }}></Box>
              <Box className={classes.connectingContainer}>
                <Typography variant="header12" sx={{ fontSize: 12, fontWeight: 500 }}>
                  Signing In..
                </Typography>
                <LoaderAuth />
              </Box>
            </AuthenticatedTemplate>
          </Box>
          <Box className={classes.azureContainer}>
            <AzureIcon />
            <Typography variant="para12" sx={{ fontSize: 12 }} className={classes.azureText}>
              Secured by Microsoft Azure
            </Typography>
          </Box>
        </Box>
        <Box className={classes.footerContainer}>
          <Box className={classes.footer}>
            <Footer isWhiteBackground />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default AuthPage;
