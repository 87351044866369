import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import user from './user';
import insight from './insight';
import insights from './insights';
import label from './label';
import labels from './labels';
import facts from './facts';
import tenants from './tenants';
import pledge from './pledge';
import pledges from './pledges';
import dialog from './dialog';
import formatters from './formatters';
import survey from './survey';
import surveys from './surveys';
import module from './module';
import modules from './modules';
import programs from './programs';
import program from './program';
import columns from './columns';
import reports from './reports';
import actionSpec from './actionSpec'
import actionSpecs from './actionSpecs'


export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
    insight,
    insights,
    label,
    labels,
    facts,
    pledge,
    pledges,
    dialog,
    formatters,
    survey,
    surveys,
    module,
    modules,
    program,
    programs,
    tenants,
    columns,
    reports,
    actionSpec,
    actionSpecs
  });
