import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { PROGRAM_MESSAGE } from 'data/programs';
import React from 'react';
import { highlighter } from 'utils/columns';
import useStyles from './Program.styles';

const Program = ({ form, handleChange, editHandler, searchedText }) => {
  const classes = useStyles();

  return form ? (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="flex flex-col">
          <Typography variant="body1">{highlighter(searchedText, form.name)}</Typography>
          <Typography variant="body1">Status: {form.status}</Typography>
        </div>
        <div className={classes.update}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => editHandler('programs', form.id)}
            size="medium"
          >
            Edit
          </Button>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.infoContainer}>
          <div className={classes.descriptionSection}>
            <Typography variant="h6" display="inline">
              {'Modules'}
            </Typography>
          </div>
          <div className={classes.tableSection}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {'Name'}
                    <Tooltip title={PROGRAM_MESSAGE} placement="bottom-start">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>Edit Modules</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {form.modules?.map((item, index) => (
                  <TableRow index={item.id} key={index}>
                    <TableCell component="th" scope="row">
                      {item.name}
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" gutterBottom>
                        <Tooltip title={''} placement="bottom-start">
                          <IconButton onClick={() => editHandler('modules', item.id)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </AccordionDetails>
      {/* <AccordionActions>
        <Button size="small" onClick={() => actions.push(`/programs/${program.id}/edit`)}>
          Edit
        </Button>
      </AccordionActions> */}
    </Accordion>
  ) : (
    ''
  );
};

export default Program;
