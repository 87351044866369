import React, { useEffect, useState } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { HOST_URL } from 'constants/hosts';
import store, { history } from 'config/store';
import { AppContainer } from 'containers/views';
import { DialogTemplateContainer } from 'containers/templates';
import { LoadingComponent } from 'components/commons';
import theme from 'config/theme';
import { getMsalConfig, setMsalInstance } from 'msal-config';
import ErrorPage from 'components/views/error-page';

const AppInitializer = () => {
  const [pca, setPca] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        // get the global config for msal configuration
        const response = await fetch(`${HOST_URL}/configurator/api/v1/config?global=true`);
        if (!response.ok) {
          throw new Error(`Failed to fetch configs: ${response.status} ${response.statusText}`);
        }
        const configs = await response.json();
        const configUrl = configs?.[0];
        if (!configUrl) {
          throw new Error('No config URL found in configs');
        }
        const configResponse = await fetch(
          `${HOST_URL}/configurator/api/v1/config/get?file_name=${configUrl}`
        );
        if (!configResponse.ok) {
          throw new Error(
            `Failed to fetch config data: ${configResponse.status} ${configResponse.statusText}`
          );
        }
        const configData = await configResponse.json();

        const msalConfig = getMsalConfig(configData);

        const msalInstance = new PublicClientApplication(msalConfig);

        // Initialize MSAL instance
        await msalInstance.initialize();

        // Set the MSAL instance in the module
        setMsalInstance(msalInstance);
        setPca(msalInstance);
      } catch (err) {
        console.error('Failed to initialize application', err);
        setError(err);
      }
    };

    initializeApp();
  }, []);

  const renderApp = () => {
    if (error) {
      return <ErrorPage />;
    }

    if (!pca) {
      return <LoadingComponent />;
    }

    return (
      <MsalProvider instance={pca}>
        <ConnectedRouter history={history}>
          <DialogTemplateContainer />
          <AppContainer />
        </ConnectedRouter>
      </MsalProvider>
    );
  };

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {renderApp()}
      </ThemeProvider>
    </Provider>
  );
};

export default AppInitializer;
