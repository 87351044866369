import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import fetchTenantsIfNeeded from 'actions/tenants';
import fetchModules from 'actions/modules';
import {
  fetchSurvey,
  createActionSpec,
  resetActionSpec,
  getLanguageFile,
  saveLanguageFile,
  getLanguageFileTemplate,
  getMessageCsv,
  saveMessageCsv,
} from 'actions/actionSpec';
import { fetchFactsIfNeeded } from 'actions/facts';
import { CreateActionSpec } from 'views';

const mapStateToProps = ({ modules, tenants, facts }) => ({ modules, tenants, facts });

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        fetchSurvey,
        createActionSpec,
        getLanguageFile,
        saveLanguageFile,
        getLanguageFileTemplate,
        getMessageCsv,
        saveMessageCsv,
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchModules());
      dispatch(fetchTenantsIfNeeded());
      dispatch(fetchFactsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetActionSpec());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateActionSpec);
