import React, { useMemo, useEffect } from 'react';
import Columns from '../columns';
import Pledge from './pledge';
import Sidebar from 'components/templates/sidebar';
import useStyles from './Pledges.styles';

const Pledges = ({ actions, tenants }) => {
  const classes = useStyles();

  const menuOptions = useMemo(() => {
    const TENANT_OPTIONS = tenants
      ? tenants.tenants.map((tenant) => ({ value: tenant.id, text: tenant.name }))
      : [];

    return [
      {
        text: 'Type',
        value: 'type',
        children: [
          {
            text: 'Water',
            value: 'water',
          },
          {
            text: 'Bedtime',
            value: 'bedtime',
          },
          {
            text: 'Steps',
            value: 'steps',
          },
          {
            text: 'Fiber',
            value: 'fiber',
          },
          {
            text: 'Calorie',
            value: 'calorie',
          },
          {
            text: 'Weight',
            value: 'weight',
          },
          {
            text: 'Temperature',
            value: 'temperature',
          },
          {
            text: 'Meditation',
            value: 'meditation',
          },
        ],
      },
      {
        text: 'Status',
        value: 'status',
        children: [
          {
            text: 'Draft',
            value: 'draft',
          },
          {
            text: 'Live',
            value: 'live',
          },
          {
            text: 'Deactivated',
            value: 'deactivated',
          },
        ],
      },
      {
        value: 'tenant',
        text: 'Tenant',
        children: TENANT_OPTIONS,
      },
    ];
  }, [tenants]);

  useEffect(() => {
    actions.fetchTenantsIfNeeded();
  }, [actions]);

  const cardBuilder = (pledge, index, searchText) => {
    return <Pledge key={index} {...{ actions, pledge, index, searchText }} />;
  };

  return (
    <div className={classes.pageContainer}>
      <Sidebar
        title="Pledges"
        mainButtonAction={() => actions.push('/pledges/create')}
        mainButtonText="Create pledge"
      ></Sidebar>
      <div className={classes.pledgesContainer}>
        <Columns
          name="pledges"
          service={actions.fetchPledges}
          menuOptions={menuOptions}
          cardBuilder={cardBuilder}
        />
      </div>
    </div>
  );
};

export default Pledges;
