import { TYPE_DISMISS_DIALOG } from 'data/dialog';
import * as insightsTypes from 'data/insights';
import * as labelsTypes from 'data/labels';
import * as pledgesTypes from 'data/pledges';
import * as surveysTypes from 'data/surveys';
import * as modulesTypes from 'data/modules';
import * as programsTypes from 'data/programs';
import * as actionSpecsTypes from 'data/actionSpecs';
import { TYPE_RECEIVE_FORMATTERS_ERROR } from 'data/formatters';

const initState = [];

const insightReducer = action => {
  switch (action.type) {
    case insightsTypes.TYPE_RECEIVE_INSIGHTS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch insights.' };
    case insightsTypes.TYPE_RECEIVE_INSIGHT_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch insight.' };
    case insightsTypes.TYPE_CREATE_INSIGHT_SUCCESS:
      return { variant: 'success', message: `Successfully created ${action.insight.name}` };
    case insightsTypes.TYPE_CREATE_INSIGHT_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem creating the insight.'
      };
    case insightsTypes.TYPE_UPDATE_INSIGHT_SUCCESS:
      return { variant: 'success', message: `Successfully updated ${action.insight.name}` };
    case insightsTypes.TYPE_UPDATE_INSIGHT_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem updating the insight.'
      };
    case insightsTypes.TYPE_RECEIVE_INSIGHT_TEST:
      return { variant: 'error', message: 'Success! All tests in Labels passed.' };
    case insightsTypes.TYPE_RECEIVE_INSIGHT_TEST_ERROR:
      return { variant: 'error', message: action.error };
    case insightsTypes.TYPE_POST_LANGUAGE_FILE_SUCCESS:
      return { variant: 'success', message: `Successfully uploaded file` };
    case insightsTypes.TYPE_POST_LANGUAGE_FILE_ERROR:
      return { variant: 'error', message: `There was a problem uploading the file` };
    default:
      return;
  }
};

const labelReducer = action => {
  switch (action.type) {
    case labelsTypes.TYPE_RECEIVE_LABELS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch labels.' };
    case labelsTypes.TYPE_RECEIVE_LABEL_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch label.' };
    case labelsTypes.TYPE_UPDATE_LABEL_SUCCESS:
      return { variant: 'success', message: `Successfully updated label ${action.label.name}.` };
    case labelsTypes.TYPE_UPDATE_LABEL_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem updating the label.'
      };
    case labelsTypes.TYPE_CREATE_LABEL_SUCCESS:
      return { variant: 'success', message: `Successfully created label ${action.label.name}.` };
    case labelsTypes.TYPE_CREATE_LABEL_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem creating the label.'
      };
    case labelsTypes.TYPE_RECEIVE_CLUSTERS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch clusters.' };
    case labelsTypes.TYPE_CREATE_CLUSTER_SUCCESS:
      return { variant: 'success', message: `Successfully created cluster ${action.clusterName}.` };
    case labelsTypes.TYPE_CREATE_CLUSTER_ERROR:
      return {
        variant: 'error',
        message: action.error || 'There was a problem creating the cluster.'
      };
    default:
      return;
  }
};

const pledgesReducer = action => {
  switch (action.type) {
    case pledgesTypes.TYPE_RECEIVE_PLEDGES_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch pledges.' };
    case pledgesTypes.TYPE_RECEIVE_PLEDGE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch pledge.' };
    case pledgesTypes.TYPE_UPDATE_PLEDGE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to update pledge.' };
    case pledgesTypes.TYPE_UPDATE_PLEDGE_SUCCESS:
      return { variant: 'success', message: `Successfully updated pledge ${action.pledge.name}.` };
    case pledgesTypes.TYPE_CREATE_PLEDGE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to create pledge.' };
    case pledgesTypes.TYPE_CREATE_PLEDGE_SUCCESS:
      return { variant: 'success', message: `Successfully created pledge ${action.pledge.name}.` };
    case pledgesTypes.TYPE_POST_PLEDGE_LANGUAGE_FILE_SUCCESS:
      return { variant: 'success', message: `Successfully uploaded file` };
    case pledgesTypes.TYPE_POST_PLEDGE_LANGUAGE_FILE_ERROR:
      return { variant: 'error', message: `There was a problem uploading the file` };
    default:
      return;
  }
};

const surveysReducer = action => {
  switch (action.type) {
    case surveysTypes.TYPE_RECEIVE_SURVEYS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch surveys.' };
    case surveysTypes.TYPE_RECEIVE_SURVEY_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch survey.' };
    case surveysTypes.TYPE_UPDATE_SURVEY_ERROR:
      return { variant: 'error', message: action.error || 'Unable to update survey.' };
    case surveysTypes.TYPE_UPDATE_SURVEY_SUCCESS:
      return { variant: 'success', message: `Successfully updated survey ${action.survey.name}.` };
    case surveysTypes.TYPE_CREATE_SURVEY_ERROR:
      return { variant: 'error', message: action.error || 'Unable to create survey.' };
    case surveysTypes.TYPE_CREATE_SURVEY_SUCCESS:
      return { variant: 'success', message: `Successfully created survey ${action.survey.name}.` };
    case surveysTypes.TYPE_POST_SURVEY_LANGUAGE_FILE_SUCCESS:
      return { variant: 'success', message: `Successfully uploaded file` };
    case surveysTypes.TYPE_POST_SURVEY_LANGUAGE_FILE_ERROR:
      return { variant: 'error', message: `There was a problem uploading the file` };
    default:
      return;
  }
};

const modulesReducer = action => {
  switch (action.type) {
    case modulesTypes.TYPE_RECEIVE_MODULES_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch modules.' };
    case modulesTypes.TYPE_RECEIVE_MODULE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch module.' };
    case modulesTypes.TYPE_UPDATE_MODULE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to update module.' };
    case modulesTypes.TYPE_UPDATE_MODULE_SUCCESS:
      return { variant: 'success', message: `Successfully updated module ${action.module.name}.` };
    case modulesTypes.TYPE_UPGRADE_MODULE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to upgrade module.' };
    case modulesTypes.TYPE_UPGRADE_MODULE_SUCCESS:
      return { variant: 'success', message: `Successfully upgraded module ${action.module.name}.` };
    case modulesTypes.TYPE_CREATE_MODULE_ERROR:
      return { variant: 'error', message: action.error || 'Unable to create module.' };
    case modulesTypes.TYPE_CREATE_MODULE_SUCCESS:
      return { variant: 'success', message: `Successfully created module ${action.module.name}.` };
    default:
      return;
  }
};

const programsReducer = action => {
  switch (action.type) {
    case programsTypes.TYPE_RECEIVE_PROGRAMS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch programs.' };
    case programsTypes.TYPE_RECEIVE_PROGRAM_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch program.' };
    case programsTypes.TYPE_UPDATE_PROGRAM_ERROR:
      return { variant: 'error', message: action.error || 'Unable to update program.' };
    case programsTypes.TYPE_UPDATE_PROGRAM_SUCCESS:
      return { variant: 'success', message: `Successfully updated program ${action.program.name}.` };
    case programsTypes.TYPE_CREATE_PROGRAM_ERROR:
      return { variant: 'error', message: action.error || 'Unable to create program.' };
    case programsTypes.TYPE_CREATE_PROGRAM_SUCCESS:
      return { variant: 'success', message: `Successfully created program ${action.program.name}.` };
    default:
      return;
  }
};

const actionSpecsReducer = (action) => {
  switch (action.type) {
    case actionSpecsTypes.TYPE_RECEIVE_ACTION_SPECS_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch action specs.' };
    case actionSpecsTypes.TYPE_RECEIVE_ACTION_SPEC_ERROR:
      return { variant: 'error', message: action.error || 'Unable to fetch action spec.' };
    case actionSpecsTypes.TYPE_UPDATE_ACTION_SPEC_ERROR:
      return { variant: 'error', message: action.error || 'Unable to update action spec.' };
    case actionSpecsTypes.TYPE_UPDATE_ACTION_SPEC_SUCCESS:
      return {
        variant: 'success',
        message: `Successfully updated action spec ${action.actionSpec.title}.`,
      };
    case actionSpecsTypes.TYPE_CREATE_ACTION_SPEC_ERROR:
      return { variant: 'error', message: action.error || 'Unable to create action spec.' };
    case actionSpecsTypes.TYPE_CREATE_ACTION_SPEC_SUCCESS:
      return {
        variant: 'success',
        message: `Successfully created action spec ${action.actionSpec.title}.`,
      };
    case actionSpecsTypes.TYPE_POST_ACTION_SPEC_LANGUAGE_FILE_SUCCESS:
      return { variant: 'success', message: `Successfully uploaded file` };
    case actionSpecsTypes.TYPE_POST_ACTION_SPEC_LANGUAGE_FILE_ERROR:
      return { variant: 'error', message: `There was a problem uploading the file` };
    default:
      return;
  }
};


/**
 * Sub reducer to build a message from a formatters action
 */
function formatters(action) {
  if (action.type === TYPE_RECEIVE_FORMATTERS_ERROR) {
    return { variant: 'error', message: action.error || 'Unable to fetch formatters.' };
  }
}

export default (state = initState, action) => {
  if (action.testPassed === false) {
    return [...state, { variant: 'error', message: `Tests failed. Content can't be live.` }];
  }
  switch (action.type) {
    case TYPE_DISMISS_DIALOG:
      return state.slice(1);
    case insightsTypes.TYPE_UPDATE_INSIGHT_SUCCESS:
    case insightsTypes.TYPE_UPDATE_INSIGHT_ERROR:
    case insightsTypes.TYPE_CREATE_INSIGHT_SUCCESS:
    case insightsTypes.TYPE_CREATE_INSIGHT_ERROR:
    case insightsTypes.TYPE_RECEIVE_INSIGHT_TEST:
    case insightsTypes.TYPE_RECEIVE_INSIGHT_TEST_ERROR:
    case insightsTypes.TYPE_POST_LANGUAGE_FILE_SUCCESS:
    case insightsTypes.TYPE_POST_LANGUAGE_FILE_ERROR:
      return [...state, insightReducer(action)];
    case labelsTypes.TYPE_RECEIVE_LABELS_ERROR:
    case labelsTypes.TYPE_UPDATE_LABEL_SUCCESS:
    case labelsTypes.TYPE_UPDATE_LABEL_ERROR:
    case labelsTypes.TYPE_CREATE_LABEL_SUCCESS:
    case labelsTypes.TYPE_CREATE_LABEL_ERROR:
    case labelsTypes.TYPE_RECEIVE_CLUSTERS_ERROR:
    case labelsTypes.TYPE_CREATE_CLUSTER_SUCCESS:
    case labelsTypes.TYPE_CREATE_CLUSTER_ERROR:
      return [...state, labelReducer(action)];
    case pledgesTypes.TYPE_CREATE_PLEDGE_SUCCESS:
    case pledgesTypes.TYPE_CREATE_PLEDGE_ERROR:
    case pledgesTypes.TYPE_UPDATE_PLEDGE_SUCCESS:
    case pledgesTypes.TYPE_UPDATE_PLEDGE_ERROR:
    case pledgesTypes.TYPE_POST_PLEDGE_LANGUAGE_FILE_SUCCESS:
    case pledgesTypes.TYPE_POST_PLEDGE_LANGUAGE_FILE_ERROR:
      return [...state, pledgesReducer(action)];
    case surveysTypes.TYPE_RECEIVE_SURVEYS_ERROR:
    case surveysTypes.TYPE_RECEIVE_SURVEY_ERROR:
    case surveysTypes.TYPE_UPDATE_SURVEY_SUCCESS:
    case surveysTypes.TYPE_UPDATE_SURVEY_ERROR:
    case surveysTypes.TYPE_CREATE_SURVEY_SUCCESS:
    case surveysTypes.TYPE_CREATE_SURVEY_ERROR:
    case surveysTypes.TYPE_POST_SURVEY_LANGUAGE_FILE_SUCCESS:
    case surveysTypes.TYPE_POST_SURVEY_LANGUAGE_FILE_ERROR:
      return [...state, surveysReducer(action)];
    case modulesTypes.TYPE_RECEIVE_MODULES_ERROR:
    case modulesTypes.TYPE_RECEIVE_MODULE_ERROR:
    case modulesTypes.TYPE_UPDATE_MODULE_SUCCESS:
    case modulesTypes.TYPE_UPDATE_MODULE_ERROR:
    case modulesTypes.TYPE_CREATE_MODULE_SUCCESS:
    case modulesTypes.TYPE_CREATE_MODULE_ERROR:
      return [...state, modulesReducer(action)];
    case programsTypes.TYPE_RECEIVE_PROGRAMS_ERROR:
    case programsTypes.TYPE_RECEIVE_PROGRAM_ERROR:
    case programsTypes.TYPE_UPDATE_PROGRAM_SUCCESS:
    case programsTypes.TYPE_UPDATE_PROGRAM_ERROR:
    case programsTypes.TYPE_CREATE_PROGRAM_SUCCESS:
    case programsTypes.TYPE_CREATE_PROGRAM_ERROR:
      return [...state, programsReducer(action)];
    case actionSpecsTypes.TYPE_RECEIVE_ACTION_SPECS_ERROR:
    case actionSpecsTypes.TYPE_RECEIVE_ACTION_SPEC_ERROR:
    case actionSpecsTypes.TYPE_UPDATE_ACTION_SPEC_SUCCESS:
    case actionSpecsTypes.TYPE_UPDATE_ACTION_SPEC_ERROR:
    case actionSpecsTypes.TYPE_CREATE_ACTION_SPEC_SUCCESS:
    case actionSpecsTypes.TYPE_CREATE_ACTION_SPEC_ERROR:
    case actionSpecsTypes.TYPE_POST_ACTION_SPEC_LANGUAGE_FILE_SUCCESS:
    case actionSpecsTypes.TYPE_POST_ACTION_SPEC_LANGUAGE_FILE_ERROR:
      return [...state, actionSpecsReducer(action)];
    case TYPE_RECEIVE_FORMATTERS_ERROR:
      return [...state, formatters(action)];
    default:
      return state;
  }
};
