import { fetchActionSpecs as serviceFetchActionSpecs } from 'services/actionSpecs';
import {
  TYPE_REQUEST_ACTION_SPECS,
  TYPE_RECEIVE_ACTION_SPECS,
  TYPE_RECEIVE_ACTION_SPECS_ERROR,
} from 'data/actionSpecs';

const requestActionSpecs = () => ({
  type: TYPE_REQUEST_ACTION_SPECS,
  isFetching: true,
});

const receiveActionSpecs = (json) => ({
  type: TYPE_RECEIVE_ACTION_SPECS,
  isFetching: false,
  actionSpecs: json.data.actionSpecs,
  currentPage: json.meta.currentPage,
  nextPage: json.meta.nextPage,
});

const receiveActionSpecsError = (error) => ({
  type: TYPE_RECEIVE_ACTION_SPECS_ERROR,
  isFetching: false,
  error: error.message ? error.message : error,
});

export const fetchActionSpecs =
  (filters = null, page = 1) =>
  (dispatch) => {
    dispatch(requestActionSpecs());
    return serviceFetchActionSpecs(filters, page)
      .then((actionSpecs) => dispatch(receiveActionSpecs(actionSpecs)))
      .catch((error) => dispatch(receiveActionSpecsError(error)));
  };
