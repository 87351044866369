import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import SurveyForm from 'components/views/surveys/form';
import useStyles from './CreateSurvey.styles';
import { tenantHandler } from 'services/shared/handlers';
import {constant, update} from "lodash";

const flagsValues = {
  'condition_day': { unnecessaryFields: [] },
  'rules_based': { unnecessaryFields: ['dayCondition'] }
};

const CreateSurvey = ({ actions, tenants, facts}) => {
  const classes = useStyles();
  const [form, setForm] = useState({
    name: 'UNTITLED_SURVEY',
    introHeader: '',
    introBody: '',
    outroHeader: '',
    outroBody: '',
    type: '',
    intake: false,
    priority: '',
    state: '',
    dayCondition: '',
    genderCondition: '',
    flags: '',
    questions: [],
    conditions: [],
    tests: {
      conditions: [],
      params: []
    },
    availableLangs: [],
    enableLangs: [],
    tenants: [],
    lang: 'en'
  });

  useEffect(() => {
    actions.onMount();
    return () => actions.onUnmount();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenants.tenants?.length) {
      setForm({ ...form, tenants: [localStorage.getItem('tenantId')] });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants]);

  const handleChange = name => ({ target: { value } }) => {
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleCheck = name => e => setForm({ ...update(form, name, constant(e.target.checked)) })

  const handleTenantCheck = (tenantIndex) => ({ target }) => {
    tenantHandler(tenantIndex, target, form, tenants, setForm);
  };

  const handleSubmit = () => {
    if (validateFields()) {
      const { unnecessaryFields } = flagsValues[form.flags];
      unnecessaryFields.forEach(field => handleChange(field)({ target: { value: '' } }));
      actions.createSurvey(form);
    }

    if (form.questions.length === 0) {
      return actions.postCreateSurveyError(form, "Cannot create survey without questions")
    }
  };

  const validateFields = () => {
    return !(form.priority > 200) && form.questions.length > 0;
  };

  const handleFileDownload = (language) => {
    return actions.getLanguageFile(form.id, language);
  };

  const handleTemplateFileDownload = () => {
    return actions.getLanguageFileTemplate(form.id);
  };

  const handleFileUpload = (file, language) => {
    let languageFile = new FormData();
    languageFile.append('file', file, file.name);
    actions.saveLanguageFile(languageFile, form.id, language);
  };

  const handleStatusChange = (availableLanguages, disabledLanguages) => {
    setForm({
      ...form,
      availableLangs: [...availableLanguages],
      enableLangs: [...disabledLanguages],
    });
  };

  return (
    <div className={classes.pageContainer}>
      <Typography variant="h5">Create Survey</Typography>
      <SurveyForm
        handleChange={handleChange}
        handleCheck={handleCheck}
        form={form} 
        onSubmit={handleSubmit}
        tenants={tenants}
        handleTenantCheck={handleTenantCheck}      
        facts={facts}
        setForm={setForm}
        handleFileDownload={handleFileDownload}
        handleTemplateFileDownload={handleTemplateFileDownload}
        handleFileUpload={handleFileUpload}
        handleStatusChange={handleStatusChange}
      />
    </div>
  );
};

export default CreateSurvey;
