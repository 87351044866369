import makeStyles from '@material-ui/styles/makeStyles';

export default makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(8),
    height: '100%',
    width: '100%',
  },
  actionSpecsContainer: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    height: '100%',
    width: 'calc(100% - 300px)',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
}));
