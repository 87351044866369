import TestFactory from './tests';
import { cleanConditions } from './utils';

export default class ModuleFactory {
  static toDTO(model) {
    model.steps.forEach(step => {
      step.ttl = step.ttl === "" ? 0 : step.ttl;
      step.cooldown = step.cooldown === "" ? 0 : step.cooldown;
    });
    return {
      id: model.id,
      content_identifier: model.contentIdentifier,
      name: model.name,
      programs: model.programs.map((program) => ({ id: program.id })),
      steps: model.steps.map(ModuleFactory.stepToDTO),
      edges: model.edges.map(ModuleFactory.edgeToDTO),
    };
  }

  static stepToDTO(stepModel) {
    return {
      content_identifier: stepModel.contentIdentifier,
      content_id: stepModel.id,
      name: stepModel.name,
      conditions: cleanConditions(stepModel.stepConditions),
      ttl: stepModel.ttl,
      cooldown: stepModel.cooldown,
      status: stepModel.status,
      content_type: stepModel.contentType,
      type: stepModel.type,
      ref_id: stepModel.ref_id,
      tests: TestFactory.testToDTO(stepModel.stepTest),
    };
  }

  static edgeToDTO(edgeModel) {
    return {
      conditions: cleanConditions(edgeModel.conditions) ?? [],
      delay: edgeModel.delay,
      dst: edgeModel.dst,
      src: edgeModel.src,
      trigger: edgeModel.trigger || 'completed',
      tests: TestFactory.testToDTO(edgeModel.tests),
      content_sub_status: ModuleFactory.subStatusToDTO(edgeModel.contentSubStatus),
      visual_conditions: edgeModel.visualConditions
    };
  }

  static toModel(dto) {
    return {
      id: dto.id,
      name: dto.name,
      contentIdentifier: dto.content_identifier,
      programs: dto.programs,
      steps: dto.steps?.map(ModuleFactory.stepToModel),
      edges: dto.edges?.map(ModuleFactory.edgeToModel),
      status: dto.status,
    };
  }
  static getContentToModel(dto) {
    return {
      id: dto.id,
      name: dto.name,
      contentIdentifier: dto.content_identifier,
      steps: dto.steps,
    };
  }

  static stepToModel(stepDTO) {
    return {
      contentIdentifier: stepDTO.content_identifier,
      name: stepDTO.name,
      contentType: stepDTO.content_type,
      type: stepDTO.type,
      id: stepDTO.content_id || stepDTO.id,
      refId: stepDTO.ref_id,
      stepConditions: stepDTO.conditions,
      stepTest: TestFactory.testToModel(stepDTO.tests),
      ttl: stepDTO.ttl,
      cooldown: stepDTO.cooldown,
      status: stepDTO.status,
    };
  }

  static edgeToModel(edgeDTO) {
    return {
      conditions: edgeDTO.conditions,
      delay: edgeDTO.delay,
      dst: edgeDTO.dst,
      src: edgeDTO.src,
      trigger: edgeDTO.trigger || 'completed',
      tests: TestFactory.testToModel(edgeDTO.tests),
      contentSubStatus: ModuleFactory.subStatusToModel(edgeDTO.content_sub_status),
      visualConditions: edgeDTO.visual_conditions || ""
    };
  }

  static subStatusToModel(content_sub_status) {
    return content_sub_status?.length === 0 ? ["completed"] : (content_sub_status || []);
  }

  static subStatusToDTO(content_sub_status) {
    return content_sub_status ?
      (content_sub_status[0] !== "completed" && content_sub_status[0] !== "") ?
        content_sub_status : []
      : [];
  }
}
