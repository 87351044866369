import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAccount, useMsal } from '@azure/msal-react';

import { LoadingComponent } from 'components/commons';
import { AuthPage } from 'views';
import ErrorPage from 'components/views/error-page';

const Login = ({ actions }) => {
  const { instance, accounts } = useMsal();
  const [isConnecting, setIsConnecting] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const account = useAccount(accounts[0] || {});

  const handleSignIn = () => {
    setIsConnecting(true);
    instance.loginRedirect({
      scopes: ['openid'],
    });
  };

  // Handle successful login and token extraction
  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          account: account,
          scopes: ['openid'],
        })
        .then((response) => {
          const idToken = response.idToken;
          const idTokenClaims = response.idTokenClaims;
          if (idToken && idTokenClaims) {
            if ((idTokenClaims?.extension_Roles ?? idTokenClaims?.roles).includes('aristotle')) {
              localStorage.setItem('token', idToken);
              setIsAuthenticated(true);
              actions.login();
            } else {
              setError(true);
            }
          }
        })
        .catch((error) => {
          if (error) {
            instance.acquireTokenRedirect({
              scopes: ['openid'],
            });
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          setIsConnecting(false);
        });
    }
  }, [account, instance, actions]);

  if (error) {
    return (
      <ErrorPage
        title="We're sorry, but you don't have permissions to access this platform."
        description=" Please contact your system administrator to resolve this issue or try sign in again."
        isSignError
      />
    );
  }

  // Show the AuthPage if the user is not authenticated and we're either connecting or signing in
  if (!isAuthenticated) {
    return <AuthPage isConnecting={isConnecting} onSignIn={handleSignIn} />;
  }

  return <LoadingComponent />;
};

Login.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

export default Login;
