import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TenantsField from 'components/commons/tenants-field';
import BlocklyConditionsAndTests from 'components/commons/blockly-conditions-and-tests';
import ParamsField from 'components/commons/params-field';
import TestParams from 'components/commons/tests-field/Params';
import { useDebounce } from 'utils/hooks';
import { Typography } from '@material-ui/core';
import useStyles from 'components/commons/tests-field/TestsField.styles';

const ConditionsTab = ({ facts, form, setForm, handleChange, handleTenantCheck, labels, tenants, testResults, handleConditions = () => { }, handleBlocklyCondition = () => { } }) => {
  const classes = useStyles();
  const [params, setParams] = useState([]);
  const debouncedParams = useDebounce(params, 500);

  useEffect(() => {
    if (debouncedParams) {
      handleExternalChange();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const handleExternalChange = () => {
    const newState = {
      conditions: [...form.tests.conditions],
      params: debouncedParams.map(param => ({
        expected: Object.fromEntries(param.expected),
        facts: Object.fromEntries(param.facts),
      })),
    };
    handleChange('tests')({ target: { value: newState } });
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Parameters
        </Typography>
        <Typography variant="body1" gutterBottom>
          {
            'Parameters will auto-fill; however, you must add formatting instructions & remove '
          }
          {'unneeded items manually.'}
        </Typography>
        <ParamsField handleChange={handleChange('extraParams')} initialValue={form.extraParams} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom>
          Tests for params
        </Typography>
        <TestParams
          {...{
            classes,
            onChange: setParams,
            ...(form.tests && { initialValue: form.tests.params }),
          }}
        />
      </Grid>
      <Grid item xs={12} >
        <BlocklyConditionsAndTests
          content={form}
          handleChange={handleConditions}
          blocklyHandleChange={handleBlocklyCondition}
          facts={facts}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <div>
            <TenantsField
              handleTenantCheck={handleTenantCheck}
              initialValue={form.tenants}
              tenantList={tenants}
              form={form}
              setForm={setForm}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ConditionsTab;
