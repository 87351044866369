export default class TestFactory {
  static testToDTO(testModel) {
    return {
      conditions:
        testModel?.conditions
          ?.map((item) => ({ ...item, facts: TestFactory.conditionsFactsToDTO(item.facts) }))
          .filter((item) => item.expected != null) || [],
      params:
        testModel?.params
          ?.map((item) => ({ ...item, facts: TestFactory.factsToDTO(item.facts) }))
          .filter((item) => item.expected != null) || [],
    };
  }

  static conditionsFactsToDTO(factsModel) {
    // Condition's facts is a list of lists
    const newFacts = Object.fromEntries(factsModel);
    return TestFactory.factsToDTO(newFacts);
  }

  static factsToDTO(facts) {
    if (facts['user_cohort'] && typeof facts['user_cohort'] == 'string') {
      facts['user_cohort'] = facts['user_cohort'].split(',');
    }

    if (facts['clinician_roles'] && typeof facts['clinician_roles'] === 'string') {
      facts['clinician_roles'] = facts['clinician_roles'].split(',');
    } else if (facts['clinician_roles'] === '') {
      facts['clinician_roles'] = [];
    }

    if (facts['actions_intervenes'] && typeof facts['actions_intervenes'] === 'string') {
      facts['actions_intervenes'] = facts['actions_intervenes'].split(',');
    }

    if (facts['epoch_time'] && typeof facts['epoch_time'] === 'string') {
      facts['epoch_time'] = Number(facts['epoch_time']);
    }

    return facts;
  }

  static testToModel(testDTO) {
    return {
      ...testDTO,
      conditions:
        testDTO?.conditions?.map((item) => ({
          ...item,
          facts: item.facts != null ? Object.entries(item.facts) : [],
        })) || [],
    };
  }
}
