import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@material-ui/core';

import DatePicker from './DatePicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from 'components/commons/select';
import useStyles from './DateFilter.styles';

const TYPE_OPTIONS = [
  { value: 'time_created', text: 'Creation time' },
  { value: 'time_modified', text: 'Modification time' },
];

const DateFilter = ({ handleApply, clear }) => {
  const classes = useStyles();
  const [type, setType] = useState('');
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    if (clear) {
      setType('');
      setStart(null);
      setEnd(null);
    }
  }, [clear]);

  const handleTypeSelect = ({ target: { value } }) => {
    if (type) {
      setStart(null);
      setEnd(null);
    }
    setType(value);
  };

  const enableBtn = () => {
    if (!(end || start) || !type) {
      return false;
    }
    if (
      (!!start && start.toString() === 'Invalid Date') ||
      (!!end && end?.toString() === 'Invalid Date')
    ) {
      return false;
    }
    const now = new Date();
    if (now < start || now < end) {
      return false;
    }
    if (end !== null && start !== null && end < start) {
      return false;
    }
    return true;
  };

  const convertToEpoch = (date) => {
    const newDate = new Date(date);
    return newDate.getTime();
  };

  const handleSubmit = () => {
    const dateFilterObj = {
      type: 'date',
      value: {},
    };

    if (start) {
      dateFilterObj.value[`start_${type}`] = convertToEpoch(start) / 1000;
    }
    if (end) {
      dateFilterObj.value[`end_${type}`] = convertToEpoch(end) / 1000;
    }
    handleApply(dateFilterObj);
  };

  return (
    <Accordion className={classes.expansionPanel}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.title} variant="subtitle1" gutterBottom>
          Date filter
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.container}>
        <Select
          className={classes.select}
          label="Select type"
          id="date-filter"
          onChange={handleTypeSelect}
          value={type}
          options={TYPE_OPTIONS}
          fullWidth
        />
        <div className={classes.dates}>
          <DatePicker label="Start" value={start} onChange={setStart} />
          <DatePicker label="End" value={end} onChange={setEnd} />
        </div>
        <Button
          className={classes.applyBtn}
          color="primary"
          variant="text"
          size="small"
          type="submit"
          disabled={!enableBtn()}
          onClick={handleSubmit}
        >
          Apply
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default DateFilter;
