import {
  ADDITIONAL_CONTENT_CONDITIONS,
  ADD_ITEM_MESSAGE,
  CONTENT_CONDITIONS_MESSAGE,
  CONTENT_TESTS_MESSAGE,
  ITEM_TYPE,
  TRIGGERS,
} from 'data/modules';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  AdditionalConditions,
  BlocklyConditionsAndTests,
  ListItemSelector,
} from 'components/commons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect, useState } from 'react';
import { constant, update } from 'lodash';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from 'components/commons/button';
import EditIcon from '@material-ui/icons/Edit';
import { EditRulesContainer } from 'containers/views';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Form from 'components/commons/form';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PopUp from 'components/commons/pop-up';
import Select from 'components/commons/select';
import { UserCohortException } from 'components/commons/blockly-conditions/utils/index';
import { hasDuplicates } from 'utils/commons';
import useStyles from './Form.styles';

const ModuleForm = ({
  form,
  contentForm,
  handlers,
  setForm,
  setContentForm,
  edit,
  actions,
  content,
  facts,
}) => {
  const classes = useStyles();
  const [back, setBack] = useState(false);
  const [rulesView, setRulesView] = useState(false);
  const [refId, setRefId] = useState('');
  const [id, setId] = useState('');
  const [item, setItem] = useState({});

  useEffect(() => {
    if (content) {
      const options = content.map((piece) => {
        return { label: piece.name, value: piece.contentIdentifier };
      });
      handleContent('options', options);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    const { name, index } = contentForm.query;
    const node = form?.steps[index];
    if (node) {
      actions.getContent({ query: name, type: node.type, content_type: node.contentType });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentForm.query]);

  const handleContent = (name, value) => {
    setContentForm({
      ...contentForm,
      [name]: value,
    });
  };

  const handleQueryChange = (name, listIndex) => {
    const node = form.steps[listIndex];
    setContentForm({
      ...contentForm,
      query: { name, index: listIndex },
    });
    setForm({
      ...form,
      steps: form.steps.map((item, index) =>
        item.id === node.id && index === listIndex
          ? { ...node, contentType: node.contentType, type: node.type }
          : item
      ),
    });
  };

  const handleEdit = (type, id, item, button) => {
    if (type === 'program') {
      window.location.href = window.location.origin + '/programs/' + id + '/edit';
    } else if (button === 'liveView') {
      window.open(`${window.location.origin}/${type}s/${item.id}/edit`, type);
    } else if (type) {
      setRulesView(true);
      setRefId(id);
      setId(item.id);
      setItem(item);
    }
  };

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setForm({
        ...update(form, name, constant(value)),
      });
    };

  const handleTypeChange =
    ({ listIndex }) =>
    ({ target: { value } }) => {
      var type = undefined;
      var contentType = value;
      if (value === 'short' || value === 'long' || value === 'observation') {
        type = value;
        contentType = 'insight';
      }
      if (value) {
        const newList = form.steps;
        newList[listIndex] = {
          contentType,
          type,
          contentSubStatus: ADDITIONAL_CONTENT_CONDITIONS[contentType].map(() => ''),
          tests: { conditions: [] },
        };
        setForm({
          ...form,
          steps: newList,
        });
      }
    };

  const handleValueChange = ({ listIndex, value }) => {
    if (value) {
      const newValue = content.find((piece) => piece.name === value);
      const newList = [...form.steps];
      newList[listIndex] = { ...newList[listIndex], ...newValue, cooldown: '', ttl: '' };
      setForm({
        ...form,
        steps: newList,
      });
    }
  };

  const handleSave = () => {
    setBack(false);
  };

  const handleSaveAndReturn = () => {
    setBack(true);
  };

  const handleRemoveContent =
    ({ listIndex }) =>
    () => {
      const newNodes = form.steps;
      newNodes.splice(listIndex, 1);
      setForm({
        ...form,
        steps: newNodes,
      });
    };

  const handleAddContent =
    ({ listIndex }) =>
    () => {
      const newNodes = form.steps;
      newNodes.splice(listIndex + 1, 0, { tests: { conditions: [] }, delay: 1 });
      setForm({
        ...form,
        steps: newNodes,
      });
    };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const newList = form.steps;
    const [reorderedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, reorderedItem);
    setForm({
      ...form,
      steps: newList,
    });
  };

  const handleConditions =
    ({ listIndex }) =>
    (name) =>
    ({ target: { value } }) => {
      if (value != null) {
        const newList = form.steps;
        if (name === 'conditions') {
          newList[listIndex].edgeConditions = value;
        } else if (value?.conditions) {
          newList[listIndex].tests = value;
        }
        setForm({
          ...form,
          steps: newList,
        });
      }
    };

  const handleBlocklyCondition =
    ({ listIndex }) =>
    (code, diff, XML, clear = false) => {
      const newSteps = form.steps;
      if (XML?.childNodes?.length > 0) {
        newSteps[listIndex].visualConditions = new XMLSerializer().serializeToString(XML);
      }

      if (code) {
        const newConditions = code;
        var aliasArray = [];
        newConditions.forEach((condition) => {
          if (condition.includes('user_cohort')) {
            aliasArray = aliasArray.concat(condition.match(/\?\w+&/g));
          }
        });

        if (hasDuplicates(aliasArray)) {
          throw new UserCohortException();
        }
        newSteps[listIndex].edgeConditions = newConditions;
      }

      if (clear) {
        newSteps[listIndex].edgeConditions = [];
        newSteps[listIndex].visualConditions = '<xml></xml>';
      }

      setForm({
        ...form,
        steps: newSteps,
      });
    };

  const handleTransitionField =
    ({ listIndex }) =>
    (name) =>
    ({ target: { value } }) => {
      if (value != null) {
        const newList = form.steps;
        newList[listIndex][name] = value;
        setForm({
          ...form,
          steps: newList,
        });
      }
    };

  const handleRadioButtonChange =
    ({ listIndex, fieldIndex }) =>
    ({ target: { value } }) => {
      if (value) {
        const newList = form.steps;
        if (newList[listIndex].contentSubStatus[fieldIndex] === value) {
          newList[listIndex].contentSubStatus = [];
        } else {
          newList[listIndex].contentSubStatus[fieldIndex] = value;
        }
        setForm({
          ...form,
          steps: newList,
        });
      }
    };

  const additionalConditionsHandlers = ({ listIndex, fieldIndex }) => {
    return {
      handleRadioButtonChange: handleRadioButtonChange({ listIndex, fieldIndex }),
    };
  };

  const handleSubmitForm = () => {
    var isValid = true;
    const stepsWithMsgs = form.steps.map((element) => {
      isValid = !!element.contentIdentifier && !!element.contentType;
      return { ...element, invalid: !element.contentIdentifier || !element.contentType };
    });
    if (!isValid) {
      setForm({
        ...form,
        steps: stepsWithMsgs,
      });
      return;
    }
    handlers.handleSubmit(false, back);
  };
  const handlePopUpClose = () => {
    setRulesView(false);
  };

  const updateModuleRules = (module, refId) => {
    const newSteps = form.steps.map((step) => {
      if (refId !== undefined && step.refId === refId) return module;
      if (
        refId === undefined &&
        step.id === module.id &&
        step.contentIdentifier === module.contentIdentifier
      )
        return module;
      return step;
    });
    setForm({ ...form, steps: newSteps });
  };

  if (rulesView) {
    return (
      <PopUp
        handlePopUpClose={handlePopUpClose}
        updateModuleRules={updateModuleRules}
        Container={EditRulesContainer}
        refId={refId}
        id={id}
        form={form}
        itemSelected={item}
      />
    );
  }
  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper>
        {form ? (
          <Form onSubmit={handleSubmitForm} className={classes.formContainer}>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <TextField
                  label="Module Name"
                  type="text"
                  value={form.name}
                  onChange={(e) => handleChange('name')(e)}
                  margin="normal"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" display="inline">
                  {'Assigned to Programs'}
                </Typography>
                <ul>
                  {form.programs?.map((program, index) => {
                    return (
                      <div className={classes.programItem} key={index}>
                        <li>{program.name}</li>
                        {edit && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEdit('program', program.id);
                            }}
                            size="small"
                            className={classes.programItemEdit}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </div>
                    );
                  })}
                </ul>
              </Grid>
            </Grid>
            <section className={classes.tracksContainer}>
              <Typography variant="h6" display="inline">
                {'Content in Module'}
              </Typography>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="contents">
                  {(provided) => (
                    <ul
                      className={classes.contentList}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {form?.steps?.map((item, index) => {
                        return (
                          <Draggable key={index} draggableId={index.toString()} index={index}>
                            {(provided) => (
                              <div
                                className={classes.itemContainer}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className={classes.blockContainer}>
                                  <li className={classes.contentListItem}>
                                    <FormatLineSpacingIcon className={classes.menuIcon} />
                                    <Select
                                      className={classes.typeSelector}
                                      label="Type"
                                      onChange={(e) => {
                                        handleTypeChange({ listIndex: index })(e);
                                        setContentForm({ ...contentForm, options: [] });
                                      }}
                                      value={
                                        item.contentType === 'insight'
                                          ? item.type
                                          : item.contentType
                                      }
                                      options={ITEM_TYPE}
                                      margin="normal"
                                      required
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    />
                                    <ListItemSelector
                                      value={item.name}
                                      options={contentForm.options}
                                      onValueSelect={(selected) => {
                                        handleValueChange({
                                          listIndex: index,
                                          value: selected,
                                        });
                                      }}
                                      onSearchChange={(e) => {
                                        handleQueryChange(e.target.value, index);
                                      }}
                                      onAdd={() => handleAddContent({ listIndex: index })()}
                                      onRemove={() => handleRemoveContent({ listIndex: index })()}
                                      onEdit={(button) =>
                                        handleEdit(item.contentType, item.refId, item, button)
                                      }
                                      multiple={form.steps.length > 1}
                                      canEdit={!item.refId || !item.contentType}
                                      infoMessage={
                                        !item.contentType
                                          ? 'Please select a type from the list.'
                                          : ''
                                      }
                                      errorMessage={
                                        item.invalid ? 'Please add a valid content item.' : ''
                                      }
                                      disabledInput={!item.contentType}
                                      typeValue={item.contentType}
                                    />
                                  </li>
                                </div>
                                <Accordion>
                                  <AccordionSummary
                                    className={classes.expansionSummary}
                                    disabled={index >= form.steps.length - 1}
                                    expandIcon={<ExpandMoreIcon />}
                                  >
                                    <Typography variant="h6" gutterBottom>
                                      Transition Conditions
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {index < form.steps.length - 1 ? (
                                      <div className={classes.conditionsContainer}>
                                        <div className={classes.additionalConditionsContainer}>
                                          <Select
                                            className={classes.triggerSelector}
                                            label="Trigger"
                                            id="trigger"
                                            onChange={handleTransitionField({ listIndex: index })(
                                              'trigger'
                                            )}
                                            value={form.steps[index].trigger || 'completed'}
                                            options={TRIGGERS}
                                            fullWidth
                                            required
                                          />
                                          {ADDITIONAL_CONTENT_CONDITIONS[item.contentType]?.map(
                                            (condition, conditionIndex) => {
                                              return (
                                                <AdditionalConditions
                                                  key={conditionIndex}
                                                  condition={condition}
                                                  value={
                                                    form.steps[index].contentSubStatus
                                                      ? form.steps[index].contentSubStatus[
                                                          conditionIndex
                                                        ]
                                                      : []
                                                  }
                                                  handlers={additionalConditionsHandlers({
                                                    listIndex: index,
                                                    fieldIndex: conditionIndex,
                                                  })}
                                                />
                                              );
                                            }
                                          )}
                                          <TextField
                                            className={classes.delayField}
                                            id="delay"
                                            label="Delay"
                                            type="number"
                                            value={
                                              !isNaN(form.steps[index].delay) &&
                                              form.steps[index].delay >= 0
                                                ? form.steps[index].delay
                                                : '1'
                                            }
                                            onChange={handleTransitionField({ listIndex: index })(
                                              'delay'
                                            )}
                                            margin="normal"
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Tooltip
                                                    title="Minimun delay time in seconds to be triggered"
                                                    placement="top-start"
                                                  >
                                                    <IconButton>
                                                      <InfoIcon />
                                                    </IconButton>
                                                  </Tooltip>
                                                </InputAdornment>
                                              ),
                                            }}
                                            fullWidth
                                            required
                                          />
                                        </div>
                                        <BlocklyConditionsAndTests
                                          content={{
                                            ...form.steps[index],
                                            conditions: form.steps[index].edgeConditions,
                                          }}
                                          handleChange={handleConditions({ listIndex: index })}
                                          conditionsTitle="Content Transition Conditions"
                                          conditionsTipMessage={CONTENT_CONDITIONS_MESSAGE}
                                          testsTitle="Content Transition Tests"
                                          testsTipMessage={CONTENT_TESTS_MESSAGE}
                                          blocklyHandleChange={handleBlocklyCondition({
                                            listIndex: index,
                                          })}
                                          facts={facts.facts}
                                        />
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                                <div className={classes.addBtn}>
                                  <IconButton
                                    onClick={handleAddContent({ listIndex: index })}
                                    className={classes.addIcon}
                                  >
                                    <Tooltip title={ADD_ITEM_MESSAGE} placement="bottom-start">
                                      <AddCircleOutlineIcon />
                                    </Tooltip>
                                  </IconButton>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </section>
            <section className={classes.actionsContainer}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  handlers.handleSubmit(true);
                }}
                size="medium"
              >
                Cancel
              </Button>
              {edit && (
                <Button
                  type="submit"
                  color="primary"
                  onClick={handleSave}
                  disabled={form.status === 60}
                >
                  Save Progress
                </Button>
              )}
              <Button
                type="submit"
                color="primary"
                onClick={handleSaveAndReturn}
                disabled={form.status === 60}
              >
                Save and Return
              </Button>
            </section>
          </Form>
        ) : (
          ''
        )}
      </Paper>
    </Container>
  );
};

export default ModuleForm;
