import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  createSurvey,
  postCreateSurveyError,
  resetSurvey,
  getLanguageFile,
  saveLanguageFile,
  getLanguageFileTemplate,
} from 'actions/survey';
import { CreateSurvey } from 'views';
import { fetchTenantsIfNeeded } from '../../../actions/tenants';
import { fetchFactsIfNeeded } from 'actions/facts';

const mapStateToProps = (state) => ({
  tenants: state.tenants,
  facts: state.facts,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        createSurvey,
        postCreateSurveyError,
        getLanguageFile,
        saveLanguageFile,
        getLanguageFileTemplate,
      },
      dispatch
    ),
    onMount: () => {
      dispatch(fetchTenantsIfNeeded());
      dispatch(fetchFactsIfNeeded());
    },
    onUnmount: () => {
      dispatch(resetSurvey());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurvey);
